import { useTranslation } from 'react-i18next';
import i18nNamespaces from '../../../i18n/i18nNamespaces';
import { CardConfig } from '../../../models/CardConfig';
import { CurrentUserDetails } from '../../../models/CurrentUserDetails';
import fetchLanguageByIndex from '../../../utils/fetchLanguageByIndex';
import formatFullName from '../../../utils/formatFullName';
import parseCustomerAge from '../../../utils/parseCustomerAge';
import parseCustomerDob from '../../../utils/parseCustomerDob';

interface ReturnType {
  cardsConfig: CardConfig[];
  customerId: string;
  title: string;
}

const useContent = (currentUserDetails: CurrentUserDetails): ReturnType => {
  const { t } = useTranslation(i18nNamespaces.MY_INFORMATION);

  const title = t('title');
  const customerId = `#${currentUserDetails.customerId}`;

  const name = formatFullName(currentUserDetails);
  const dob = parseCustomerDob(currentUserDetails.dateofbirth);
  const age = parseCustomerAge(currentUserDetails.dateofbirth);

  const cardsConfig: CardConfig[] = [
    {
      title: t('patient_details'),
      rows: [
        {
          key: t('name'),
          value: name,
        },
        {
          key: t('dob'),
          value: dob || '',
        },
        {
          key: t('age'),
          value: String(age) || '',
        },
        {
          key: t('language'),
          value: fetchLanguageByIndex(Number(currentUserDetails.lang)),
        },
        {
          key: t('insurance'),
          value: currentUserDetails.insurance,
        },
      ],
    },
    {
      title: t('contact_details'),
      rows: [
        {
          key: t('email'),
          value: currentUserDetails.username,
        },
        {
          key: t('phone'),
          value: currentUserDetails.phone,
        },
        {
          key: t('workPhone'),
          value: currentUserDetails.workphone || '',
        },
        {
          key: t('cellPhone'),
          value: currentUserDetails.cellphone || '',
        },
      ],
    },
    {
      title: t('address_details'),
      rows: [
        {
          key: t('address_line_1'),
          value: currentUserDetails.address,
        },
        {
          key: t('address_line_2'),
          value: currentUserDetails.address2,
        },
        {
          key: t('city'),
          value: currentUserDetails.city,
        },
        {
          key: t('county'),
          value: currentUserDetails.county,
        },
        {
          key: t('state'),
          value: currentUserDetails.state,
        },
        {
          key: t('zip'),
          value: currentUserDetails.zip,
        },
        {
          key: t('country'),
          value: currentUserDetails.country,
        },
      ],
    },
  ];
  return { cardsConfig, customerId, title };
};

export default useContent;
