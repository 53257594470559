import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import { RootState } from 'MyTypes';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import i18next from 'i18next';
import HeaderCard from '../../components/HeaderCard/HeaderCard';
import ListFilterTabs, { ListFilterTabItem } from '../../components/ListFilterTabs/ListFilterTabs';
import i18nNamespaces from '../../i18n/i18nNamespaces';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import { FormDetails } from '../../models/FormDetails';
import { selectAllForms } from '../../store/FormsDuck/duck/selector';
import * as FormsActions from '../../store/FormsDuck/duck/action';
import formComponents from '../FormDetails/components/formComponents';
import './FormsAndDocuments.scss';
import ItemList, { ListItem } from '../../components/ItemList/ItemList';

const tabKeys = {
  TODO: 'todo',
  SIGNED: 'signed',
  ALL: 'all',
};

interface Props {
  forms: FormDetails[];
  fetchForms: (successCallback?: () => void, errorCallback?: () => void) => Promise<FormsActions.ActionType>;
  fetchFormsCatalogContent: (
    i18n: typeof i18next,
    successCallback?: () => void,
    errorCallback?: () => void,
  ) => Promise<FormsActions.ActionType>;
}

export const FormsAndDocuments: React.FC<Props> = ({ forms, fetchForms, fetchFormsCatalogContent }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation(i18nNamespaces.FORMS_AND_DOCUMENTS);

  /** ------------------------------- Forms content fetching ------------------------------- */
  const [isRefreshingContent, setIsRefreshingContent] = useState(true);
  const refreshContent = useCallback(async () => {
    setIsRefreshingContent(true);
    const finallyCallback = () => setIsRefreshingContent(false);
    // @TODO: Handle error
    fetchFormsCatalogContent(i18n, finallyCallback, finallyCallback);
  }, [fetchFormsCatalogContent, i18n]);

  /** ------------------------------- Forms fetching ------------------------------- */
  const [isRefreshingForms, setIsRefreshingForms] = useState(true);
  const refreshForms = useCallback(async () => {
    setIsRefreshingForms(true);
    const finallyCallback = () => setIsRefreshingForms(false);
    // @TODO: Handle error
    fetchForms(finallyCallback, finallyCallback);
  }, [fetchForms]);

  // Fetch forms and content on mount
  useEffect(() => {
    refreshForms();
    refreshContent();
  }, [refreshContent, refreshForms]);

  /** ------------------------------- Filters tabs ------------------------------- */
  const [activeTabKey, setActiveTabKey] = useState(tabKeys.TODO);
  const tabItems: ListFilterTabItem[] = [
    {
      key: tabKeys.TODO,
      label: t('todo'),
    },
    {
      key: tabKeys.SIGNED,
      label: t('signed'),
    },
    {
      key: tabKeys.ALL,
      label: t('all'),
    },
  ];

  /** ------------------------------- Handlers ------------------------------- */
  const handleTabClick = useCallback(
    (tabKey: string) => {
      setActiveTabKey(tabKey);
      refreshForms();
    },
    [refreshForms],
  );

  const handleFormItemClick = useCallback(({ id }: ListItem) => history.push(`/form/${id}`), [history]);

  /** ------------------------------- Manipulate data ------------------------------- */
  const formItems = useMemo<ListItem[]>(
    () =>
      forms
        .filter(({ signed }) => {
          if (activeTabKey === tabKeys.SIGNED) return signed;
          if (activeTabKey === tabKeys.TODO) return !signed;
          return true;
        })
        .map(
          ({
            id,
            formTitle,
            formComponent,
            version = 'v1',
            signed,
            adminReviewRequired,
            adminPrefillRequired,
            adminPrefilled,
            adminSigned,
            adminSigningOnly,
            updatedAt,
            editable,
          }) => {
            const i18nKey = `${formComponent}-${version}:catalog-title`;
            const title = i18n.exists(i18nKey) ? t(i18nKey) : formTitle;
            const isNotEditable = !formComponents[formComponent] || editable === false;
            const adminHasNotPrefilled = adminPrefillRequired && !adminPrefilled;
            const isInReview = signed && adminReviewRequired && !adminSigned;
            const disabled = (isNotEditable || adminSigningOnly || adminHasNotPrefilled) && !signed;
            return {
              id,
              title,
              remarks: format(new Date(updatedAt as string), 'MM/dd/yyyy'),
              status: signed,
              disabled,
              /** --- Unsupported props --- */
              ...(isNotEditable && !signed && !adminSigningOnly
                ? {
                    statusLabel: t('comingSoon'),
                    statusClassName: 'FormsAndDocuments-item-unsupported',
                  }
                : {}),
              /** --- In review props --- */
              ...(isInReview
                ? {
                    statusLabel: t('inReview'),
                    statusClassName: 'FormsAndDocuments-item-in-review',
                  }
                : {}),
              /** --- Admin sign only props --- */
              ...((adminSigningOnly && !signed) || adminHasNotPrefilled
                ? {
                    statusLabel: t('pendingAdminAction'),
                    statusClassName: 'FormsAndDocuments-item-pending-admin-signing',
                  }
                : {}),
            };
          },
        ),
    [activeTabKey, forms, i18n, t],
  );

  return (
    <MainLayout showBackButton backRoutePathname="/" contentMaxWidth={1000}>
      <HeaderCard title={t('title')} description={t('description')} />
      <ListFilterTabs items={tabItems} activeItemKey={activeTabKey} onItemClick={handleTabClick} />
      <ItemList
        items={formItems}
        trueLabel={t('signed')}
        falseLabel={t('toBeSigned')}
        onItemClick={handleFormItemClick}
        isRefreshing={isRefreshingForms || isRefreshingContent}
        emptyLabel={t('listEmpty')}
      />
    </MainLayout>
  );
};

const mapStateToProps = (state: RootState): Pick<Props, 'forms'> => ({
  forms: selectAllForms(state),
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<Props, 'fetchForms' | 'fetchFormsCatalogContent'> => ({
  fetchForms: async (successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(await FormsActions.fetchForms(successCallback, errorCallback)),
  fetchFormsCatalogContent: async (i18n: typeof i18next, successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(await FormsActions.fetchFormsCatalogContent(i18n, successCallback, errorCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormsAndDocuments);
