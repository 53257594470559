import React, { memo, ReactNode, useContext } from 'react';

import TopToolbar from './components/TopToolbar/TopToolbar';
import './MainLayout.scss';
import MainNavigation from '../../components/MainNavigation/MainNavigation';
import ToolsContext from '../../context/ToolsContext';

interface Props extends React.HTMLProps<HTMLDivElement> {
  children?: ReactNode;
  contentContainerClassName?: string;
  contentMaxWidth?: number;
  showBackButton?: boolean;
  /** Used when there is no previous route from history API */
  backRoutePathname?: string;
  backButtonDisabled?: boolean;
  menuDisabled?: boolean;
}

export const MainLayout = memo<Props>(
  ({
    children,
    contentContainerClassName = '',
    contentMaxWidth,
    showBackButton,
    backRoutePathname,
    backButtonDisabled,
    menuDisabled,
    ...restProps
  }) => {
    const { isMainNavigationOpen, setIsMainNavigationOpen, device } = useContext(ToolsContext);

    return (
      <div {...restProps} className={`MainLayout-wrapper ${restProps.className || ''}`}>
        <MainNavigation isOpen={isMainNavigationOpen} setOpen={setIsMainNavigationOpen} device={device} />
        <div className="MainLayout-main">
          {!menuDisabled && (
            <TopToolbar
              showBackButton={showBackButton}
              backRoutePathname={backRoutePathname}
              backButtonDisabled={backButtonDisabled}
              maxWidth={contentMaxWidth}
            />
          )}
          <div
            className={`MainLayout ${contentContainerClassName} ${isMainNavigationOpen ? 'mod-blur' : ''}`}
            style={{ maxWidth: contentMaxWidth }}
          >
            <div className="MainLayout-children">{children}</div>
          </div>
        </div>
      </div>
    );
  },
);

MainLayout.displayName = 'MainLayout';

export default MainLayout;
