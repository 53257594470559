const {
  REACT_APP_VERSION,
  REACT_APP_ENVIRONMENT,
  REACT_APP_API_BASE_URL,
  REACT_APP_CONTENT_API_BASE_URL,
  REACT_APP_CDN_PROXY_BASE_URL,
} = process.env;

const Config = {
  version: REACT_APP_VERSION,
  environment: REACT_APP_ENVIRONMENT,
  baseUrl: REACT_APP_API_BASE_URL as string,
  contentUrl: REACT_APP_CONTENT_API_BASE_URL as string,
  cdnProxyUrl: REACT_APP_CDN_PROXY_BASE_URL as string,
  companyUrl: 'www.nymannings.com',
  companyName: 'Confucius Pharmacy Inc',
  companyManhattanStoreAddress: '25 Bowery New York Ny 10002',
  companyCSHotline: '(212) 966-4420',
};

export default Config;
