import React, { memo } from 'react';
import { DeepMap, FieldError, FieldValues, UseFormRegister, UseFormSetFocus, UseFormSetValue } from 'react-hook-form';
import InputTextField, { InputTextFieldProps } from '../InputTextField/InputTextField';
import './OTPInput.scss';

interface Props extends Partial<InputTextFieldProps> {
  register: UseFormRegister<FieldValues>;
  getFieldName: (index: number) => string;
  setValue: UseFormSetValue<FieldValues>;
  setFocus: UseFormSetFocus<FieldValues>;
  errors?: DeepMap<FieldValues, FieldError>;
  length?: number;
}

export const OTPInput = memo<Props>(
  ({
    register,
    getFieldName,
    length = 5,
    setValue,
    setFocus,
    errors,
    onChange: propOnChange,
    onPaste: propOnPaste,
    ...restProps
  }) => {
    return (
      <div className="OTPInput">
        {Array(length)
          .fill(0)
          .map((v, index, arr) => {
            const key = getFieldName(index);
            const { onChange, ...registerProps } = register(key, { required: true });

            const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
              onChange(e);
              // Focus next input segment
              if (e.target.value && index + 1 < arr.length) setFocus(getFieldName(index + 1));
              if (propOnChange) propOnChange(e);
            };

            const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
              if (/Backspace/i.test(e.key) || e.which === 8) {
                // Focus previous input segment
                setTimeout(() => setFocus(getFieldName(Math.max(index - 1, 0))), 100);
              }
            };

            const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
              const value = e.clipboardData.getData('text/plain');
              if (value.length > 1) {
                e.preventDefault();
                Array(length)
                  .fill(0)
                  .forEach((_, i) => setValue(getFieldName(i), value[i]));
              }
              if (propOnPaste) propOnPaste(e);
            };

            return (
              <InputTextField
                {...restProps}
                {...registerProps}
                key={key}
                type="text"
                pattern="\d*"
                maxLength={1}
                status={errors && errors[key] ? 'error' : 'active'}
                autoFocus={index === 0}
                onChange={handleChange}
                onPaste={handlePaste}
                onKeyDown={handleKeyDown}
              />
            );
          })}
      </div>
    );
  },
);

OTPInput.displayName = 'OTPInput';

export default OTPInput;
