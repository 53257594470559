import React, { memo, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import parseHTML from 'html-react-parser';
import { format } from 'date-fns';

import InputTextField from 'components/InputTextField/InputTextField';
import { RadioField } from 'components/RadioField/RadioField';
import SignaturePadField from 'components/SignaturePadField/SignaturePadField';
import { FormComponentProps } from '../../../types/FormComponentProps';
import FormDetailsLayout from '../../FormDetailsLayout/FormDetailsLayout';
import './FormReleaseOfLiabilityItemReturn.scss';
import getFormRelationshipOptions from '../../../utils/getFormRelationshipOptions';

interface Props<T = any> extends FormComponentProps<T> {
  namePlaceholder?: string;
  datePlaceholder?: string;
}

export const FormReleaseOfLiabilityItemReturn = memo<Props>(
  ({
    form,
    onSubmit,
    loading,
    submitError,
    ctaLabel,
    ctaLoadingLabel,
    secondaryCtaLabel,
    tForm,
    mode = 'all',
    datePlaceholder,
    namePlaceholder,
  }) => {
    const prefillEnabled = mode === 'prefill' || mode === 'all';
    const patientEnabled = mode === 'patient' || mode === 'all';
    const adminEnabled = mode === 'admin' || mode === 'all';

    const relationshipRadioOptions = useMemo(() => getFormRelationshipOptions(tForm), [tForm]);

    const {
      register,
      handleSubmit,
      formState: { errors },
      getValues,
      setValue,
    } = useForm({
      defaultValues: {
        name: form?.name ?? '',
        customerId: form?.customerId ?? '',
        date: format(new Date(), 'yyyy-MM-dd'),
        orderNo: form?.paOrderData?.orderNo,
        equipmentType: form?.paOrderData?.equipmentType,
        option: form?.adminData?.data?.option,
        reason: form?.adminData?.data?.reason,
        witnessDate: adminEnabled ? format(new Date(), 'yyyy-MM-dd') : undefined,
      } as { [key: string]: string },
    });
    const { signature: signatureValue, witnessSignature: witnessSignatureValue } = getValues();

    const renderBasicInfo = () => (
      <>
        <InputTextField
          {...register('date')}
          label={tForm('date')}
          placeholder={datePlaceholder}
          type="date"
          status="disabled"
        />
        <InputTextField {...register('orderNo')} label={tForm('order')} type="text" status="disabled" />
        <InputTextField {...register('customerId')} label={tForm('patientAcct')} type="text" status="disabled" />
      </>
    );

    const renderPrefillSection = (fieldsDisabled?: boolean) => {
      const defaultStatus = fieldsDisabled ? 'disabled' : 'active';
      const options = [
        { label: tForm('option_returned'), value: 'returned' },
        { label: tForm('option_removal'), value: 'requestedRemoval' },
      ];
      return (
        <>
          <InputTextField
            {...register('name')}
            label={`${tForm('certifyStatementPrefix')} (${tForm('patientName')})`}
            placeholder={namePlaceholder}
            type="text"
            status="disabled"
            className="FormReleaseOfLiabilityItemReturn-field-name"
          />
          <RadioField
            {...register('option', { required: !fieldsDisabled })}
            options={options}
            disabled={fieldsDisabled}
            status={errors?.option ? 'error' : defaultStatus}
          />
          <InputTextField
            {...register('equipmentType')}
            label={`(${tForm('typeOfEquipment')})`}
            type="text"
            status="disabled"
          />
          <InputTextField
            {...register('reason')}
            label={tForm('reason')}
            type="text"
            maxLength={50}
            status={errors?.reason ? 'error' : defaultStatus}
          />
        </>
      );
    };

    const renderPatientFields = () => (
      <>
        <p>{parseHTML(tForm('ackStatement')?.replace('\n', '<br/>'))}</p>
        <br />
        <SignaturePadField
          {...register('signature', { required: true })}
          label={tForm('signature')}
          status={errors?.signature ? 'error' : 'active'}
          value={signatureValue}
          disabled={loading}
          onChange={(value) => setValue('signature', value)}
        />
        <RadioField
          {...register('relationship', { required: true })}
          label={tForm('relationship')}
          options={relationshipRadioOptions}
          disabled={loading}
          status={errors?.relationship ? 'error' : 'active'}
        />
      </>
    );

    const renderWitnessFields = () => (
      <>
        <br />
        <SignaturePadField
          {...register('witnessSignature', { required: true })}
          label={tForm('companyName')}
          status={errors?.signature ? 'error' : 'active'}
          value={witnessSignatureValue}
          onChange={(value) => setValue('witnessSignature', value)}
        />
        <InputTextField {...register('witnessDate')} label={tForm('dateOfReturn')} type="date" status="disabled" />
      </>
    );

    return (
      <FormDetailsLayout
        className="FormReleaseOfLiabilityItemReturn"
        id={form?.id}
        title={parseHTML(tForm('title')?.replace('\n', '<br/>'))}
        ctaLabel={ctaLabel}
        ctaLoadingLabel={ctaLoadingLabel}
        secondaryCtaLabel={secondaryCtaLabel}
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
        submitError={submitError}
      >
        {renderBasicInfo()}
        <hr />
        <br />
        {renderPrefillSection(!prefillEnabled)}
        {!prefillEnabled && <hr />}
        {!!patientEnabled && renderPatientFields()}
        {!!adminEnabled && renderWitnessFields()}
      </FormDetailsLayout>
    );
  },
);

FormReleaseOfLiabilityItemReturn.displayName = 'FormReleaseOfLiabilityItemReturn';

export default FormReleaseOfLiabilityItemReturn;
