import React, { HTMLProps, memo, ReactNode } from 'react';
import './Card.scss';

interface Props extends HTMLProps<HTMLDivElement> {
  children?: ReactNode;
}

const Card = memo<Props>(({ children, className = '', ...restProps }) => {
  return (
    <div {...restProps} className={`Card ${className}`}>
      {children}
    </div>
  );
});
Card.displayName = 'Card';

interface CardsRowProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode;
}

export const CardsRow = memo<CardsRowProps>(({ children, className = '', ...restProps }) => {
  return (
    <div {...restProps} className={`CardsRow ${className}`}>
      {children}
    </div>
  );
});
CardsRow.displayName = 'CardsRow';

export default Card;
