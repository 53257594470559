import { NotificationDetails } from '../../../models/Notifications';
import * as NotificationsActions from './action';

export interface State {
  notifications: NotificationDetails[];
}

const initialState: State = {
  notifications: [],
};

export default function reducer(state: State = initialState, action: NotificationsActions.ActionType): State {
  switch (action.type) {
    case NotificationsActions.FETCH_NOTIFICATIONS:
    case NotificationsActions.FETCH_NOTIFICATIONS_SUCCESS:
    case NotificationsActions.FETCH_NOTIFICATIONS_FAIL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
