const langMap = [
  {
    Index: 2,
    Language: 'Afrikaans',
  },
  {
    Index: 3,
    Language: 'Albanian',
  },
  {
    Index: 4,
    Language: 'Amharic',
  },
  {
    Index: 5,
    Language: 'Arabic',
  },
  {
    Index: 6,
    Language: 'Armenian',
  },
  {
    Index: 7,
    Language: 'Azerbaijani',
  },
  {
    Index: 8,
    Language: 'Basque',
  },
  {
    Index: 9,
    Language: 'Belarusian',
  },
  {
    Index: 10,
    Language: 'Bengali',
  },
  {
    Index: 11,
    Language: 'Bihari',
  },
  {
    Index: 12,
    Language: '',
  },
  {
    Index: 13,
    Language: 'Bosnian',
  },
  {
    Index: 14,
    Language: 'Breton',
  },
  {
    Index: 15,
    Language: 'Bulgarian',
  },
  {
    Index: 16,
    Language: 'Cambodian',
  },
  {
    Index: 17,
    Language: 'Catalan',
  },
  {
    Index: 18,
    Language: '',
  },
  {
    Index: 19,
    Language: 'Chinese',
  },
  {
    Index: 20,
    Language: 'Corsican',
  },
  {
    Index: 21,
    Language: 'Croatian',
  },
  {
    Index: 22,
    Language: 'Czech',
  },
  {
    Index: 23,
    Language: 'Danish',
  },
  {
    Index: 24,
    Language: 'Dutch',
  },
  {
    Index: 25,
    Language: '',
  },
  {
    Index: 26,
    Language: 'English',
  },
  {
    Index: 27,
    Language: 'Esperanto',
  },
  {
    Index: 28,
    Language: 'Estonian',
  },
  {
    Index: 29,
    Language: 'Faroese',
  },
  {
    Index: 30,
    Language: 'Filipino',
  },
  {
    Index: 31,
    Language: 'Finnish',
  },
  {
    Index: 32,
    Language: 'French',
  },
  {
    Index: 33,
    Language: 'Frisian',
  },
  {
    Index: 34,
    Language: 'Galician',
  },
  {
    Index: 35,
    Language: 'Georgian',
  },
  {
    Index: 36,
    Language: 'German',
  },
  {
    Index: 37,
    Language: 'Greek',
  },
  {
    Index: 38,
    Language: 'Guarani',
  },
  {
    Index: 39,
    Language: '',
  },
  {
    Index: 40,
    Language: '',
  },
  {
    Index: 41,
    Language: 'Hebrew',
  },
  {
    Index: 42,
    Language: 'Hindu',
  },
  {
    Index: 43,
    Language: 'Hungarian',
  },
  {
    Index: 44,
    Language: 'Icelandic',
  },
  {
    Index: 45,
    Language: 'Indonesian',
  },
  {
    Index: 46,
    Language: '',
  },
  {
    Index: 47,
    Language: 'Irish',
  },
  {
    Index: 48,
    Language: 'Italian',
  },
  {
    Index: 49,
    Language: 'Japanese',
  },
  {
    Index: 50,
    Language: '',
  },
  {
    Index: 51,
    Language: 'Kannada',
  },
  {
    Index: 52,
    Language: '',
  },
  {
    Index: 53,
    Language: '',
  },
  {
    Index: 54,
    Language: 'Korean',
  },
  {
    Index: 55,
    Language: 'Kurdish',
  },
  {
    Index: 56,
    Language: '',
  },
  {
    Index: 57,
    Language: 'Laothian',
  },
  {
    Index: 58,
    Language: 'Latin',
  },
  {
    Index: 59,
    Language: '',
  },
  {
    Index: 60,
    Language: '',
  },
  {
    Index: 61,
    Language: 'Lithuanian',
  },
  {
    Index: 62,
    Language: 'Macedonian',
  },
  {
    Index: 63,
    Language: '',
  },
  {
    Index: 64,
    Language: 'Malayalam',
  },
  {
    Index: 65,
    Language: 'Maltese',
  },
  {
    Index: 66,
    Language: 'Marathi',
  },
  {
    Index: 67,
    Language: 'Mongolian',
  },
  {
    Index: 68,
    Language: 'Nepali',
  },
  {
    Index: 69,
    Language: 'Norwegian',
  },
  {
    Index: 70,
    Language: '',
  },
  {
    Index: 71,
    Language: 'Occitan',
  },
  {
    Index: 72,
    Language: 'Oriya',
  },
  {
    Index: 73,
    Language: 'Pashto',
  },
  {
    Index: 74,
    Language: 'Persian',
  },
  {
    Index: 75,
    Language: '',
  },
  {
    Index: 76,
    Language: 'Polish',
  },
  {
    Index: 77,
    Language: '',
  },
  {
    Index: 78,
    Language: 'Portuguese',
  },
  {
    Index: 79,
    Language: '',
  },
  {
    Index: 80,
    Language: '',
  },
  {
    Index: 81,
    Language: 'Romanian',
  },
  {
    Index: 82,
    Language: '',
  },
  {
    Index: 83,
    Language: 'Russian',
  },
  {
    Index: 84,
    Language: 'Scots Gaelic',
  },
  {
    Index: 85,
    Language: 'Serbian',
  },
  {
    Index: 86,
    Language: 'Sesotho',
  },
  {
    Index: 87,
    Language: '',
  },
  {
    Index: 88,
    Language: 'Shona',
  },
  {
    Index: 89,
    Language: 'Sindhi',
  },
  {
    Index: 90,
    Language: 'Sinhalese',
  },
  {
    Index: 91,
    Language: 'Slovak',
  },
  {
    Index: 92,
    Language: 'Slovenian',
  },
  {
    Index: 93,
    Language: 'Somali',
  },
  {
    Index: 94,
    Language: 'Spanish',
  },
  {
    Index: 95,
    Language: 'Sundanese',
  },
  {
    Index: 96,
    Language: 'Swahili',
  },
  {
    Index: 97,
    Language: 'Swedish',
  },
  {
    Index: 98,
    Language: 'Tamil',
  },
  {
    Index: 99,
    Language: 'Tatar',
  },
  {
    Index: 100,
    Language: '',
  },
  {
    Index: 101,
    Language: 'Thai',
  },
  {
    Index: 102,
    Language: '',
  },
  {
    Index: 103,
    Language: '',
  },
  {
    Index: 104,
    Language: 'Tonga',
  },
  {
    Index: 105,
    Language: 'Turkish',
  },
  {
    Index: 106,
    Language: 'Turkmen',
  },
  {
    Index: 107,
    Language: '',
  },
  {
    Index: 108,
    Language: 'Uighur',
  },
  {
    Index: 109,
    Language: 'Ukrainian',
  },
  {
    Index: 110,
    Language: 'Urdu',
  },
  {
    Index: 111,
    Language: 'Uzbek',
  },
  {
    Index: 112,
    Language: 'Vietnamese',
  },
  {
    Index: 113,
    Language: 'Welsh',
  },
  {
    Index: 114,
    Language: '',
  },
  {
    Index: 115,
    Language: 'Yiddish',
  },
  {
    Index: 116,
    Language: '',
  },
  {
    Index: 117,
    Language: 'Zulu',
  },
  {
    Index: 118,
    Language: 'Navajo',
  },
];

const fetchLanguageByIndex = (index: number): string => {
  return langMap.find((x) => x.Index === index)?.Language || '';
};

export default fetchLanguageByIndex;
