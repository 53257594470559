import i18next from 'i18next';
import { action } from 'typesafe-actions';
import { FormDetails } from '../../../models/FormDetails';

export interface ActionType {
  type:
    | 'forms/SET_FORMS'
    | 'forms/FETCH_FORMS'
    | 'forms/FETCH_FORMS_SUCCESS'
    | 'forms/FETCH_FORMS_FAIL'
    | 'forms/FETCH_FORMS_CATALOG_CONTENT'
    | 'forms/FETCH_FORM_CONTENT';
  payload: {
    i18n?: typeof i18next;
    formComponent?: string;
    version?: string;
    forms?: FormDetails[];
    error?: string;
    successCallback?: () => void;
    errorCallback?: () => void;
  };
}

export const SET_FORMS = 'forms/SET_FORMS';

export const FETCH_FORMS = 'forms/FETCH_FORMS';
export const FETCH_FORMS_SUCCESS = 'forms/FETCH_FORMS_SUCCESS';
export const FETCH_FORMS_FAIL = 'forms/FETCH_FORMS_FAIL';
export const FETCH_FORMS_CATALOG_CONTENT = 'forms/FETCH_FORMS_CATALOG_CONTENT';
export const FETCH_FORM_CONTENT = 'forms/FETCH_FORM_CONTENT';

export const setForms = (forms: FormDetails[]): Promise<ActionType> =>
  Promise.resolve(action(SET_FORMS, { forms })).then();

/* ------------- Fetch Forms  ------------- */
export const fetchForms = (successCallback?: () => void, errorCallback?: () => void): ActionType =>
  action(FETCH_FORMS, { successCallback, errorCallback });

export const fetchFormsSuccess = (forms: FormDetails[]): ActionType => action(FETCH_FORMS_SUCCESS, { forms });
export const fetchFormsFail = (error?: string): ActionType => action(FETCH_FORMS_FAIL, { error });

export const fetchFormsCatalogContent = (
  i18n: typeof i18next,
  successCallback?: () => void,
  errorCallback?: () => void,
): ActionType => action(FETCH_FORMS_CATALOG_CONTENT, { i18n, successCallback, errorCallback });

export const fetchFormContent = (
  i18n: typeof i18next,
  formComponent: string,
  version: string,
  successCallback?: () => void,
  errorCallback?: () => void,
): ActionType => action(FETCH_FORM_CONTENT, { i18n, formComponent, version, successCallback, errorCallback });
