import i18next from 'i18next';
import { action } from 'typesafe-actions';
import { Order } from '../../../models/Order';

export interface ActionType {
  type: 'forms/FETCH_ORDERS' | 'forms/FETCH_ORDERS_SUCCESS' | 'forms/FETCH_ORDERS_FAIL';

  payload: {
    i18n?: typeof i18next;
    orders?: Order[];
    error?: string;
    successCallback?: () => void;
    errorCallback?: () => void;
  };
}

export const FETCH_ORDERS = 'forms/FETCH_ORDERS';
export const FETCH_ORDERS_SUCCESS = 'forms/FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAIL = 'forms/FETCH_ORDERS_FAIL';

/* ------------- Fetch Orders  ------------- */
export const fetchOrders = (successCallback?: () => void, errorCallback?: () => void): ActionType =>
  action(FETCH_ORDERS, { successCallback, errorCallback });

export const fetchOrdersSuccess = (orders: Order[]): ActionType => action(FETCH_ORDERS_SUCCESS, { orders });
export const fetchOrdersFail = (error?: string): ActionType => action(FETCH_ORDERS_FAIL, { error });
