import React, { memo, ReactNode } from 'react';

import CTAButton from 'components/CTAButton/CTAButton';
import useBackHandler from 'hooks/useBackHandler';
import FormDetailsHeader from '../FormDetailsHeader/FormDetailsHeader';
import './FormDetailsLayout.scss';

export interface Props<T = never> {
  title: ReactNode;
  id: string;
  ctaLabel: string;
  ctaLoadingLabel: string;
  secondaryCtaLabel?: string;
  children: ReactNode;
  onSubmit: (data: T) => unknown;
  loading?: boolean;
  submitError?: string | null;
  className?: string;
  beforeHeaderNode?: ReactNode;
  afterHeaderNode?: ReactNode;
}

export const FormDetailsLayout = memo<Props>(
  ({
    title,
    id = '',
    ctaLabel,
    ctaLoadingLabel,
    secondaryCtaLabel,
    children,
    onSubmit,
    submitError,
    loading,
    className = '',
    beforeHeaderNode,
    afterHeaderNode,
  }) => {
    const handleBack = useBackHandler('/forms-and-documents');
    return (
      <div className={`FormDetailsLayout ${className}`}>
        {beforeHeaderNode}
        <FormDetailsHeader title={title} id={id} />
        {afterHeaderNode}
        <form onSubmit={onSubmit} className="FormDetailsLayout-form">
          <div className="FormDetailsLayout-form-container">{children}</div>
          <span className={`FormDetailsLayout-form-error ${submitError ? 'mod-show' : ''}`}>{`${submitError}`}</span>
          <div className="FormDetailsLayout-form-buttons">
            <CTAButton type="submit" text={loading ? ctaLoadingLabel : ctaLabel} disabled={loading} />
            {!!secondaryCtaLabel && (
              <CTAButton type="button" text={secondaryCtaLabel} disabled={loading} invert onClick={handleBack} />
            )}
          </div>
        </form>
      </div>
    );
  },
);

FormDetailsLayout.displayName = 'FormDetailsLayout';

export default FormDetailsLayout;
