const formatUSPhoneNumber = (value: string): string => {
  const pureNumValue = value.replace(/\D/g, '');
  const areaCode = pureNumValue.slice(0, 3);
  const firstPart = pureNumValue.slice(3, 6);
  const lastPart = pureNumValue.slice(6, 10);

  let str = '';

  if (areaCode.length > 0) str += `(${areaCode}`;
  if (firstPart.length > 0) str += `) ${firstPart}`;
  if (lastPart.length > 0) str += `-${lastPart}`;

  return str;
};

export default formatUSPhoneNumber;
