import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';

import AppVersionCheckPrompt from 'components/AppVersionCheckPrompt';
import MainRouter from './router';
import { store, persistor } from './store';
import './i18n';
import i18nNamespaces from './i18n/i18nNamespaces';
import ToolsContext from './context/ToolsContext';
import useWindowDimensions from './hooks/useWindowDimensions';

const App: React.FC = () => {
  const { t } = useTranslation(i18nNamespaces.APP);
  const { device = 'desktop' } = useWindowDimensions();
  const [isMainNavigationOpen, setIsMainNavigationOpen] = useState<boolean>(device === 'desktop');

  return (
    <>
      <ToolsContext.Provider value={{ isMainNavigationOpen, setIsMainNavigationOpen, device }}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Helmet>
              <title>{t('title')}</title>
            </Helmet>
            <MainRouter />
          </PersistGate>
        </Provider>
      </ToolsContext.Provider>
      <AppVersionCheckPrompt />
    </>
  );
};

export default App;
