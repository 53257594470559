import React, { ReactNode } from 'react';
import './FormSection.scss';

type InsetEdge = 'left' | 'top' | 'right' | 'bottom';

interface Props {
  title?: ReactNode;
  description?: ReactNode;
  inset?: InsetEdge[];
}

const FormSection: React.FC<Props> = ({ children, title, description, inset = [] }) => (
  <div className="FormSection">
    {!!title && <h2>{title}</h2>}
    {!!description && <p>{description}</p>}
    <div className={`FormSection-content ${inset.map((c) => `mod-inset-${c}`)}`}>{children}</div>
    <hr />
  </div>
);

export default FormSection;
