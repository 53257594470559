import { Appointment } from '../../../models/Appointment';
import * as AppointmentsActions from './action';

export interface State {
  appointments: Appointment[];
}

const initialState: State = {
  appointments: [],
};

export default function reducer(state: State = initialState, action: AppointmentsActions.ActionType): State {
  switch (action.type) {
    case AppointmentsActions.FETCH_APPOINTMENTS:
    case AppointmentsActions.FETCH_APPOINTMENTS_SUCCESS:
    case AppointmentsActions.FETCH_APPOINTMENTS_FAIL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
