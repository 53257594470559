import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps, RouteComponentProps, Redirect } from 'react-router-dom';
import { setToken } from '../../../api/axios';
import { selectUserAuthResponseData } from '../../../store/UserDetailsDuck/duck/selector';

interface PrivateRouteProps extends RouteProps {
  component: FC<RouteComponentProps<any>>;
}

export const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps): JSX.Element => {
  const authResponse = useSelector(selectUserAuthResponseData);

  useEffect(() => {
    const token = authResponse?.accessToken?.token;
    if (token) setToken(token);
  }, [authResponse?.accessToken?.token]);

  const renderComponent = (routeProps: RouteComponentProps) => {
    // Redirect to login page if unauthorized
    if (!(authResponse?.accessToken?.token && authResponse.status === 'ok')) {
      return <Redirect to={{ pathname: '/', state: { from: routeProps.location } }} />;
    }
    return <Component {...routeProps} />;
  };

  return <Route {...rest} render={(routeProps) => renderComponent(routeProps)} />;
};

export default PrivateRoute;
