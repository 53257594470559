import React, { memo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import OTPVerifyLayout from '../../layouts/OTPVerifyLayout/OTPVerifyLayout';

export const ForgotPasswordVerify = memo<RouteComponentProps>((routeProps) => {
  return <OTPVerifyLayout {...routeProps} from="/forgot-password" to="/reset-password" withToken />;
});

ForgotPasswordVerify.displayName = 'ForgotPasswordVerify';

export default ForgotPasswordVerify;
