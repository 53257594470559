import { RootState } from 'MyTypes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { format, isBefore } from 'date-fns';
import { useHistory } from 'react-router-dom';
import HeaderCard from '../../components/HeaderCard/HeaderCard';
import i18nNamespaces from '../../i18n/i18nNamespaces';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import { CurrentUserDetails } from '../../models/CurrentUserDetails';
import { selectCurrentUserDetails } from '../../store/UserDetailsDuck/duck/selector';
import * as UserDetailsActions from '../../store/UserDetailsDuck/duck/action';
import './Appointments.scss';
import ListFilterTabs, { ListFilterTabItem } from '../../components/ListFilterTabs/ListFilterTabs';
import ItemList, { ListItem } from '../../components/ItemList/ItemList';
import { Appointment } from '../../models/Appointment';
import * as AppointmentsActions from '../../store/AppointmentsDuck/duck/action';
import { selectAllAppointments } from '../../store/AppointmentsDuck/duck/selector';

const tabKeys = {
  UPCOMING: 'upcoming',
  PAST: 'past',
  ALL: 'all',
};

interface Props {
  appointments: Appointment[];
  currentUserDetails: CurrentUserDetails | null;
  fetchCurrentUserDetails: (
    successCallback?: () => void,
    errorCallback?: () => void,
  ) => Promise<UserDetailsActions.ActionType>;
  fetchAppointments: (
    successCallback?: () => void,
    errorCallback?: () => void,
  ) => Promise<AppointmentsActions.ActionType>;
}

const Appointments = ({ appointments, currentUserDetails, fetchCurrentUserDetails, fetchAppointments }: Props) => {
  const history = useHistory();
  /** ------------------------------- Content ------------------------------- */
  const { t } = useTranslation(i18nNamespaces.APPOINTMENTS);

  /** ------------------------------- Fetch Appointments ------------------------------- */
  const [isRefreshingContent, setIsRefreshingContent] = useState(true);

  const refreshAppointments = useCallback(() => {
    setIsRefreshingContent(true);
    const finallyCallback = () => setIsRefreshingContent(false);
    fetchAppointments(finallyCallback, finallyCallback);
    // @TODO: Handle error
  }, [fetchAppointments]);

  useEffect(() => {
    refreshAppointments();
    if (!currentUserDetails) {
      fetchCurrentUserDetails();
    }
  }, [currentUserDetails, fetchAppointments, fetchCurrentUserDetails, refreshAppointments]);

  /** ------------------------------- Filters tabs ------------------------------- */
  const [activeTabKey, setActiveTabKey] = useState(tabKeys.ALL);
  const tabItems: ListFilterTabItem[] = [
    {
      key: tabKeys.ALL,
      label: t('all'),
    },
    {
      key: tabKeys.UPCOMING,
      label: t('upcoming'),
    },
    {
      key: tabKeys.PAST,
      label: t('past'),
    },
  ];

  /** ------------------------------- Handlers ------------------------------- */
  const handleTabClick = useCallback(
    (tabKey: string) => {
      if (tabKey === activeTabKey) return;
      setActiveTabKey(tabKey);
      refreshAppointments();
    },
    [activeTabKey, refreshAppointments],
  );

  /** ------------------------------- Manipulate data ------------------------------- */
  const appointmentItems: ListItem[] = useMemo(() => {
    return appointments
      .sort((a, b) => new Date(b.start as string).valueOf() - new Date(a.start as string).valueOf())
      .map((x) => ({
        id: x.id,
        title: x.store.name,
        remarks: format(new Date(x.start as string), 'MM/dd/yyyy hh:mm a'),
        status: isBefore(new Date(x.start as string), new Date()),
      }))
      .filter((x) => {
        switch (activeTabKey) {
          case tabKeys.UPCOMING:
            return !x.status;
          case tabKeys.PAST:
            return x.status;
          default:
            return true;
        }
      });
  }, [activeTabKey, appointments]);

  const handleAppointmentItemClick = useCallback(({ id }: ListItem) => history.push(`/appointments/${id}`), [history]);

  return (
    <MainLayout className="Appointments" showBackButton backRoutePathname="/" contentMaxWidth={1000}>
      <HeaderCard title={t('title')} description={`#${currentUserDetails?.customerId}`} />
      {/* <CTAButton invert className="Appointments-create-appointment-cta" text={t('create_appointment')} /> */}
      <ListFilterTabs items={tabItems} activeItemKey={activeTabKey} onItemClick={handleTabClick} />
      <ItemList
        trueLabelClassName="Appointments-item-status-true"
        falseLabelClassName="Appointments-item-status-false"
        badgeClassName="Appointments-item-status-badge"
        items={appointmentItems}
        trueLabel={t('expired')}
        falseLabel={t('upcoming_appointment')}
        onItemClick={handleAppointmentItemClick}
        isRefreshing={isRefreshingContent}
        emptyLabel={(() => {
          switch (activeTabKey) {
            case tabKeys.UPCOMING:
              return t('no_upcoming_appointments');
            case tabKeys.PAST:
              return t('no_past_appointments');
            default:
              return t('no_appointments');
          }
        })()}
      />
    </MainLayout>
  );
};

Appointments.displayName = 'Appointments';

const mapStateToProps = (state: RootState): Pick<Props, 'currentUserDetails' | 'appointments'> => ({
  currentUserDetails: selectCurrentUserDetails(state),
  appointments: selectAllAppointments(state),
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<Props, 'fetchCurrentUserDetails' | 'fetchAppointments'> => ({
  fetchCurrentUserDetails: async (successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(await UserDetailsActions.fetchCurrentUserDetails(successCallback, errorCallback)),
  fetchAppointments: async (successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(await AppointmentsActions.fetchAppointments(successCallback, errorCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
