/* eslint-disable react/jsx-one-expression-per-line */
import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import parseHTML from 'html-react-parser';

import InputTextField from 'components/InputTextField/InputTextField';
import SignaturePadField from 'components/SignaturePadField/SignaturePadField';
import RadioField, { RadioOption } from 'components/RadioField/RadioField';
import Config from 'Config';
import { FormComponentProps } from '../../../types/FormComponentProps';
import FormDetailsLayout from '../../FormDetailsLayout/FormDetailsLayout';
import './AdvancedBeneficiaryNotice.scss';

interface Props<T = any> extends FormComponentProps<T> {
  namePlaceholder?: string;
  datePlaceholder?: string;
}

export const AdvancedBeneficiaryNotice = memo<Props>(
  ({
    form,
    onSubmit,
    loading,
    submitError,
    ctaLabel,
    ctaLoadingLabel,
    secondaryCtaLabel,
    namePlaceholder,
    datePlaceholder,
    tForm,
    mode = 'all',
  }) => {
    const patientEnabled = mode === 'patient' || mode === 'all';

    const {
      register,
      handleSubmit,
      formState: { errors },
      getValues,
      setValue,
    } = useForm({
      defaultValues: {
        notifier: Config.companyManhattanStoreAddress,
        notifierPhone: Config.companyCSHotline,
        name: form?.name ?? '',
        customerId: form?.customerId ?? '',
        date: format(new Date(), 'yyyy-MM-dd'),
        itemDescription: form?.adminData?.data?.itemDescription,
        notPayableReason: form?.adminData?.data?.notPayableReason,
        estimatedCost: form?.adminData?.data?.estimatedCost,
      } as { [key: string]: string },
    });
    const { signature: signatureValue } = getValues();
    const itemName = form?.paOrderData?.itemOrdered ?? '';

    const tFormAlphabetOrder = (alphabetKey: string, transKey: string) => `${tForm(alphabetKey)}. ${tForm(transKey)}`;

    const renderBasicInfo = () => (
      <>
        <InputTextField
          {...register('notifier')}
          label={tFormAlphabetOrder('A', 'notifier')}
          type="text"
          status="disabled"
          className="AdvancedBeneficiaryNotice-field-notifier"
        />
        <InputTextField {...register('notifierPhone')} type="text" status="disabled" />
        <InputTextField
          {...register('name')}
          label={tFormAlphabetOrder('B', 'patientName')}
          placeholder={namePlaceholder}
          type="text"
          status="disabled"
        />
        <InputTextField
          {...register('customerId')}
          label={tFormAlphabetOrder('C', 'identificationNumber')}
          type="text"
          status="disabled"
        />
      </>
    );

    const renderDeclareStatement = () => (
      <p>
        <strong>
          <u>{tForm('note').toUpperCase()}:</u>
        </strong>
        &nbsp;
        {parseHTML(tForm('declareStatement', { itemName }))}
      </p>
    );

    const renderItemDetailsFields = (fieldsDisabled?: boolean) => {
      const defaultStatus = fieldsDisabled ? 'disabled' : 'active';
      return (
        <>
          <InputTextField
            {...register('itemDescription')}
            label={`${tForm('D')}. ${itemName} - ${tForm('itemDescription')}:`}
            type="textarea"
            maxLength={240}
            status={errors?.itemDescription ? 'error' : defaultStatus}
          />
          <InputTextField
            {...register('notPayableReason')}
            label={tFormAlphabetOrder('E', 'reasonMayNotPay')}
            type="textarea"
            maxLength={240}
            status={errors?.notPayableReason ? 'error' : defaultStatus}
          />
          <InputTextField
            {...register('estimatedCost')}
            label={tFormAlphabetOrder('F', 'estimatedCost')}
            type="number"
            min={1}
            max={9999999999}
            step="0.01"
            status={errors?.estimatedCost ? 'error' : defaultStatus}
            className="FormDetailsLayout-form-field-amount"
            elementAfter={<div className="FormDetailsLayout-form-field-amount-prefix">USD $</div>}
          />
        </>
      );
    };

    const renderPatientFields = () => {
      const ctaBulletPoints = tForm('ctaBulletPoints', { returnObjects: true, itemName });
      const ctaBulletPointsText = `<ul>${
        Array.isArray(ctaBulletPoints)
          ? (ctaBulletPoints as unknown as string[])?.map((pt) => `<li>${pt}</li>`).join('')
          : ''
      }</ul>`;
      const optionsItems = tForm('optionItems', { returnObjects: true, itemName });
      const options: RadioOption[] = Array.isArray(optionsItems)
        ? optionsItems.map((text, i) => ({
            value: `${i + 1}`,
            label: (
              <>
                <strong>{`${tForm('option')} ${i + 1}: `}</strong>
                {parseHTML(text)}
              </>
            ),
          }))
        : [];

      return (
        <>
          <p>
            <strong>{tForm('ctaTitle')}</strong>
            {parseHTML(ctaBulletPointsText)}
          </p>
          <RadioField
            {...register('option', { required: true })}
            label={`${tFormAlphabetOrder('G', 'option_plural')}: ${tForm('optionsInstruction')}`}
            options={options}
            status={errors?.option ? 'error' : 'active'}
          />
          <InputTextField
            {...register('additionalInfo')}
            label={tFormAlphabetOrder('H', 'additionalInfo')}
            status={errors?.additionalInfo ? 'error' : 'active'}
            type="textarea"
            maxLength={250}
          />
          <SignaturePadField
            {...register('signature', { required: true })}
            label={tFormAlphabetOrder('I', 'signature')}
            status={errors?.signature ? 'error' : 'active'}
            value={signatureValue}
            disabled={loading}
            onChange={(value) => setValue('signature', value)}
          />
          <InputTextField
            {...register('date')}
            label={tFormAlphabetOrder('J', 'date')}
            placeholder={datePlaceholder}
            type="date"
            status="disabled"
          />
        </>
      );
    };

    return (
      <FormDetailsLayout
        className="AdvancedBeneficiaryNotice"
        id={form?.id}
        title={tForm('title')}
        ctaLabel={ctaLabel}
        ctaLoadingLabel={ctaLoadingLabel}
        secondaryCtaLabel={secondaryCtaLabel}
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
        submitError={submitError}
      >
        {renderBasicInfo()}
        <hr />
        {mode === 'prefill' ? <br /> : renderDeclareStatement()}
        {renderItemDetailsFields(mode === 'patient')}
        {patientEnabled && (
          <>
            <hr />
            <br />
            {renderPatientFields()}
          </>
        )}
      </FormDetailsLayout>
    );
  },
);

AdvancedBeneficiaryNotice.displayName = 'AdvancedBeneficiaryNotice';

export default AdvancedBeneficiaryNotice;
