import React, { memo, useCallback, useState } from 'react';
import { FieldError, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { InputStatus, InputTextField } from 'components/InputTextField/InputTextField';
import InputDropdownField from 'components/InputDropdownField/InputDropdownField';
import convertNumberBetweenMeasurementUnits, { Unit } from 'utils/convertNumberBetweenMeasurementUnits';
import './MeasurementInputGroup.scss';

interface Props<T = any> {
  name: string;
  unitName: string;
  numberValue: number | string;
  unitValue: number | string;
  setValue: UseFormSetValue<T>;
  register: UseFormRegister<T>;
  getMeasurementOptions: (count: number | string) => { value: string; label: string }[];
  label: string;
  placeholder?: string;
  unitPlaceholder: string;
  errors?: Record<string, FieldError>;
  required?: boolean;
  autoSelectedUnit?: string;
  defaultStatus?: InputStatus;
}

export const MeasurementInputGroup = memo<Props>(
  ({
    name,
    unitName,
    numberValue,
    unitValue,
    setValue,
    register,
    label,
    placeholder,
    unitPlaceholder,
    getMeasurementOptions,
    errors,
    required,
    autoSelectedUnit,
    defaultStatus = 'active',
  }) => {
    const [isDirty, setIsDirty] = useState(false);

    const handleNumChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
      (e) => {
        const { value } = e.target;
        setValue(name, value);
        if (autoSelectedUnit && !unitValue) {
          setValue(unitName, autoSelectedUnit);
          setIsDirty(true);
        }
      },
      [autoSelectedUnit, name, setValue, unitName, unitValue],
    );

    const handleUnitChange = useCallback<React.ChangeEventHandler<HTMLSelectElement>>(
      (e) => {
        const { value } = e.target;
        setValue(unitName, value);

        if (unitValue) {
          setValue(
            name,
            convertNumberBetweenMeasurementUnits(+(numberValue || 0), unitValue as Unit, value as Unit).toFixed(2),
          );
        }
      },
      [name, numberValue, setValue, unitName, unitValue],
    );

    return (
      <div className="MeasurementInputGroup">
        <InputTextField
          {...register(name, { required })}
          label={label}
          placeholder={placeholder}
          type="number"
          step="0.01"
          min={1}
          max={500}
          onChange={handleNumChange}
          status={errors?.[name] ? 'error' : defaultStatus}
          className="MeasurementInputGroup-number"
        />
        <InputDropdownField
          {...register(unitName, { required: !!numberValue })}
          items={getMeasurementOptions(numberValue)}
          placeholder={unitPlaceholder}
          status={errors?.[unitName] ? 'error' : defaultStatus}
          disabled={defaultStatus === 'disabled'}
          onChange={handleUnitChange}
          className={`MeasurementInputGroup-unit ${isDirty ? 'mod-dirty' : ''}`}
        />
      </div>
    );
  },
);

MeasurementInputGroup.displayName = 'MeasurementInputGroup';

export default MeasurementInputGroup;
