import { createSelector } from 'reselect';
import { RootState } from 'MyTypes';
import { State } from './reducer';
import { Appointment } from '../../../models/Appointment';

export const selectAppointmentsState = (state: RootState): State => state.appointments;

export const selectAllAppointments = createSelector(
  selectAppointmentsState,
  (appointmentsState) => appointmentsState.appointments,
);

export const selectAppointment = createSelector(
  selectAllAppointments,
  (_: unknown, id: string) => id,
  (appointments, id) => appointments && appointments.find((item: Appointment) => item.id === id),
);

export const selectUpcomingAppointments = createSelector(selectAppointmentsState, (appointmentsState) =>
  appointmentsState.appointments.filter((item: Appointment) => new Date(item.start) >= new Date()),
);
