import React, { memo, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';

import Config from '../../../../Config';
import useMeasuredElementRef from '../../../../hooks/useMeasuredElementRef';
import { FormDetails } from '../../../../models/FormDetails';
import FormDetailsHeader from '../FormDetailsHeader/FormDetailsHeader';

import './FormPdfPreview.scss';
import { selectUserAuthResponseData } from '../../../../store/UserDetailsDuck/duck/selector';
import i18nNamespaces from '../../../../i18n/i18nNamespaces';

interface Props {
  form: FormDetails | null | undefined;
  isRefreshingContent?: boolean;
}

export const FormPdfPreview = memo<Props>(({ form, isRefreshingContent }) => {
  const { t } = useTranslation(i18nNamespaces.FORM);

  const version = form?.version ?? 'v1';
  const formI18nNamespace = `${form?.formComponent}-${version}`;

  const { t: tForm } = useTranslation(formI18nNamespace);

  const authResponse = useSelector(selectUserAuthResponseData);
  const token = authResponse?.accessToken?.token;
  const isInReview = form?.adminReviewRequired && !form?.adminSigned;

  const [containerDOMRect, setContainerDOMRect] = useState<null | DOMRect>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, remeasureContainer] = useMeasuredElementRef<HTMLDivElement>(setContainerDOMRect);

  const [numPages, setNumPages] = useState<number | null>(null);

  const onDocumentLoadSuccess = useCallback(({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  }, []);

  const pdfDataUrl = useMemo(() => {
    if (form?.signed) {
      if (/^http|https:\/\//.test(form?.formPdf)) {
        return form?.formPdf;
      }
      return new URL(`/s3/${form?.formPdf}`, Config.cdnProxyUrl).toString();
    }
    return null;
  }, [form?.formPdf, form?.signed]);

  const file = useMemo(
    () => ({
      url: pdfDataUrl,
      httpHeaders: { 'x-access-token': token },
    }),
    [pdfDataUrl, token],
  );

  const renderLoading = () => <Skeleton count={1} className="FormPdfPreview-document-loading-skeleton" />;

  // @TODO: Handler load error and render message and retry button
  // @TODO: Render zoom button
  return (
    <div className="FormPdfPreview" ref={remeasureContainer}>
      <FormDetailsHeader id={form?.id ?? ''} title={tForm('title')} isRefreshingContent={isRefreshingContent} />
      {isInReview && <div className="FormPdfPreview-in-review">{t('inReview')}</div>}
      <Document
        file={file}
        className={`FormPdfPreview-document ${isInReview ? 'mod-in-review' : ''}`}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={renderLoading}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} width={containerDOMRect?.width} />
        ))}
      </Document>
    </div>
  );
});

FormPdfPreview.displayName = 'FormPdfPreview';

export default FormPdfPreview;
