import React, { useEffect, useState } from 'react';
import parseHTML from 'html-react-parser';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import useWindowDimensions from 'hooks/useWindowDimensions';
import getAPIErrorI18nKey from 'utils/getAPIErrorI18nKey';
import VisitorJumbotron from '../../components/VisitorJumbotron/VisitorJumbotron';
import InputTextField from '../../components/InputTextField/InputTextField';
import CTAButton from '../../components/CTAButton/CTAButton';
import VisitorLayout from '../../layouts/VisitorLayout/VisitorLayout';
import i18nNamespaces from '../../i18n/i18nNamespaces';
import { postLoginDetails } from '../../api';
import { LoginDetails } from '../../models/LoginDetails';
import { setToken } from '../../api/axios';
import * as UserDetailsActions from '../../store/UserDetailsDuck/duck/action';
import { LoginResponse } from '../../models/LoginResponse';
import './Login.scss';
import ApplicationVersion from '../../components/ApplicationVersion/ApplicationVersion';

interface Props {
  setLoginDetails: (loginDetails: LoginResponse) => Promise<UserDetailsActions.ActionType>;
  clearLoginDetails: () => Promise<UserDetailsActions.ActionType>;
  clearCurrentUserDetails: () => Promise<UserDetailsActions.ActionType>;
  setOTPUsername: (username: string) => Promise<UserDetailsActions.ActionType>;
}

export const Login: React.FC<Props> = ({
  setLoginDetails,
  clearLoginDetails,
  clearCurrentUserDetails,
  setOTPUsername,
}) => {
  const { t } = useTranslation(i18nNamespaces.LOGIN);
  const [loginError, setLoginError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { device } = useWindowDimensions();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginDetails>();

  useEffect(() => {
    Promise.all([clearLoginDetails(), clearCurrentUserDetails()]).then(() => {
      sessionStorage.clear();
    });
  }, [clearCurrentUserDetails, clearLoginDetails]);

  const onSubmit = async (data: LoginDetails) => {
    setLoginError(null);
    setLoading(true);
    try {
      const res = await postLoginDetails(data);

      switch (res.status) {
        case 'ok': {
          if (res.accessToken) setToken(res.accessToken.token);
          setLoginDetails(res);
          setTimeout(() => history.push('/home'), 0);
          break;
        }
        case 'error':
          if (res.error === 'unverified') {
            await setOTPUsername(data.username);
            // Redirect to verification page
            history.push('/login/verify');
          } else {
            setLoginError(res.error ? t(`error_${res.error}`) : null);
          }
          break;
        default:
          break;
      }
    } catch (err) {
      // @TODO: Log error message to server
      setLoginError(t(getAPIErrorI18nKey(err, i18nNamespaces.LOGIN)));
    } finally {
      setLoading(false);
    }
  };

  return (
    <VisitorLayout className="Login" hasError={!!loginError}>
      {({
        formClassName,
        formContainerClassName,
        formErrorClassName,
        formButtonsContainerClassName,
        formHintButtonClassName,
      }) => (
        <>
          <VisitorJumbotron title={parseHTML(t('title'))} description={parseHTML(t('description'))} />
          <form className={formClassName} onSubmit={handleSubmit(onSubmit)}>
            <div className={formContainerClassName}>
              <InputTextField
                {...register('username', { required: true })}
                label={t('email')}
                placeholder="john.appleseed@nymannings.com"
                type="email"
                status={errors?.username ? 'error' : 'active'}
              />
              <InputTextField
                {...register('password', { required: true })}
                type="password"
                label={t('password')}
                placeholder="•••••••••••••••"
                minLength={10}
                status={errors?.password ? 'error' : 'active'}
              />
            </div>
            <span className={formErrorClassName}>{`${loginError}`}</span>
            <div className={formButtonsContainerClassName}>
              <CTAButton type="submit" text={loading ? t('ctaLoading') : t('cta')} disabled={loading} />
              <CTAButton type="button" text={t('secondaryCta')} to="/sign-up" disabled={loading} invert noBorder />
            </div>
          </form>
          <Link to="/forgot-password" className={formHintButtonClassName}>
            {t('bottomCta')}
          </Link>
          <ApplicationVersion
            opacity="0.3"
            className="application-version"
            absolute={device !== 'mobile' ? true : undefined}
          />
        </>
      )}
    </VisitorLayout>
  );
};

Login.displayName = 'Login';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setLoginDetails: async (authResponse: LoginResponse) =>
    dispatch(await UserDetailsActions.setLoginDetails(authResponse)),
  clearLoginDetails: async () => dispatch(await UserDetailsActions.clearLoginDetails()),
  clearCurrentUserDetails: async () => dispatch(await UserDetailsActions.clearCurrentUserDetails()),
  setOTPUsername: async (username: string) => dispatch(await UserDetailsActions.setOTPUsername(username)),
});

export default connect(null, mapDispatchToProps)(Login);
