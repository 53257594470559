import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import parseHTML from 'html-react-parser';

import SignaturePadField from 'components/SignaturePadField/SignaturePadField';
import { FormComponentProps } from '../../../types/FormComponentProps';
import FormDetailsLayout from '../../FormDetailsLayout/FormDetailsLayout';
import './PatientOrientationForm.scss';

type Props<T = any> = FormComponentProps<T>;

export const PatientOrientationForm = memo<Props>(
  ({ form, onSubmit, loading, submitError, ctaLabel, ctaLoadingLabel, secondaryCtaLabel, tForm }) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      getValues,
      setValue,
    } = useForm();
    const { signature: signatureValue } = getValues();

    const renderDescription = () => {
      const mainStatementBulletPoints = tForm('mainStatementBulletPoints', { returnObjects: true });
      const bulletPointsText = `<ol>${
        Array.isArray(mainStatementBulletPoints)
          ? (mainStatementBulletPoints as unknown as string[])?.map((pt) => `<li>${pt}</li>`).join('')
          : ''
      }</ol>`;

      const returnAndExchangeParaText = (() => {
        const paragraphs = tForm('returnAndExchangePolicyParagraph', { returnObjects: true });
        return `${Array.isArray(paragraphs) ? (paragraphs as unknown as string[])?.join('<br/><br/>') : ''}`;
      })();

      const specialInstructionParaText = (() => {
        const paragraphs = tForm('specialInstructionParagraph', { returnObjects: true });
        return `${Array.isArray(paragraphs) ? (paragraphs as unknown as string[])?.join('<br/><br/>') : ''}`;
      })();

      const htmlText = [
        tForm('leadIn'),
        bulletPointsText,
        `<h4>${tForm('returnAndExchangePolicyTitle')}</h4>`,
        returnAndExchangeParaText,
        `<h4>${tForm('specialInstructionTitle')}</h4>`,
        specialInstructionParaText,
      ].join('');
      return <p className="PatientOrientationForm-description">{parseHTML(htmlText)}</p>;
    };

    return (
      <FormDetailsLayout
        className="PatientOrientationForm"
        id={form?.id}
        title={tForm('title')}
        ctaLabel={ctaLabel}
        ctaLoadingLabel={ctaLoadingLabel}
        secondaryCtaLabel={secondaryCtaLabel}
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
        submitError={submitError}
      >
        {renderDescription()}
        <SignaturePadField
          {...register('signature', { required: true })}
          label={tForm('signature')}
          status={errors?.signature ? 'error' : 'active'}
          value={signatureValue}
          disabled={loading}
          onChange={(value) => setValue('signature', value)}
        />
      </FormDetailsLayout>
    );
  },
);

PatientOrientationForm.displayName = 'PatientOrientationForm';

export default PatientOrientationForm;
