import { RootState } from 'MyTypes';
import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import HeaderCard from '../../components/HeaderCard/HeaderCard';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import { selectCurrentUserDetails } from '../../store/UserDetailsDuck/duck/selector';
import './MyInformation.scss';
import * as UserDetailsActions from '../../store/UserDetailsDuck/duck/action';
import { CurrentUserDetails } from '../../models/CurrentUserDetails';
import useContent from './hooks/useContent';
import InformationCards from '../../components/InformationCard/InformationCards';

interface Props {
  currentUserDetails: CurrentUserDetails | null;
  fetchCurrentUserDetails: (
    successCallback?: () => void,
    errorCallback?: () => void,
  ) => Promise<UserDetailsActions.ActionType>;
}

export const MyInformation: React.FC<Props> = ({ currentUserDetails, fetchCurrentUserDetails }: Props) => {
  useEffect(() => {
    if (currentUserDetails) return;
    fetchCurrentUserDetails();
  }, [currentUserDetails, fetchCurrentUserDetails]);

  if (!currentUserDetails) {
    return (
      <div className="MyInformation-loading">
        <ClipLoader color="#06bbc9" />
      </div>
    );
  }

  const { cardsConfig, customerId, title } = useContent(currentUserDetails);

  return (
    <MainLayout className="MyInformation" showBackButton backRoutePathname="/" contentMaxWidth={1000}>
      <HeaderCard title={title} description={customerId} />
      <InformationCards cards={cardsConfig} />
    </MainLayout>
  );
};

MyInformation.displayName = 'MyInformation';
const mapStateToProps = (state: RootState): Pick<Props, 'currentUserDetails'> => ({
  currentUserDetails: selectCurrentUserDetails(state),
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<Props, 'fetchCurrentUserDetails'> => ({
  fetchCurrentUserDetails: async (successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(await UserDetailsActions.fetchCurrentUserDetails(successCallback, errorCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyInformation);
