const parseBonafideDate = (date: string): Date | null => {
  if (date?.length !== 8) return null;
  if (!/^(\d){8}$/.test(date)) return null;
  const y = Number(date.substring(0, 4));
  const m = Number(date.substring(4, 6));
  const d = Number(date.substring(6, 9));
  return new Date(y, m, d);
};

export default parseBonafideDate;
