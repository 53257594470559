import React, { memo, ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';
import './FormDetailsHeader.scss';

interface Props {
  title: ReactNode;
  id: string;
  isRefreshingContent?: boolean;
}

export const FormDetailsHeader = memo<Props>(({ title, id, isRefreshingContent }) => {
  return (
    <div className="FormDetailsHeader">
      <span className="FormDetailsHeader-id">{`#${id}`}</span>
      {isRefreshingContent ? (
        <Skeleton count={1} className="FormDetailsHeader-title-loading-skeleton" />
      ) : (
        <h1 className="FormDetailsHeader-title">{title}</h1>
      )}
    </div>
  );
});

FormDetailsHeader.displayName = 'FormDetailsHeader';

export default FormDetailsHeader;
