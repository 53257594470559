import React, { memo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import OTPVerifyLayout from '../../layouts/OTPVerifyLayout/OTPVerifyLayout';

export const SignUpVerify = memo<RouteComponentProps>((routeProps) => {
  return <OTPVerifyLayout {...routeProps} from="/sign-up" to="/sign-up/complete" sendOnMountDisabled />;
});

SignUpVerify.displayName = 'SignUpVerify';

export default SignUpVerify;
