import { createSelector } from 'reselect';
import { RootState } from 'MyTypes';
import { State } from './reducer';

export const selectUserDetails = (state: RootState): State => state.userDetails;

export const selectUserAuthResponseData = createSelector(selectUserDetails, (userDetails) => userDetails.authResponse);

export const selectCurrentUserDetails = createSelector(
  selectUserDetails,
  (userDetails) => userDetails.currentUserDetails,
);

export const selectOTPUsername = createSelector(selectUserDetails, (userDetails) => userDetails.otpUsername);
export const selectOTPPhone = createSelector(selectUserDetails, (userDetails) => userDetails.otpPhone);
export const selectResetPasswordToken = createSelector(
  selectUserDetails,
  (userDetails) => userDetails.resetPasswordToken,
);
