import { ActionType } from 'typesafe-actions';
import { CurrentUserDetails } from '../../../models/CurrentUserDetails';
import { LoginResponse } from '../../../models/LoginResponse';
import * as UserDetailsActions from './action';

export interface State {
  currentUserDetails: CurrentUserDetails | null;
  authResponse: LoginResponse | null;
  otpUsername: string;
  otpPhone: string;
  resetPasswordToken: string;
}

const initialState: State = {
  currentUserDetails: null,
  authResponse: null,
  otpUsername: '',
  otpPhone: '',
  resetPasswordToken: '',
};

type UserDetailsActionsType = ActionType<typeof UserDetailsActions>;

export default function reducer(state: State = initialState, action: UserDetailsActionsType): State {
  switch (action.type) {
    case UserDetailsActions.CLEAR_LOGIN_DETAILS:
      return {
        ...state,
        currentUserDetails: initialState.currentUserDetails,
        authResponse: initialState.authResponse,
        otpUsername: initialState.otpUsername,
        otpPhone: initialState.otpPhone,
        resetPasswordToken: initialState.resetPasswordToken,
      };
    case UserDetailsActions.SET_RESET_PASSWORD_TOKEN:
    case UserDetailsActions.SET_OTP_USERNAME:
    case UserDetailsActions.SET_OTP_PHONE:
    case UserDetailsActions.SET_LOGIN_DETAILS:
    case UserDetailsActions.REFRESH_TOKEN_SUCCESS:
    case UserDetailsActions.FETCH_CURRENT_USER_DETAILS:
    case UserDetailsActions.FETCH_CURRENT_USER_DETAILS_SUCCESS:
    case UserDetailsActions.FETCH_CURRENT_USER_DETAILS_FAIL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
