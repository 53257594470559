import React, { memo, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import parseHTML from 'html-react-parser';
import { format } from 'date-fns';

import { InputTextField } from 'components/InputTextField/InputTextField';
import RadioField from 'components/RadioField/RadioField';
import SignaturePadField from 'components/SignaturePadField/SignaturePadField';
import Config from 'Config';
import getFormRelationshipOptions from '../../../utils/getFormRelationshipOptions';
import useFormRepresentativeField from '../../../hooks/useFormRepresentativeField';
import { FormComponentProps } from '../../../types/FormComponentProps';
import FormDetailsLayout from '../../FormDetailsLayout/FormDetailsLayout';
import './PatientConsentForm.scss';

interface Props<T = any> extends FormComponentProps<T> {
  namePlaceholder?: string;
  datePlaceholder?: string;
}

export const PatientConsentForm = memo<Props>(
  ({
    form,
    onSubmit,
    loading,
    submitError,
    ctaLabel,
    ctaLoadingLabel,
    secondaryCtaLabel,
    namePlaceholder,
    datePlaceholder,
    tForm,
    mode = 'all',
  }) => {
    const patientEnabled = mode === 'patient' || mode === 'all';
    const adminEnabled = mode === 'admin' || mode === 'all';

    /** ------------------------------- Radio Options ------------------------------- */
    const relationshipRadioOptions = useMemo(() => getFormRelationshipOptions(tForm), [tForm]);

    const {
      register,
      handleSubmit,
      formState: { errors },
      getValues,
      setValue,
      watch,
    } = useForm({
      defaultValues: {
        name: form?.name ?? '',
        date: format(new Date(), 'yyyy-MM-dd'),
        witnessDate: adminEnabled ? format(new Date(), 'yyyy-MM-dd') : undefined,
      } as { [key: string]: string },
    });
    const { signature: signatureValue, witnessSignature: witnessSignatureValue } = getValues();

    const { handleRepresentativeNameChange, isSelfRepresentative } = useFormRepresentativeField(watch, setValue);

    const renderDescription = () => {
      const mainParagraph = tForm('mainStatementParagraph', { returnObjects: true });
      const mainParaText = `${
        Array.isArray(mainParagraph) ? (mainParagraph as unknown as string[])?.join('<br/><br/>') : ''
      }`;

      const mainStatementBulletPoints = tForm('mainStatementBulletPoints', { returnObjects: true });
      const bulletPointsText = `<ul>${
        Array.isArray(mainStatementBulletPoints)
          ? (mainStatementBulletPoints as unknown as string[])?.map((pt) => `<li>${pt}</li>`).join('')
          : ''
      }</ul>`;

      const formStatement = tForm('formStatement');

      const htmlText = [mainParaText, bulletPointsText, formStatement].join('<br/>');
      return <p className="PatientConsentForm-description">{parseHTML(htmlText)}</p>;
    };

    const renderPatientFields = () => (
      <>
        <InputTextField
          {...register('name')}
          label={tForm('name')}
          placeholder={namePlaceholder}
          type="text"
          status="disabled"
        />
        <SignaturePadField
          {...register('signature', { required: true })}
          label={tForm('signature')}
          status={errors?.signature ? 'error' : 'active'}
          value={signatureValue}
          disabled={loading}
          onChange={(value) => setValue('signature', value)}
        />
        <InputTextField
          {...register('date')}
          label={tForm('date')}
          placeholder={datePlaceholder}
          type="date"
          status="disabled"
        />
        <p className="PatientConsentForm-form-consentText">{tForm('patientConsentTo')}</p>
        <InputTextField
          {...register('representativeName', { required: true })}
          label={tForm('name')}
          placeholder={namePlaceholder}
          type="text"
          disabled={isSelfRepresentative || loading}
          maxLength={50}
          onChange={handleRepresentativeNameChange}
        />
        <RadioField
          {...register('relationship', { required: true })}
          label={tForm('relationship')}
          options={relationshipRadioOptions}
          disabled={loading}
          status={errors?.relationship ? 'error' : 'active'}
        />
      </>
    );

    const renderWitnessFields = () => (
      <>
        <InputTextField
          {...register('witnessName', { required: true })}
          label={tForm('witnessName')}
          placeholder={namePlaceholder}
          type="text"
          status={errors?.witnessName ? 'error' : 'active'}
        />
        <SignaturePadField
          {...register('witnessSignature', { required: true })}
          label={tForm('witnessSignature')}
          status={errors?.signature ? 'error' : 'active'}
          value={witnessSignatureValue}
          onChange={(value) => setValue('witnessSignature', value)}
        />
        <InputTextField {...register('witnessDate')} label={tForm('date')} type="date" status="disabled" />
      </>
    );

    return (
      <FormDetailsLayout
        className="PatientConsentForm"
        id={form?.id}
        title={tForm('title')}
        ctaLabel={ctaLabel}
        ctaLoadingLabel={ctaLoadingLabel}
        secondaryCtaLabel={secondaryCtaLabel}
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
        submitError={submitError}
        afterHeaderNode={renderDescription()}
      >
        {!!patientEnabled && renderPatientFields()}
        {!!adminEnabled && renderWitnessFields()}
        <div className="PatientConsentForm-form-remark">
          {tForm('providerCompany')}
          :&nbsp;
          {tForm('companyName')}
          <br />
          {Config.companyUrl}
        </div>
      </FormDetailsLayout>
    );
  },
);

PatientConsentForm.displayName = 'PatientConsentForm';

export default PatientConsentForm;
