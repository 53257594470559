import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export type BackHandler = () => void;

const useBackHandler = (backRoutePathname: string | null | undefined): BackHandler => {
  const history = useHistory();

  const handleBack = useCallback(() => {
    if (history.length === 0 && backRoutePathname) {
      history.push(backRoutePathname);
      return;
    }
    history.goBack();
  }, [backRoutePathname, history]);
  return handleBack;
};

export default useBackHandler;
