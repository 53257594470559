import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import i18nNamespaces from '../../i18n/i18nNamespaces';
import ActionCompleteLayout from '../../layouts/ActionCompleteLayout/ActionCompleteLayout';
import { selectOTPUsername } from '../../store/UserDetailsDuck/duck/selector';

export const ResetPasswordComplete = memo(() => {
  const { t } = useTranslation(i18nNamespaces.RESET_PW_SUCCESS);
  const username = useSelector(selectOTPUsername);

  return (
    <ActionCompleteLayout
      title={t('title')}
      description={t('description', { email: username })}
      ctaLabel={t('cta')}
      to="/"
    />
  );
});

ResetPasswordComplete.displayName = 'ResetPasswordComplete';

export default ResetPasswordComplete;
