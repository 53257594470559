export const withUnit = (name: string, formData: Record<string, string>): string =>
  `${formData[name]} ${formData[`${name}Unit`]}`;

export const dataMapWithUnit = (dataMap: Record<string, string>) =>
  Object.keys(dataMap).reduce((acc, key) => {
    if (/Unit$/.test(key)) return acc;
    return { ...acc, [key]: withUnit(key, dataMap) };
  }, {});

export const skipUnitFields = (dataMap: Record<string, any>) =>
  Object.entries(dataMap).reduce((acc, [key, value]) => {
    if (/Unit$/.test(key)) return acc;
    return { ...acc, [key]: value };
  }, {});
