import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18nNamespaces from '../../../../i18n/i18nNamespaces';
import './TopToolbar.scss';
import LangSwitchButton from '../../../../components/LangSwitchButton/LangSwitchButton';
import ModalButtonList, { ButtonList } from '../../../../components/ModalButtonList/ModalButtonList';
import languages from '../../../../i18n/languages';
import i18n from '../../../../i18n';
import useBackHandler from '../../../../hooks/useBackHandler';

interface Props {
  /** Used when there is no previous route from history API */
  showBackButton?: boolean;
  backRoutePathname?: string;
  backButtonDisabled?: boolean;
  maxWidth?: number;
}

export const TopToolbar = memo<Props>(({ showBackButton, backRoutePathname, backButtonDisabled, maxWidth }) => {
  const { t } = useTranslation(i18nNamespaces.APP);
  const handleBack = useBackHandler(backRoutePathname);
  const [isLangSwitchOpen, setIsLangSwitchOpen] = useState(false);

  const langList: ButtonList[] = Object.values(languages).map((langCode) => {
    const tLng = i18n.getFixedT(langCode, i18nNamespaces.APP);
    return {
      title: tLng('lang'),
      onClick: () => i18n.changeLanguage(langCode),
      active: langCode === i18n.language || undefined,
    };
  });

  return (
    <div className="TopToolbar" style={{ maxWidth }}>
      {showBackButton && (
        <button type="button" className="TopToolbar-back-button" onClick={handleBack} disabled={backButtonDisabled}>
          {t('goBack')}
        </button>
      )}
      <div className="TopToolbar-lang-switch-container">
        <LangSwitchButton onClick={useCallback(() => setIsLangSwitchOpen(true), [])} />
      </div>
      <ModalButtonList
        list={langList}
        isOpen={isLangSwitchOpen}
        onRequestClose={useCallback(() => setIsLangSwitchOpen(false), [])}
      />
    </div>
  );
});

TopToolbar.displayName = 'TopToolbar';

export default TopToolbar;
