const i18nNamespaces = {
  APP: 'app',
  OTP_VERIFY: 'otp-verify',
  LOGIN: 'login',
  SIGNUP: 'signup',
  SIGNUP_SUCCESS: 'signup-complete',
  FORGOT_PW: 'forgot-pw',
  RESET_PW: 'reset-pw',
  RESET_PW_SUCCESS: 'reset-pw-complete',
  HOME: 'home',
  FORMS_AND_DOCUMENTS: 'forms-and-documents',
  FORM: 'form',
  MY_INFORMATION: 'my-information',
  APPOINTMENTS: 'appointments',
  ORDERS: 'orders',
  MEASUREMENT: 'measurement',
} as const;

export default i18nNamespaces;
