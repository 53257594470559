import { TFunction } from 'react-i18next';

const getFormRelationshipOptions = (tForm: TFunction) => [
  { value: 'self', label: tForm('self') },
  { value: 'homeAttendant', label: tForm('homeAttendant') },
  { value: 'family', label: tForm('family') },
  { value: 'friend', label: tForm('friend') },
];

export default getFormRelationshipOptions;
