import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import parseHTML from 'html-react-parser';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'MyTypes';
import { ClipLoader } from 'react-spinners';
import { Order } from 'models/Order';
import { selectAllOrders } from 'store/OrdersDuck/duck/selector';
import parseBonafideDate from 'utils/parseBonafideDate';
import i18nNamespaces from '../../i18n/i18nNamespaces';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import './Home.scss';
import { NotificationPreviewDataItem } from './components/NotificationsPreview/NotificationsPreview';
import { IconCardButtonProps } from './components/IconCardButton/IconCardButton';
import * as FormsActions from '../../store/FormsDuck/duck/action';
import * as UserDetailsActions from '../../store/UserDetailsDuck/duck/action';
import * as AppointmentsActions from '../../store/AppointmentsDuck/duck/action';
import * as NotificationsActions from '../../store/NotificationsDuck/duck/action';
import * as OrdersActions from '../../store/OrdersDuck/duck/action';
import calendarIcon from '../../assets/icons/calendar.svg';
import userIcon from '../../assets/icons/user.svg';
import fileTextIcon from '../../assets/icons/file-text.svg';
import truckIcon from '../../assets/icons/truck.svg';
import wheelChairBackground from '../../assets/images/wheelchair_white_bg.jpg';
import { FormDetails } from '../../models/FormDetails';
import { selectUnsignedForms } from '../../store/FormsDuck/duck/selector';
import { selectCurrentUserDetails } from '../../store/UserDetailsDuck/duck/selector';
import { CurrentUserDetails } from '../../models/CurrentUserDetails';
import { selectUpcomingAppointments } from '../../store/AppointmentsDuck/duck/selector';
import { Appointment } from '../../models/Appointment';
import { selectAllNotifications } from '../../store/NotificationsDuck/duck/selector';
import { NotificationDetails } from '../../models/Notifications';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import HomeContext from './context';
import Mobile from './screens/Mobile/Mobile';
import Desktop from './screens/Desktop/Desktop';

interface Props {
  orders: Order[];
  notifications: NotificationDetails[];
  currentUserDetails: CurrentUserDetails | null;
  unsignedForms: FormDetails[];
  upcomingAppointments: Appointment[];
  fetchForms: (successCallback?: () => void, errorCallback?: () => void) => Promise<FormsActions.ActionType>;
  fetchCurrentUserDetails: (
    successCallback?: () => void,
    errorCallback?: () => void,
  ) => Promise<UserDetailsActions.ActionType>;
  fetchAppointments: (
    successCallback?: () => void,
    errorCallback?: () => void,
  ) => Promise<AppointmentsActions.ActionType>;
  fetchNotifications: (
    limit?: number,
    successCallback?: () => void,
    errorCallback?: () => void,
  ) => Promise<NotificationsActions.ActionType>;
  fetchOrders: (successCallback?: () => void, errorCallback?: () => void) => Promise<OrdersActions.ActionType>;
}

export const Home: React.FC<Props> = ({
  orders,
  notifications: initialNotificationsState,
  upcomingAppointments,
  currentUserDetails,
  unsignedForms,
  fetchForms,
  fetchCurrentUserDetails,
  fetchAppointments,
  fetchNotifications,
  fetchOrders,
}: Props) => {
  const { t } = useTranslation(i18nNamespaces.HOME);
  const { device } = useWindowDimensions();

  useEffect(() => {
    fetchOrders();
    fetchForms();
    fetchCurrentUserDetails();
    fetchAppointments();
    fetchNotifications();
  }, [fetchForms, fetchCurrentUserDetails, fetchAppointments, fetchNotifications, fetchOrders]);

  const notifications: NotificationPreviewDataItem[] = useMemo(() => {
    return initialNotificationsState
      .map((notification) => {
        switch (notification.type) {
          case 'appointment':
            return {
              key: notification.id,
              icon: calendarIcon,
              title: `Appointment at ${notification?.start}`,
              pathname: `/appointments/${notification?.id}`,
            };
          case 'form':
            return {
              key: notification.id,
              icon: fileTextIcon,
              title: `New form -  ${notification?.formTitle}`,
              pathname: `/form/${notification?.id}`,
            };
          default:
            return null;
        }
      })
      .filter((x) => x !== null) as NotificationPreviewDataItem[];
  }, [initialNotificationsState]);

  if (!currentUserDetails) {
    return (
      <div className="Home-loading">
        <ClipLoader color="#06bbc9" />
      </div>
    );
  }

  const customerId = `#${currentUserDetails.customerId}`;
  const firstName = `${currentUserDetails?.firstname ?? ''}`;
  const lastName = ` ${currentUserDetails?.middleinitial ?? ''} ${currentUserDetails?.lastname ?? ''}`;

  const buttonConfigs: IconCardButtonProps[][] = [
    [
      {
        icon: calendarIcon,
        title: t('appointments'),
        badgeNumber: upcomingAppointments.length,
        to: '/appointments',
      },
      {
        icon: userIcon,
        title: t('myInformation'),
        to: '/my-information',
      },
    ],
    [
      {
        icon: fileTextIcon,
        title: t('formsAndDocuments'),
        badgeNumber: unsignedForms.length,
        to: '/forms-and-documents',
      },
      {
        icon: truckIcon,
        title: t('orders'),
        to: '/orders',
      },
    ],
    [
      {
        backgroundImage: wheelChairBackground,
        title: t('viewAllProducts'),
        titleAlign: 'left',
        to: '/products/all',
        disabled: true,
      },
    ],
  ];

  const renderScreen = () => {
    switch (device) {
      case 'mobile':
      case 'tablet':
        return <Mobile />;
      default:
        return <Desktop />;
    }
  };

  const nextAppointment =
    upcomingAppointments.sort((a, b) => new Date(a?.start).getTime() - new Date(b?.start).getTime())[0] || null;

  const latestUnsignedForm =
    unsignedForms.sort((a, b) => new Date(a?.createdAt).getTime() - new Date(b?.createdAt).getTime())[0] || null;

  const lastOrderedItem =
    orders.sort(
      (a, b) =>
        (parseBonafideDate(a.orderdate)?.getTime() || Infinity) -
        (parseBonafideDate(b.orderdate)?.getTime() || Infinity),
    )[0] || null;

  return (
    <HomeContext.Provider
      value={{
        buttonConfigs,
        headerNode: parseHTML(t('title', { firstName, lastName })),
        customerId,
        notifications,
        nextAppointment,
        latestUnsignedForm,
        lastOrderedItem,
      }}
    >
      <MainLayout className="Home" contentMaxWidth={2400}>
        {renderScreen()}
      </MainLayout>
    </HomeContext.Provider>
  );
};

Home.displayName = 'Home';

const mapStateToProps = (
  state: RootState,
): Pick<Props, 'unsignedForms' | 'currentUserDetails' | 'upcomingAppointments' | 'notifications' | 'orders'> => ({
  unsignedForms: selectUnsignedForms(state),
  currentUserDetails: selectCurrentUserDetails(state),
  upcomingAppointments: selectUpcomingAppointments(state),
  notifications: selectAllNotifications(state),
  orders: selectAllOrders(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
): Pick<
  Props,
  'fetchForms' | 'fetchCurrentUserDetails' | 'fetchAppointments' | 'fetchNotifications' | 'fetchOrders'
> => ({
  fetchForms: async (successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(await FormsActions.fetchForms(successCallback, errorCallback)),
  fetchCurrentUserDetails: async (successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(await UserDetailsActions.fetchCurrentUserDetails(successCallback, errorCallback)),
  fetchAppointments: async (successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(await AppointmentsActions.fetchAppointments(successCallback, errorCallback)),
  fetchNotifications: async (limit?: number, successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(await NotificationsActions.fetchNotifications(limit, successCallback, errorCallback)),
  fetchOrders: async (successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(await OrdersActions.fetchOrders(successCallback, errorCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
