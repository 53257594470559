import { put, takeLatest, call, ForkEffect, CallEffect, PutEffect } from 'redux-saga/effects';
import { Action } from 'typesafe-actions';
import { getAppointments } from '../../../api';
import { Appointment, AppointmentsResponse } from '../../../models/Appointment';
import * as AppointmentsActions from './action';

export function* handleGetAppointments(
  action: AppointmentsActions.ActionType,
  successAction: (appointments: Appointment[]) => AppointmentsActions.ActionType,
  failAction: (error?: string | undefined) => AppointmentsActions.ActionType,
): Generator<CallEffect<AppointmentsResponse> | PutEffect<Action<string>>, void, AppointmentsResponse> {
  const {
    payload: { successCallback, errorCallback },
  } = action;

  try {
    const { data } = yield call(getAppointments);
    if (!data) return;
    yield put(successAction(data));
    if (successCallback) successCallback();
  } catch (error) {
    yield put(failAction(`${error}`));
    if (errorCallback) errorCallback();
  }
}

export function* watchGetAppointments(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(AppointmentsActions.FETCH_APPOINTMENTS, (action: AppointmentsActions.ActionType) =>
    handleGetAppointments(
      action,
      AppointmentsActions.fetchAppointmentsSuccess,
      AppointmentsActions.fetchAppointmentsFail,
    ),
  );
}
