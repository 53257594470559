import React, { ChangeEvent, useState } from 'react';
import parseHTML from 'html-react-parser';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { format } from 'date-fns';

import getAPIErrorI18nKey from 'utils/getAPIErrorI18nKey';
import VisitorJumbotron from '../../components/VisitorJumbotron/VisitorJumbotron';
import './SignUp.scss';
import InputTextField from '../../components/InputTextField/InputTextField';
import CTAButton from '../../components/CTAButton/CTAButton';
import VisitorLayout from '../../layouts/VisitorLayout/VisitorLayout';
import PhoneInputWrapper from '../../components/PhoneInputWrapper/PhoneInputWrapper';
import i18nNamespaces from '../../i18n/i18nNamespaces';
import formatUSPhoneNumber from '../../utils/formatUSPhoneNumber';
import isValidUSPhoneNumber from '../../utils/isValidUSPhoneNumber';
import { RegisterUserDetails } from '../../models/RegisterUserDetails';
import { postRegisterUser } from '../../api';
import * as UserDetailsActions from '../../store/UserDetailsDuck/duck/action';

interface FormData extends RegisterUserDetails {
  confirmPassword: string;
}

interface Props {
  setOTPUsername: (username: string) => Promise<UserDetailsActions.ActionType>;
}

export const SignUp: React.FC<Props> = ({ setOTPUsername }) => {
  const history = useHistory();
  const { t } = useTranslation(i18nNamespaces.SIGNUP);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<FormData>();

  const validatePhone = (phone: string) => {
    if (!isValidUSPhoneNumber(phone)) return t('phoneInvalid');
    return true;
  };

  const validateConfirmPassword = (confirmPassword: string) => {
    const { password } = getValues();
    if (confirmPassword !== password) return t('passwordMismatch');
    return true;
  };

  const onSubmit = async (data: FormData) => {
    setSubmitError(null);
    setLoading(true);
    try {
      const res = await postRegisterUser(data);

      switch (res.status) {
        case 'ok': {
          await setOTPUsername(data.username);
          setTimeout(() => history.push('/sign-up/verify'), 0);
          break;
        }
        case 'error':
          setSubmitError(res.error ? t(`error_${res.error}`) : null);
          break;
        default:
          break;
      }
    } catch (err) {
      // @TODO: Log error message to server
      setSubmitError(t(getAPIErrorI18nKey(err, i18nNamespaces.SIGNUP)));
    } finally {
      setLoading(false);
    }
  };

  return (
    <VisitorLayout hasError={!!submitError}>
      {({
        formClassName,
        formContainerClassName,
        formErrorClassName,
        formRemarkClassName,
        formButtonsContainerClassName,
        formHintButtonClassName,
      }) => (
        <>
          <VisitorJumbotron title={t('title')} description={parseHTML(t('description'))} />
          <form className={formClassName} onSubmit={handleSubmit(onSubmit)}>
            <div className={formContainerClassName}>
              <InputTextField
                {...register('username', { required: true })}
                label={t('email')}
                placeholder="john.appleseed@nymannings.com"
                type="email"
                status={errors?.username ? 'error' : 'active'}
              />
              <PhoneInputWrapper hasError={!!errors?.phone} hasErrorMessage={!!errors?.phone?.message}>
                {({ className, inputClassName, elementAfter }) => (
                  <InputTextField
                    {...register('phone', { required: true, validate: validatePhone })}
                    label={t('phone')}
                    placeholder="(201) 555-5555"
                    type="text"
                    className={className}
                    inputClassName={inputClassName}
                    status={errors?.phone ? 'error' : 'active'}
                    bottomLabel={errors?.phone?.message}
                    elementAfter={elementAfter}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setValue('phone', formatUSPhoneNumber(e.target.value));
                    }}
                    onBlur={(e) => setValue('phone', formatUSPhoneNumber(e.target.value))}
                  />
                )}
              </PhoneInputWrapper>
              <InputTextField
                {...register('dob', { required: true, value: format(new Date(), 'yyyy-MM-dd') })}
                label={t('dob')}
                placeholder={t('dobPlaceholder')}
                type="date"
                status={errors?.dob ? 'error' : 'active'}
              />
              <InputTextField
                {...register('password', { required: true })}
                type="password"
                label={t('password')}
                placeholder="•••••••••••••••"
                minLength={10}
                status={errors?.password ? 'error' : 'active'}
              />
              <InputTextField
                {...register('confirmPassword', { required: true, validate: validateConfirmPassword })}
                type="password"
                label={t('confirmPassword')}
                placeholder="•••••••••••••••"
                minLength={10}
                status={errors?.confirmPassword ? 'error' : 'active'}
                bottomLabel={errors?.confirmPassword?.message}
              />
            </div>
            <span className={formErrorClassName}>{`${submitError}`}</span>
            <p className={formRemarkClassName}>{parseHTML(t('remark'))}</p>
            <div className={formButtonsContainerClassName}>
              <CTAButton type="submit" text={loading ? t('ctaLoading') : t('cta')} disabled={loading} />
            </div>
          </form>
          <Link to="/" className={formHintButtonClassName}>
            {t('bottomCta')}
          </Link>
        </>
      )}
    </VisitorLayout>
  );
};

SignUp.displayName = 'SignUp';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setOTPUsername: async (username: string) => dispatch(await UserDetailsActions.setOTPUsername(username)),
});

export default connect(null, mapDispatchToProps)(SignUp);
