import { action } from 'typesafe-actions';
import { CurrentUserDetails } from '../../../models/CurrentUserDetails';
import { LoginResponse } from '../../../models/LoginResponse';

export interface ActionType {
  type:
    | 'userDetails/FETCH_CURRENT_USER_DETAILS'
    | 'userDetails/FETCH_CURRENT_USER_DETAILS_SUCCESS'
    | 'userDetails/FETCH_CURRENT_USER_DETAILS_FAIL'
    | 'userDetails/SET_LOGIN_DETAILS'
    | 'userDetails/CLEAR_LOGIN_DETAILS'
    | 'userDetails/SET_CURRENT_USER_DETAILS'
    | 'userDetails/CLEAR_CURRENT_USER_DETAILS'
    | 'userDetails/SET_OTP_USERNAME'
    | 'userDetails/SET_OTP_PHONE'
    | 'userDetails/SET_RESET_PASSWORD_TOKEN'
    | 'userDetails/REFRESH_TOKEN'
    | 'userDetails/REFRESH_TOKEN_SUCCESS'
    | 'userDetails/REFRESH_TOKEN_FAIL';
  payload: {
    // @TODO: Apply type
    currentUserDetails?: CurrentUserDetails;
    authResponse?: LoginResponse;
    otpUsername?: string;
    otpPhone?: string;
    resetPasswordToken?: string;
    error?: string;
    successCallback?: () => void;
    errorCallback?: () => void;
  };
}

export const SET_LOGIN_DETAILS = 'userDetails/SET_LOGIN_DETAILS';
export const CLEAR_LOGIN_DETAILS = 'userDetails/CLEAR_LOGIN_DETAILS';
export const SET_CURRENT_USER_DETAILS = 'userDetails/SET_CURRENT_USER_DETAILS';
export const CLEAR_CURRENT_USER_DETAILS = 'userDetails/CLEAR_CURRENT_USER_DETAILS';
export const SET_OTP_USERNAME = 'userDetails/SET_OTP_USERNAME';
export const SET_OTP_PHONE = 'userDetails/SET_OTP_PHONE';
export const SET_RESET_PASSWORD_TOKEN = 'userDetails/SET_RESET_PASSWORD_TOKEN';

export const FETCH_CURRENT_USER_DETAILS = 'userDetails/FETCH_CURRENT_USER_DETAILS';
export const FETCH_CURRENT_USER_DETAILS_SUCCESS = 'userDetails/FETCH_CURRENT_USER_DETAILS_SUCCESS';
export const FETCH_CURRENT_USER_DETAILS_FAIL = 'userDetails/FETCH_CURRENT_USER_DETAILS_FAIL';

export const REFRESH_TOKEN = 'userDetails/REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'userDetails/REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAIL = 'userDetails/REFRESH_TOKEN_FAIL';

// @TODO: Apply type
export const setLoginDetails = (authResponse: LoginResponse): ActionType => action(SET_LOGIN_DETAILS, { authResponse });

export const clearLoginDetails = (): Promise<ActionType> => Promise.resolve(action(CLEAR_LOGIN_DETAILS)).then();

export const setOTPUsername = (username: string): Promise<ActionType> =>
  Promise.resolve(action(SET_OTP_USERNAME, { otpUsername: username })).then();

export const setOTPPhone = (phone: string): Promise<ActionType> =>
  Promise.resolve(action(SET_OTP_PHONE, { otpPhone: phone })).then();

export const setResetPasswordToken = (token: string): Promise<ActionType> =>
  Promise.resolve(action(SET_RESET_PASSWORD_TOKEN, { resetPasswordToken: token })).then();

export const clearCurrentUserDetails = (): Promise<ActionType> =>
  Promise.resolve(action(CLEAR_CURRENT_USER_DETAILS)).then();

/* ------------- Fetch Current User Details  ------------- */
export const fetchCurrentUserDetails = (successCallback?: () => void, errorCallback?: () => void): ActionType =>
  action(FETCH_CURRENT_USER_DETAILS, { successCallback, errorCallback });
export const fetchCurrentUserDetailsSuccess = (currentUserDetails: CurrentUserDetails): ActionType =>
  action(FETCH_CURRENT_USER_DETAILS_SUCCESS, { currentUserDetails });
export const fetchCurrentUserDetailsFail = (error?: string): ActionType =>
  action(FETCH_CURRENT_USER_DETAILS_FAIL, { error });

export const refreshToken = (): ActionType => action(REFRESH_TOKEN, {});
export const refreshTokenSuccess = (authResponse: LoginResponse): ActionType =>
  action(REFRESH_TOKEN_SUCCESS, { authResponse });
export const refreshTokenFail = (error?: string): ActionType => action(REFRESH_TOKEN_FAIL, { error });
