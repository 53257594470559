import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import i18nNamespaces from 'i18n/i18nNamespaces';

export type MeasurementUnitType = 'all' | 'length' | 'massWeight';

const useMeasurementOptions = (
  unitType: MeasurementUnitType = 'all',
  whitelist?: string[],
  namespace = i18nNamespaces.MEASUREMENT,
) => {
  const { t: tMeasurement } = useTranslation(namespace);

  const getMeasurementOptions = useCallback(
    (count: string | number) =>
      [
        { value: 'cm', label: tMeasurement('centimeter', { count: +(count || 0) }) },
        { value: 'm', label: tMeasurement('meter', { count: +(count || 0) }) },
        { value: 'in', label: tMeasurement('inch', { count: +(count || 0) }) },
        { value: 'ft', label: tMeasurement('foot', { count: +(count || 0) }) },
        { value: 'g', label: tMeasurement('gram', { count: +(count || 0) }) },
        { value: 'kg', label: tMeasurement('kilogram', { count: +(count || 0) }) },
        { value: 'oz', label: tMeasurement('ounce', { count: +(count || 0) }) },
        { value: 'lb', label: tMeasurement('pound', { count: +(count || 0) }) },
      ].filter(({ value }) => {
        if (Array.isArray(whitelist) && !whitelist.includes(value)) return false;

        switch (unitType) {
          case 'massWeight':
            return ['g', 'kg', 'oz', 'lb'].includes(value);
          case 'length':
            return ['cm', 'm', 'in', 'ft'].includes(value);
          case 'all':
          default:
            return true;
        }
      }),
    [tMeasurement, unitType, whitelist],
  );

  return { getMeasurementOptions, tMeasurement };
};

export default useMeasurementOptions;
