import { Order } from '../../../models/Order';
import * as AppointmentsActions from './action';

export interface State {
  orders: Order[];
}

const initialState: State = {
  orders: [],
};

export default function reducer(state: State = initialState, action: AppointmentsActions.ActionType): State {
  switch (action.type) {
    case AppointmentsActions.FETCH_ORDERS:
    case AppointmentsActions.FETCH_ORDERS_SUCCESS:
    case AppointmentsActions.FETCH_ORDERS_FAIL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
