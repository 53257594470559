import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';

import InputTextField from 'components/InputTextField/InputTextField';
import RadioField from 'components/RadioField/RadioField';
import { FormComponentProps } from '../../types/FormComponentProps';
import FormDetailsLayout from '../FormDetailsLayout/FormDetailsLayout';
import FormSection from '../FormSection/FormSection';

interface Props<T = any> extends FormComponentProps<T> {
  namePlaceholder?: string;
  datePlaceholder?: string;
}

export const FootAssessmentFormShoeOnly = memo<Props>(
  ({
    form,
    onSubmit,
    loading,
    submitError,
    ctaLabel,
    ctaLoadingLabel,
    secondaryCtaLabel,
    tForm,
    namePlaceholder,
    datePlaceholder,
  }) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      defaultValues: {
        customerId: form?.customerId ?? '',
        name: form?.name ?? '',
        date: format(new Date(), 'yyyy-MM-dd'),
        orderNo: form?.paOrderData?.orderNo,
      } as { [key: string]: string },
    });

    return (
      <FormDetailsLayout
        className="FootAssessmentFormShoeOnly"
        id={form?.id}
        title={tForm('title')}
        ctaLabel={ctaLabel}
        ctaLoadingLabel={ctaLoadingLabel}
        secondaryCtaLabel={secondaryCtaLabel}
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
        submitError={submitError}
      >
        <FormSection>
          <InputTextField {...register('customerId')} label={tForm('customerId')} type="text" status="disabled" />
          <InputTextField {...register('orderNo')} label={tForm('order')} type="text" status="disabled" />
          <RadioField
            {...register('justificationForCO', { required: true })}
            options={[{ label: tForm('justificationForCO'), value: 'yes', required: true }]}
            disabled={loading}
            status={errors?.justificationForCO ? 'error' : 'active'}
            type="checkbox"
          />
          <InputTextField
            {...register('name')}
            label={tForm('patient')}
            placeholder={namePlaceholder}
            type="text"
            status="disabled"
          />
          <RadioField
            {...register('washHands', { required: true })}
            options={[{ label: `${tForm('washedHands')} / ${tForm('newGloves')}`, value: 'yes', required: true }]}
            disabled={loading}
            status={errors?.washedHands ? 'error' : 'active'}
            type="checkbox"
          />
          <InputTextField
            {...register('date')}
            label={tForm('date')}
            placeholder={datePlaceholder}
            type="date"
            status="disabled"
          />
          <InputTextField
            {...register('tech', { required: true })}
            label={tForm('tech')}
            type="text"
            maxLength={50}
            status={errors?.tech ? 'error' : 'active'}
          />
          <InputTextField
            {...register('coCPed', { required: true })}
            label={tForm('coCPed')}
            type="text"
            maxLength={50}
            status={errors?.coCPed ? 'error' : 'active'}
          />
        </FormSection>
        <FormSection>
          <RadioField
            {...register('gait')}
            label={tForm('gait')}
            options={[
              { value: 'pronation', label: tForm('pronation') },
              { value: 'supination', label: tForm('supination') },
              { value: 'normal', label: tForm('normal') },
            ]}
            disabled={loading}
            status={errors?.gait ? 'error' : 'active'}
          />
          <RadioField
            {...register('temperature')}
            label={tForm('temperature')}
            options={[
              { value: 'warm', label: tForm('warm') },
              { value: 'cold', label: tForm('cold') },
              { value: 'normal', label: tForm('normal') },
            ]}
            disabled={loading}
            status={errors?.temperature ? 'error' : 'active'}
          />
          <RadioField
            {...register('footType')}
            label={tForm('footType')}
            options={[
              { value: 'chubby', label: `${tForm('chubby')} / ${tForm('fleshy')}` },
              { value: 'stocky', label: `${tForm('stocky')} / ${tForm('muscular')}` },
              { value: 'lean', label: `${tForm('lean')} / ${tForm('bony')}` },
            ]}
            disabled={loading}
            status={errors?.footType ? 'error' : 'active'}
          />
          <RadioField
            {...register('footFlexibility')}
            options={[
              { value: 'rigid', label: tForm('rigid') },
              { value: 'flexible', label: tForm('flexible') },
            ]}
            disabled={loading}
            status={errors?.footFlexibility ? 'error' : 'active'}
          />
          <RadioField
            {...register('instep')}
            label={tForm('instep')}
            options={[
              { value: 'high', label: tForm('high') },
              { value: 'medium', label: tForm('medium') },
              { value: 'low', label: tForm('low') },
            ]}
            disabled={loading}
            status={errors?.instep ? 'error' : 'active'}
          />
          <RadioField
            {...register('deformities')}
            options={[{ label: tForm('deformities'), value: 'yes' }]}
            disabled={loading}
            status={errors?.deformities ? 'error' : 'active'}
            type="checkbox"
          />
          <RadioField
            {...register('archType')}
            label={tForm('archType')}
            options={[
              { value: 'high', label: `${tForm('high')} (${tForm('pesCavus')})` },
              { value: 'medium', label: tForm('medium') },
              { value: 'low', label: tForm('low') },
              { value: 'flat', label: `${tForm('flat')} (${tForm('pesPlanus')})` },
            ]}
            disabled={loading}
            status={errors?.archType ? 'error' : 'active'}
          />
        </FormSection>
      </FormDetailsLayout>
    );
  },
);

FootAssessmentFormShoeOnly.displayName = 'FootAssessmentFormShoeOnly';

export default FootAssessmentFormShoeOnly;
