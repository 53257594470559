import { put, takeLatest, call, ForkEffect, CallEffect, PutEffect } from 'redux-saga/effects';
import { Action } from 'typesafe-actions';
import { getOrders } from '../../../api';
import { Order, OrdersResponse } from '../../../models/Order';
import * as OrdersActions from './action';

export function* handleGetOrders(
  action: OrdersActions.ActionType,
  successAction: (Orders: Order[]) => OrdersActions.ActionType,
  failAction: (error?: string | undefined) => OrdersActions.ActionType,
): Generator<CallEffect<OrdersResponse> | PutEffect<Action<string>>, void, OrdersResponse> {
  const {
    payload: { successCallback, errorCallback },
  } = action;

  try {
    const { data } = yield call(getOrders);
    if (!data) return;
    yield put(successAction(data));
    if (successCallback) successCallback();
  } catch (error) {
    yield put(failAction(`${error}`));
    if (errorCallback) errorCallback();
  }
}

export function* watchGetOrders(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(OrdersActions.FETCH_ORDERS, (action: OrdersActions.ActionType) =>
    handleGetOrders(action, OrdersActions.fetchOrdersSuccess, OrdersActions.fetchOrdersFail),
  );
}
