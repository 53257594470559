import React, { ChangeEvent } from 'react';
import parseHTML from 'html-react-parser';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import VisitorJumbotron from '../../components/VisitorJumbotron/VisitorJumbotron';
import i18nNamespaces from '../../i18n/i18nNamespaces';
import VisitorLayout from '../../layouts/VisitorLayout/VisitorLayout';
import isValidUSPhoneNumber from '../../utils/isValidUSPhoneNumber';
import CTAButton from '../../components/CTAButton/CTAButton';
import InputTextField from '../../components/InputTextField/InputTextField';
import PhoneInputWrapper from '../../components/PhoneInputWrapper/PhoneInputWrapper';
import formatUSPhoneNumber from '../../utils/formatUSPhoneNumber';
import * as UserDetailsActions from '../../store/UserDetailsDuck/duck/action';

interface FormData {
  username: string;
  phone: string;
}

interface Props {
  setOTPUsername: (username: string) => Promise<UserDetailsActions.ActionType>;
  setOTPPhone: (phone: string) => Promise<UserDetailsActions.ActionType>;
}

export const ForgotPassword: React.FC<Props> = ({ setOTPUsername, setOTPPhone }) => {
  const history = useHistory();
  const { t } = useTranslation(i18nNamespaces.FORGOT_PW);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormData>();

  const validatePhone = (phone: string) => {
    if (!isValidUSPhoneNumber(phone)) return t('phoneInvalid');
    return true;
  };

  const onSubmit = async (data: FormData) => {
    await setOTPUsername(data.username);
    await setOTPPhone(data.phone);
    setTimeout(() => history.push('/forgot-password/verify'), 0);
  };

  return (
    <VisitorLayout hasError={false}>
      {({
        formClassName,
        formContainerClassName,
        formRemarkClassName,
        formButtonsContainerClassName,
        formHintButtonClassName,
      }) => (
        <>
          <VisitorJumbotron title={t('title')} description={parseHTML(t('description'))} />
          <form className={formClassName} onSubmit={handleSubmit(onSubmit)}>
            <div className={formContainerClassName}>
              <InputTextField
                {...register('username', { required: true })}
                label={t('email')}
                placeholder="john.appleseed@nymannings.com"
                type="email"
                status={errors?.username ? 'error' : 'active'}
              />
              <PhoneInputWrapper hasError={!!errors?.phone} hasErrorMessage={!!errors?.phone?.message}>
                {({ className, inputClassName, elementAfter }) => (
                  <InputTextField
                    {...register('phone', { required: true, validate: validatePhone })}
                    label={t('phone')}
                    placeholder="(201) 555-5555"
                    type="text"
                    className={className}
                    inputClassName={inputClassName}
                    status={errors?.phone ? 'error' : 'active'}
                    bottomLabel={errors?.phone?.message}
                    elementAfter={elementAfter}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setValue('phone', formatUSPhoneNumber(e.target.value));
                    }}
                    onBlur={(e) => setValue('phone', formatUSPhoneNumber(e.target.value))}
                  />
                )}
              </PhoneInputWrapper>
            </div>
            <p className={formRemarkClassName}>{parseHTML(t('remark'))}</p>
            <div className={formButtonsContainerClassName}>
              <CTAButton type="submit" text={t('cta')} />
            </div>
          </form>
          <Link to="/" className={formHintButtonClassName}>
            {t('bottomCta')}
          </Link>
        </>
      )}
    </VisitorLayout>
  );
};

ForgotPassword.displayName = 'ForgotPassword';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setOTPUsername: async (username: string) => dispatch(await UserDetailsActions.setOTPUsername(username)),
  setOTPPhone: async (phone: string) => dispatch(await UserDetailsActions.setOTPPhone(phone)),
});

export default connect(null, mapDispatchToProps)(ForgotPassword);
