import React, { memo } from 'react';
import CTAButton from '../../components/CTAButton/CTAButton';
import VisitorJumbotron from '../../components/VisitorJumbotron/VisitorJumbotron';
import VisitorLayout from '../VisitorLayout/VisitorLayout';
import './ActionCompleteLayout.scss';

interface Props {
  title: string;
  description: string;
  ctaLabel: string;
  /** CTA destination route */
  to: string;
}

export const ActionCompleteLayout = memo<Props>(({ title, description, ctaLabel, to }) => {
  return (
    <VisitorLayout className="ActionCompleteLayout" hasError={false}>
      {({ formButtonsContainerClassName }) => (
        <>
          <VisitorJumbotron title={title} description={description} smallerTitle />
          <div className="ActionCompleteLayout-confettiBall">🎊</div>
          <div className={`${formButtonsContainerClassName} ActionCompleteLayout-buttonsContainer`}>
            <CTAButton type="button" text={ctaLabel} to={to} />
          </div>
        </>
      )}
    </VisitorLayout>
  );
});

ActionCompleteLayout.displayName = 'ActionCompleteLayout';

export default ActionCompleteLayout;
