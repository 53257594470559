import { addSeconds, isAfter } from 'date-fns';
import { RootState } from 'MyTypes';
import { REHYDRATE } from 'redux-persist';
import { Task } from 'redux-saga';
import {
  put,
  takeLatest,
  call,
  ForkEffect,
  CallEffect,
  PutEffect,
  fork,
  take,
  cancel,
  delay,
  retry,
  SelectEffect,
} from 'redux-saga/effects';
import { Action } from 'typesafe-actions';
import { getCurrentUserDetails, postRefreshToken } from '../../../api';
import { setToken } from '../../../api/axios';
import { CurrentUserDetails, CurrentUserDetailsResponse } from '../../../models/CurrentUserDetails';
import { LoginResponse } from '../../../models/LoginResponse';
import * as UserDetailsActions from './action';

const REFRESH_TOKEN_BUFFER_SECONDS = 2 * 60;

export function* handleGetCurrentUserDetails(
  action: UserDetailsActions.ActionType,
  successAction: (users: CurrentUserDetails) => UserDetailsActions.ActionType,
  failAction: (error?: string | undefined) => UserDetailsActions.ActionType,
): Generator<CallEffect<CurrentUserDetailsResponse> | PutEffect<Action<string>>, void, CurrentUserDetailsResponse> {
  const {
    payload: { successCallback, errorCallback },
  } = action;

  try {
    const { user } = yield call(getCurrentUserDetails);
    if (!user) return;
    yield put(successAction(user));
    if (successCallback) successCallback();
  } catch (error) {
    yield put(failAction(`${error}`));
    if (errorCallback) errorCallback();
  }
}

export function* watchGetCurrentUserDetails(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(UserDetailsActions.FETCH_CURRENT_USER_DETAILS, (action: UserDetailsActions.ActionType) =>
    handleGetCurrentUserDetails(
      action,
      UserDetailsActions.fetchCurrentUserDetailsSuccess,
      UserDetailsActions.fetchCurrentUserDetailsFail,
    ),
  );
}

export function* handleRefreshToken(
  action: UserDetailsActions.ActionType,
  successAction: (authResponse: LoginResponse) => UserDetailsActions.ActionType,
  failAction: (error?: string | undefined) => UserDetailsActions.ActionType,
): Generator<CallEffect<LoginResponse> | PutEffect<Action<string>> | SelectEffect, void, LoginResponse> {
  const { refreshToken } = (action.payload.authResponse ?? {}) as LoginResponse;
  try {
    const authResponse = yield retry(3, 1000, postRefreshToken, refreshToken?.token ?? '');
    if (!authResponse) throw new Error('error_response');
    setToken(authResponse?.accessToken?.token as string);
    yield put(successAction(authResponse));
  } catch (error) {
    yield put(failAction(`${error}`));
  }
}

export function* forceLogout(): Generator {
  // @TODO: Prompt session timeout alert
  yield put({ type: UserDetailsActions.CLEAR_LOGIN_DETAILS });
}

export function* refreshTokenTicker(
  action: UserDetailsActions.ActionType,
  successAction: (authResponse: LoginResponse) => UserDetailsActions.ActionType,
  failAction: (error?: string | undefined) => UserDetailsActions.ActionType,
): Generator {
  const { accessToken, refreshToken } = action.payload.authResponse ?? {};

  const accessTokenExpDate = new Date((accessToken?.exp as number) * 1000);
  const accessTokenExpDateWithBuffer = addSeconds(accessTokenExpDate, -REFRESH_TOKEN_BUFFER_SECONDS);

  const refreshTokenExpDate = new Date((refreshToken?.exp as number) * 1000);
  if (isAfter(new Date(), refreshTokenExpDate)) yield forceLogout();

  while (true) {
    if (isAfter(new Date(), accessTokenExpDateWithBuffer)) {
      yield fork(handleRefreshToken, action, successAction, failAction);
    }
    yield delay(1000);
  }
}

export function* startRefreshTokenTimer(
  action: UserDetailsActions.ActionType,
  successAction: (authResponse: LoginResponse) => UserDetailsActions.ActionType,
  failAction: (error?: string | undefined) => UserDetailsActions.ActionType,
): Generator {
  const refreshTokenTask = yield fork(refreshTokenTicker, action, successAction, failAction);
  // Wait for stop action
  yield take([
    UserDetailsActions.CLEAR_LOGIN_DETAILS,
    UserDetailsActions.REFRESH_TOKEN_SUCCESS,
    UserDetailsActions.REFRESH_TOKEN_FAIL,
  ]);
  // Cancel action on stop
  yield cancel(refreshTokenTask as Task);
}

export function* watchRefreshToken(): Generator<ForkEffect<never>, void, unknown> {
  // Start refresh token timer after rehydrate
  yield takeLatest(REHYDRATE, function* handleRehydrate(action: { type: string; payload: RootState }) {
    if (action?.payload?.userDetails?.authResponse) {
      yield startRefreshTokenTimer(
        { type: action?.type, payload: action?.payload?.userDetails } as UserDetailsActions.ActionType,
        UserDetailsActions.refreshTokenSuccess,
        UserDetailsActions.refreshTokenFail,
      );
    }
  });
  // Start refresh token timer after each token retrieval success
  yield takeLatest(
    [UserDetailsActions.SET_LOGIN_DETAILS, UserDetailsActions.REFRESH_TOKEN_SUCCESS],
    (action: UserDetailsActions.ActionType) =>
      startRefreshTokenTimer(action, UserDetailsActions.refreshTokenSuccess, UserDetailsActions.refreshTokenFail),
  );
  // Clear token on refresh fail action
  yield takeLatest(UserDetailsActions.REFRESH_TOKEN_FAIL, forceLogout);
}
