import React, { memo } from 'react';
import './ListFilterTabs.scss';

export interface ListFilterTabItem {
  key: string;
  label: string;
}

interface Props {
  items: ListFilterTabItem[];
  activeItemKey: string;
  onItemClick: (itemKey: string) => unknown;
}

export const ListFilterTabs = memo<Props>(({ items, activeItemKey, onItemClick }) => {
  return (
    <div className="ListFilterTabs">
      {items.map(({ key, label }) => (
        <button
          type="button"
          className={`ListFilterTabs-item ${activeItemKey === key ? 'mod-active' : ''}`}
          key={key}
          onClick={() => onItemClick(key)}
        >
          {label}
        </button>
      ))}
    </div>
  );
});

ListFilterTabs.displayName = 'ListFilterTabs';

export default ListFilterTabs;
