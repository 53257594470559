import React, { memo, useState, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import i18next from 'i18next';
import { Dispatch } from 'redux';
import { RootState } from 'MyTypes';

import './FormDetails.scss';
import getAPIErrorI18nKey from 'utils/getAPIErrorI18nKey';
import i18nNamespaces from '../../i18n/i18nNamespaces';
import { postSubmitForm } from '../../api';
import { SubmitFormDetails } from '../../models/SubmitFormDetails';
import { selectAllForms } from '../../store/FormsDuck/duck/selector';
import * as FormsActions from '../../store/FormsDuck/duck/action';
import { FormDetails as FormDetailsType } from '../../models/FormDetails';
import formComponents from './components/formComponents';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import FormPdfPreview from './components/FormPdfPreview/FormPdfPreview';
import { selectCurrentUserDetails } from '../../store/UserDetailsDuck/duck/selector';
import { CurrentUserDetails } from '../../models/CurrentUserDetails';
import getFormI18nNamespace from './utils/getFormI18nNamespace';

interface Props extends RouteComponentProps<{ id: string }> {
  currentUserDetails: CurrentUserDetails | null;
  selectForm: (id: string) => FormDetailsType | null;
  fetchForms: (successCallback?: () => void, errorCallback?: () => void) => Promise<FormsActions.ActionType>;
  fetchFormContent: (
    i18n: typeof i18next,
    formComponent: string,
    version: string,
    successCallback?: () => void,
    errorCallback?: () => void,
  ) => Promise<FormsActions.ActionType>;
}

export const FormDetails = memo<Props>(({ match, fetchForms, fetchFormContent, selectForm }) => {
  const {
    params: { id },
  } = match;
  const { t, i18n } = useTranslation(i18nNamespaces.FORM);
  const history = useHistory();

  const form = useMemo(() => selectForm(id), [id, selectForm]);
  const version = form?.version ?? 'v1';

  const formI18nNamespace = getFormI18nNamespace(form);
  const { t: tForm } = useTranslation(formI18nNamespace);

  const FormComponent = formComponents[form?.formComponent ?? ''];
  const isUnsupported = !FormComponent || form?.editable === false;
  const shouldFetchContent = !isUnsupported || form?.signed;

  /** ------------------------------- Forms fetching ------------------------------- */
  const [isRefreshingForms, setIsRefreshingForms] = useState(false);
  const refreshForms = useCallback(async () => {
    setIsRefreshingForms(true);
    const finallyCallback = () => setIsRefreshingForms(false);
    // @TODO: Only fetch "this form"
    // @TODO: Handle error
    fetchForms(finallyCallback, finallyCallback);
  }, [fetchForms]);

  // Fetch forms and content on mount
  useEffect(() => {
    if (!form) refreshForms();
  }, [form, refreshForms]);

  /** ------------------------------- Fetch content ------------------------------- */
  const [isRefreshingContent, setIsRefreshingContent] = useState(true);

  const refreshContent = useCallback(async () => {
    if (!form?.formComponent) return;
    setIsRefreshingContent(true);
    const finallyCallback = () => setIsRefreshingContent(false);
    // @TODO: Handle error
    fetchFormContent(i18n, form?.formComponent, version, finallyCallback, finallyCallback);
  }, [fetchFormContent, form?.formComponent, i18n, version]);

  // Fetch and set users on mount
  useEffect(() => {
    if (shouldFetchContent) refreshContent();
    else setIsRefreshingContent(false);
  }, [shouldFetchContent, refreshContent]);

  /** ------------------------------- Submit form ------------------------------- */
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    async (data: SubmitFormDetails['data']) => {
      setLoading(true);
      try {
        const res = await postSubmitForm(id, { data });

        switch (res.status) {
          case 'ok': {
            setTimeout(() => history.push('/forms-and-documents'), 0);
            break;
          }
          case 'error':
            setSubmitError(res.error ? t(`error_${res.error}`) : null);
            break;
          default:
            break;
        }
      } catch (err) {
        // @TODO: Log error message to server
        setSubmitError(t(getAPIErrorI18nKey(err, i18nNamespaces.FORM)));
      } finally {
        setLoading(false);
      }
    },
    [history, id, t],
  );

  const renderBody = () => {
    if (form?.signed) {
      return <FormPdfPreview form={form} isRefreshingContent={isRefreshingContent} />;
    }
    if (isRefreshingContent || isRefreshingForms) {
      return (
        <div className="FormDetails-loading">
          <ClipLoader color="#06bbc9" />
        </div>
      );
    }
    if (!form || !FormComponent || isUnsupported) {
      return <div className="FormDetails-unsupported">{t('formUnsupported')}</div>;
    }
    return (
      <FormComponent
        form={form}
        onSubmit={onSubmit}
        loading={loading}
        submitError={submitError}
        tForm={tForm}
        ctaLabel={t('cta')}
        ctaLoadingLabel={t('ctaLoading')}
        secondaryCtaLabel={t('ctaSecondary')}
      />
    );
  };

  return (
    <MainLayout
      showBackButton
      backRoutePathname="/forms-and-documents"
      backButtonDisabled={loading}
      menuDisabled={loading}
      className="FormDetails-main-layout"
      contentContainerClassName={form?.signed ? 'FormDetails-preview' : ''}
      contentMaxWidth={800}
    >
      {renderBody()}
    </MainLayout>
  );
});

FormDetails.displayName = 'FormDetails';

const mapStateToProps = (state: RootState): Pick<Props, 'currentUserDetails' | 'selectForm'> => ({
  currentUserDetails: selectCurrentUserDetails(state),
  selectForm: (id: string) => {
    const forms = selectAllForms(state);
    return forms.find((c) => c.id === id) ?? null;
  },
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<Props, 'fetchForms' | 'fetchFormContent'> => ({
  fetchForms: async (successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(await FormsActions.fetchForms(successCallback, errorCallback)),
  fetchFormContent: async (
    i18n: typeof i18next,
    formComponent: string,
    version: string,
    successCallback?: () => void,
    errorCallback?: () => void,
  ) => dispatch(await FormsActions.fetchFormContent(i18n, formComponent, version, successCallback, errorCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormDetails);
