import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Squash as Hamburger } from 'hamburger-react';
import NavigationLink from './components/NavigationLink';
import { Device } from '../../hooks/useWindowDimensions';
import i18nNamespaces from '../../i18n/i18nNamespaces';
import { NavigationRoutes } from '../../models/NavigationRoutes';
import HomeIcon from '../../assets/icons/home.svg';
import AppoinmentsIcon from '../../assets/icons/calendar.svg';
import MyInformationIcon from '../../assets/icons/user.svg';
import FormsAndDocumentsIcon from '../../assets/icons/file-text.svg';
import OrdersIcon from '../../assets/icons/truck.svg';
import ViewAllProductsIcon from '../../assets/icons/package.svg';
import LogoutIcon from '../../assets/icons/log-out.svg';
import './MainNavigation.scss';
import ApplicationVersion from '../ApplicationVersion/ApplicationVersion';

interface Props {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  device: Device;
}

const MainNavigation = memo<Props>(({ device, isOpen, setOpen }) => {
  const isDesktop = device === 'desktop';
  const { t } = useTranslation(i18nNamespaces.APP);
  const history = useHistory();

  const [showContents, setShowContents] = useState(isDesktop);

  const currentPath = history.location.pathname;

  const routes: NavigationRoutes[] = [
    {
      icon: HomeIcon,
      path: '/home',
      label: t('menu_home'),
    },
    {
      path: '/appointments',
      label: t('menu_appointments'),
      icon: AppoinmentsIcon,
    },
    {
      path: '/my-information',
      label: t('menu_myInformation'),
      icon: MyInformationIcon,
    },
    {
      path: '/forms-and-documents',
      label: t('menu_formsAndDocuments'),
      icon: FormsAndDocumentsIcon,
    },
    {
      path: '/orders',
      label: t('menu_orders'),
      icon: OrdersIcon,
    },
    {
      path: '/products/all',
      label: t('menu_viewAllProducts'),
      icon: ViewAllProductsIcon,
    },
    {
      path: '/',
      label: t('menu_logout'),
      icon: LogoutIcon,
    },
  ];

  const handleBurgerMenuClick = useCallback(() => {
    setOpen(!isOpen);

    if (!isOpen) {
      setTimeout(() => {
        setShowContents(true);
      }, 300);
    } else {
      setShowContents(false);
    }
  }, [isOpen, setOpen]);

  const prevIsDesktop = useRef<boolean | null>(null);
  const setOpenRef = useRef(setOpen);
  useEffect(() => {
    // Make sure only execute when "isDesktop" does change (sometimes same boolean value still triggered this effect)
    if (
      prevIsDesktop.current === null ||
      (typeof prevIsDesktop.current === 'boolean' && prevIsDesktop.current !== isDesktop)
    ) {
      setOpenRef.current(isDesktop);
      setShowContents(isDesktop);
      prevIsDesktop.current = isDesktop;
    }
  }, [isDesktop]);

  // Make sure it is closed when the component unmounts
  useEffect(
    () => () => {
      if (isDesktop) return;
      setOpenRef.current(false);
    },
    [isDesktop],
  );

  return (
    <>
      <div className={`MainNavigation mod-${isOpen ? 'open' : 'close'}`}>
        <div className="MainNavigation-burger-menu">
          {device !== 'desktop' && (
            <Hamburger color="#11a3b5" toggled={isOpen} size={25} onToggle={handleBurgerMenuClick} />
          )}
        </div>
        <div className="MainNavigation-container">
          {showContents
            ? routes.map((route) => (
                <NavigationLink
                  key={route.path}
                  active={currentPath.startsWith(route.path) && route.path !== '/'}
                  {...route}
                />
              ))
            : null}
        </div>
        <ApplicationVersion opacity="0.3" />
      </div>
    </>
  );
});

MainNavigation.displayName = 'MainNavigation';

export default MainNavigation;
