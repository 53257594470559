import { Order } from 'models/Order';
import { createContext, ReactNode } from 'react';
import { Appointment } from '../../../models/Appointment';
import { FormDetails } from '../../../models/FormDetails';
import { IconCardButtonProps } from '../components/IconCardButton/IconCardButton';
import { NotificationPreviewDataItem } from '../components/NotificationsPreview/NotificationsPreview';

interface HomeContextProps {
  buttonConfigs: IconCardButtonProps[][];
  headerNode: ReactNode;
  customerId: string;
  notifications: NotificationPreviewDataItem[];
  nextAppointment: Appointment | null;
  latestUnsignedForm: FormDetails | null;
  lastOrderedItem: Order | null;
}

const HomeContext = createContext<HomeContextProps>({} as HomeContextProps);

export default HomeContext;
