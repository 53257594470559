import React, { memo, useContext } from 'react';
import { CardsRow } from '../../../../components/Card/Card';
import HeaderCard from '../../../../components/HeaderCard/HeaderCard';
import IconCardButton from '../../components/IconCardButton/IconCardButton';
import NotificationsPreview from '../../components/NotificationsPreview/NotificationsPreview';
import HomeContext from '../../context';
import './Mobile.scss';

export const Mobile = memo(() => {
  const { buttonConfigs, headerNode, customerId, notifications } = useContext(HomeContext);

  return (
    <>
      <HeaderCard title={headerNode} description={customerId} largeDescription>
        <NotificationsPreview data={notifications} />
      </HeaderCard>
      {buttonConfigs.map((buttonRowConfig, rowIndex) => (
        <CardsRow key={`row-${rowIndex + 1}`}>
          {buttonRowConfig.map(
            ({ to, icon, iconSize, backgroundImage, title, badgeNumber, titleAlign, disabled }, i) => (
              <IconCardButton
                key={`${i + 1}`}
                to={to}
                icon={icon}
                iconSize={iconSize}
                title={title}
                badgeNumber={badgeNumber}
                backgroundImage={backgroundImage}
                titleAlign={titleAlign}
                disabled={disabled}
              />
            ),
          )}
        </CardsRow>
      ))}
    </>
  );
});

Mobile.displayName = 'Mobile';

export default Mobile;
