import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import parseBonafideDate from 'utils/parseBonafideDate';
import Card from '../../../../components/Card/Card';
import IconCardButton from '../../components/IconCardButton/IconCardButton';
import NotificationsPreview from '../../components/NotificationsPreview/NotificationsPreview';
import HomeContext from '../../context';
import calendarIcon from '../../../../assets/icons/calendar.svg';
import fileTextIcon from '../../../../assets/icons/file-text.svg';
import truckIcon from '../../../../assets/icons/truck.svg';
import phoneIcon from '../../../../assets/icons/phone.svg';
import i18nNamespaces from '../../../../i18n/i18nNamespaces';
import './Desktop.scss';

export const Desktop = memo(() => {
  const { t } = useTranslation(i18nNamespaces.HOME);
  const {
    buttonConfigs = [],
    headerNode,
    customerId,
    notifications,
    nextAppointment,
    latestUnsignedForm,
    lastOrderedItem,
  } = useContext(HomeContext);

  const viewAllProductsIconCardButton = (buttonConfigs[buttonConfigs.length - 1] || [])[0];

  return (
    <div className="Home mod-desktop">
      <div className="Home-header-container">
        {headerNode}
        <p>{customerId}</p>
      </div>
      <div className="Home-cards-row">
        <div className="Home-cards-row-column">
          <Card className="Home-notifications-container">
            <NotificationsPreview maxDisplayedRow={5} data={notifications} />
          </Card>
        </div>
        <div className="Home-cards-row-column">
          <IconCardButton
            to={viewAllProductsIconCardButton?.to}
            title={viewAllProductsIconCardButton?.title}
            backgroundImage={viewAllProductsIconCardButton?.backgroundImage}
            titleAlign="left"
            disabled
          />
        </div>
      </div>
      <div className="Home-cards-row">
        <div className="Home-cards-row-column">
          <div className="Home-cards-row-column">
            <Card>
              <IconCardButton
                title={t('nextAppointment')}
                to={`/appointments/${nextAppointment?.id}`}
                icon={calendarIcon}
                iconSize={55}
                disabled={nextAppointment === null}
              >
                {nextAppointment?.start && nextAppointment?.store?.address ? (
                  <>
                    <p>{nextAppointment?.start}</p>
                    <p>{nextAppointment?.store?.address}</p>
                  </>
                ) : (
                  <p>-</p>
                )}
              </IconCardButton>
            </Card>
          </div>
          <div className="Home-cards-row-column">
            <Card>
              <IconCardButton
                title={t('latestUnsignedForm')}
                to={`/form/${latestUnsignedForm?.id}`}
                icon={fileTextIcon}
                iconSize={55}
                disabled={latestUnsignedForm === null}
              >
                {latestUnsignedForm?.createdAt && latestUnsignedForm?.formTitle ? (
                  <>
                    <p>{format(new Date(latestUnsignedForm?.createdAt), 'dd/MM/yyyy hh:mm a')}</p>
                    <p>{latestUnsignedForm?.formTitle}</p>
                  </>
                ) : (
                  <p>-</p>
                )}
              </IconCardButton>
            </Card>
          </div>
        </div>
        <div className="Home-cards-row-column">
          <div className="Home-cards-row-column">
            <IconCardButton
              title={t('lastOrder')}
              to={`/orders/${lastOrderedItem?.id}`}
              icon={truckIcon}
              iconSize={55}
              disabled={lastOrderedItem === null || parseBonafideDate(lastOrderedItem.orderdate) === null}
            >
              {lastOrderedItem && parseBonafideDate(lastOrderedItem.orderdate) ? (
                <>
                  <p>
                    {lastOrderedItem && parseBonafideDate(lastOrderedItem.orderdate)
                      ? format(parseBonafideDate(lastOrderedItem?.orderdate) || Infinity, 'dd/MM/yyyy')
                      : ''}
                  </p>
                  <p>{lastOrderedItem?.itemInfo?.description || ''}</p>
                </>
              ) : (
                <p>-</p>
              )}
            </IconCardButton>
          </div>
          <div className="Home-cards-row-column">
            <Card>
              <IconCardButton title={t('contactUs')} href="tel:+1-212-966-4420" icon={phoneIcon} iconSize={50}>
                <>
                  <p>10:00 AM - 06:00 PM </p>
                  <p>+1 (212) 966 – 4420</p>
                </>
              </IconCardButton>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
});

Desktop.displayName = 'Desktop';

export default Desktop;
