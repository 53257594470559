import React, { useCallback, useEffect, useRef } from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';

interface ReturnType {
  handleRepresentativeNameChange: React.ChangeEventHandler<HTMLInputElement>;
  isSelfRepresentative: boolean;
}

const useFormRepresentativeField = (
  watch: UseFormWatch<any>,
  setValue: UseFormSetValue<any>,
  relationshipFieldName = 'relationship',
  nameFieldName = 'name',
  representativeFieldName = 'representativeName',
): ReturnType => {
  const relationshipValue = watch(relationshipFieldName);
  const nameValue = watch(nameFieldName);
  const representativeNameValueRef = useRef('');
  const isSelfRepresentative = relationshipValue === 'self';

  // Pre-fill representative field when user select “Self”
  useEffect(() => {
    setValue(representativeFieldName, isSelfRepresentative ? nameValue : representativeNameValueRef.current);
  }, [isSelfRepresentative, nameValue, representativeFieldName, setValue]);

  const handleRepresentativeNameChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setValue(representativeFieldName, e.target.value);
      representativeNameValueRef.current = e.target.value;
    },
    [representativeFieldName, setValue],
  );

  return { handleRepresentativeNameChange, isSelfRepresentative };
};

export default useFormRepresentativeField;
