/**
 * @param value US phone value including area code
 * @param countryCodeIncluded Whether the passed value includes country/region code
 */
const isValidUSPhoneNumber = (value: string, countryCodeIncluded = false): boolean => {
  const pureNumValue = value.replace(/\D/g, '');
  const expectedLength = countryCodeIncluded ? 11 : 10;
  return pureNumValue.length === expectedLength;
};

export default isValidUSPhoneNumber;
