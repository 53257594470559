const convertHtmlToPlainText = (html: string): string => {
  return String.raw`${html
    .replace(/\n/g, ' ')
    .trim()
    .replaceAll('<p>', '')
    .replaceAll('</p> ', '\\n')
    .replaceAll('</p>', '\\n')}`;
};

export default convertHtmlToPlainText;
