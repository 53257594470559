import { UseFormSetValue } from 'react-hook-form';

const getMultipleChoiceClickHandler =
  (fieldKey: string, value: string, setValue: UseFormSetValue<any>) => (e: React.MouseEvent<HTMLInputElement>) => {
    const { value: optValue } = e.target as unknown as { value: string };
    if (typeof value === 'string' && value?.split(',').includes(optValue)) {
      setValue(
        fieldKey,
        value
          .split(',')
          .filter((s) => !!s && s !== optValue)
          .join(','),
      );
    } else {
      setValue(fieldKey, [value, optValue].filter((s) => !!s).join(','));
    }
  };
export default getMultipleChoiceClickHandler;
