import React, { Fragment, memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { NavigationRoutes } from '../../../models/NavigationRoutes';

interface Props extends NavigationRoutes {
  active: boolean;
  onClick?: (path: string) => void;
}

const NavigationLink = memo<Props>(({ path, icon, label, active, onClick }) => (
  <>
    <Link
      key={path}
      className={`MainNavigation-link ${active ? 'mod-active' : ''}`}
      to={path}
      onClick={useCallback(() => onClick?.(path), [onClick, path])}
    >
      <div
        style={{
          mask: `url(${icon}) no-repeat center`,
          WebkitMask: `url(${icon}) no-repeat center`,
        }}
        className="MainNavigation-link-icon"
      />
      <span>{label}</span>
    </Link>
  </>
));

NavigationLink.displayName = 'NavigationLink';

export default NavigationLink;
