import { RootState } from 'MyTypes';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { useHistory } from 'react-router-dom';
import HeaderCard from '../../components/HeaderCard/HeaderCard';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import useContent from './hooks/useContent';
import InformationCards from '../../components/InformationCard/InformationCards';
import { selectOrder } from '../../store/OrdersDuck/duck/selector';
import { Order } from '../../models/Order';
import { getOrderDetails } from '../../api';

interface Props {
  id: string;
  orderDetails?: Order;
}

export const OrderDetails: React.FC<Props> = ({ id, orderDetails: initialOrderDetailsState }: Props) => {
  const [orderDetails, setOrderDetails] = useState(initialOrderDetailsState);

  const history = useHistory();

  useEffect(() => {
    if (orderDetails) return;
    getOrderDetails(id).then((res) => {
      if (res.status === 'ok') {
        setOrderDetails(res.data);
      } else {
        history.push('/orders');
      }
    });
  }, [history, id, orderDetails]);

  if (!orderDetails) {
    return (
      <div className="OrderDetails-loading">
        <ClipLoader color="#06bbc9" />
      </div>
    );
  }

  const { cardsConfig, description, title } = useContent(orderDetails);

  return (
    <MainLayout className="OrderDetails" showBackButton backRoutePathname="/" contentMaxWidth={1000}>
      <HeaderCard title={title} description={description} />
      <InformationCards cards={cardsConfig} />
    </MainLayout>
  );
};

OrderDetails.displayName = 'OrderDetails';

const mapStateToProps = (state: RootState, props: any): Pick<Props, 'orderDetails' | 'id'> => {
  const { id } = props.match.params;

  return {
    id,
    orderDetails: selectOrder(state, id),
  };
};

export default connect(mapStateToProps, null)(OrderDetails);
