import { put, takeLatest, call, ForkEffect, CallEffect, PutEffect } from 'redux-saga/effects';
import { Action } from 'typesafe-actions';
import { getNotifications } from '../../../api';
import { NotificationDetails, NotificationsResponse } from '../../../models/Notifications';
import * as NotificationsActions from './action';

export function* handleGetNotifications(
  action: NotificationsActions.ActionType,
  successAction: (notifications: NotificationDetails[]) => NotificationsActions.ActionType,
  failAction: (error?: string | undefined) => NotificationsActions.ActionType,
): Generator<CallEffect<NotificationsResponse> | PutEffect<Action<string>>, void, NotificationsResponse> {
  const {
    payload: { successCallback, errorCallback },
  } = action;

  try {
    const { data } = yield call(getNotifications);
    if (!data) return;
    yield put(successAction(data));
    if (successCallback) successCallback();
  } catch (error) {
    yield put(failAction(`${error}`));
    if (errorCallback) errorCallback();
  }
}

export function* watchGetNotifications(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(NotificationsActions.FETCH_NOTIFICATIONS, (action: NotificationsActions.ActionType) =>
    handleGetNotifications(
      action,
      NotificationsActions.fetchNotificationsSuccess,
      NotificationsActions.fetchNotificationsFail,
    ),
  );
}
