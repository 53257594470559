import { put, takeLatest, call, ForkEffect, CallEffect, PutEffect } from 'redux-saga/effects';
import { Action } from 'typesafe-actions';
import { getAllForms, getFormContent, getFormsCatalogContent } from '../../../api';
import languages from '../../../i18n/languages';
import { FormDetails } from '../../../models/FormDetails';
import { GetAllFormsResponse } from '../../../models/GetAllFormsResponse';
import {
  GetFormCatalogContentsResponse,
  GetFormCatalogErrorResponse,
} from '../../../models/GetFormCatalogContentsResponse';
import {
  GetFormContentDictionary,
  GetFormContentErrorResponse,
  GetFormContentResponse,
} from '../../../models/GetFormContentResponse';
import * as FormsActions from './action';

export function* handleGetFormContent(
  action: FormsActions.ActionType,
): Generator<
  CallEffect<GetFormContentResponse | GetFormContentErrorResponse> | PutEffect<Action<string>>,
  void,
  GetFormContentResponse
> {
  const {
    payload: { i18n, formComponent, version, successCallback, errorCallback },
  } = action;

  try {
    const { content } = yield call(getFormContent, formComponent as string, version as string);
    if (!content || !i18n || !formComponent || !version) return;

    const formI18nNamespace = `${formComponent}-${version}`;
    Object.values(languages).forEach((lng) => {
      i18n.addResources(lng, formI18nNamespace, content[lng as keyof GetFormContentDictionary] ?? {});
    });
    if (successCallback) successCallback();
  } catch (error) {
    if (errorCallback) errorCallback();
  }
}

export function* handleGetFormsCatalogContent(
  action: FormsActions.ActionType,
): Generator<
  CallEffect<GetFormCatalogContentsResponse | GetFormCatalogErrorResponse> | PutEffect<Action<string>>,
  void,
  GetFormCatalogContentsResponse
> {
  const {
    payload: { i18n, successCallback, errorCallback },
  } = action;

  try {
    const { content } = yield call(getFormsCatalogContent);
    if (!content || !i18n) return;

    Object.values(languages).forEach((lng) => {
      const lngContent = content[lng as keyof GetFormContentDictionary];
      if (lngContent)
        Object.entries(lngContent).forEach(([formComponent, versionsContent]) => {
          // eslint-disable-next-line array-callback-return
          Object.entries(versionsContent).map(([version, formContent]) => {
            i18n.addResources(lng, `${formComponent}-${version}`, formContent);
          });
        });
    });
    if (successCallback) successCallback();
  } catch (error) {
    if (errorCallback) errorCallback();
  }
}

export function* handleGetForms(
  action: FormsActions.ActionType,
  successAction: (users: FormDetails[]) => FormsActions.ActionType,
  failAction: (error?: string | undefined) => FormsActions.ActionType,
): Generator<CallEffect<GetAllFormsResponse> | PutEffect<Action<string>>, void, GetAllFormsResponse> {
  const {
    payload: { successCallback, errorCallback },
  } = action;

  try {
    const { data } = yield call(getAllForms);
    if (!data) return;
    yield put(successAction(data));
    if (successCallback) successCallback();
  } catch (error) {
    yield put(failAction(`${error}`));
    if (errorCallback) errorCallback();
  }
}

export function* watchGetForms(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(FormsActions.FETCH_FORMS, (action: FormsActions.ActionType) =>
    handleGetForms(action, FormsActions.fetchFormsSuccess, FormsActions.fetchFormsFail),
  );
  yield takeLatest(FormsActions.FETCH_FORMS_CATALOG_CONTENT, (action: FormsActions.ActionType) =>
    handleGetFormsCatalogContent(action),
  );
  yield takeLatest(FormsActions.FETCH_FORM_CONTENT, (action: FormsActions.ActionType) => handleGetFormContent(action));
}
