import i18next from 'i18next';
import { action } from 'typesafe-actions';
import { Appointment } from '../../../models/Appointment';

export interface ActionType {
  type: 'forms/FETCH_APPOINTMENTS' | 'forms/FETCH_APPOINTMENTS_SUCCESS' | 'forms/FETCH_APPOINTMENTS_FAIL';

  payload: {
    i18n?: typeof i18next;
    appointments?: Appointment[];
    error?: string;
    successCallback?: () => void;
    errorCallback?: () => void;
  };
}

export const FETCH_APPOINTMENTS = 'forms/FETCH_APPOINTMENTS';
export const FETCH_APPOINTMENTS_SUCCESS = 'forms/FETCH_APPOINTMENTS_SUCCESS';
export const FETCH_APPOINTMENTS_FAIL = 'forms/FETCH_APPOINTMENTS_FAIL';

/* ------------- Fetch Appointments  ------------- */
export const fetchAppointments = (successCallback?: () => void, errorCallback?: () => void): ActionType =>
  action(FETCH_APPOINTMENTS, { successCallback, errorCallback });

export const fetchAppointmentsSuccess = (appointments: Appointment[]): ActionType =>
  action(FETCH_APPOINTMENTS_SUCCESS, { appointments });
export const fetchAppointmentsFail = (error?: string): ActionType => action(FETCH_APPOINTMENTS_FAIL, { error });
