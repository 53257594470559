import React, { memo, useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { format, parse, isMatch } from 'date-fns';
import parseHTML from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import { InputTextField } from 'components/InputTextField/InputTextField';
import SignaturePadField from 'components/SignaturePadField/SignaturePadField';
import RadioField, { RadioOption, RadioProps } from 'components/RadioField/RadioField';
import PhoneInputWrapper from 'components/PhoneInputWrapper/PhoneInputWrapper';
import { DatePicker } from 'components/DatePicker/DatePicker';
import MeasurementInputGroup from 'components/MeasurementInputGroup/MeasurementInputGroup';
import i18nNamespaces from 'i18n/i18nNamespaces';
import getFormRelationshipOptions from '../../../utils/getFormRelationshipOptions';
import useMeasurementOptions from '../../../hooks/useMeasurementOptions';
import { FormComponentProps } from '../../../types/FormComponentProps';
import FormDetailsLayout from '../../FormDetailsLayout/FormDetailsLayout';
import './FormHomeSafetyEnvironmentAssessment.scss';
import FormSection from '../../FormSection/FormSection';
import getFormYesNoOptions from '../../../utils/getFormYesNoOptions';
import getMultipleChoiceClickHandler from '../../../utils/getMultipleChoiceClickHandler';

type VariantType = 'delivery' | 'dropship' | 'ptnPickup';

interface Props<T = any> extends FormComponentProps<T> {
  variantType: VariantType;
  namePlaceholder?: string;
  datePlaceholder?: string;
}

export const FormHomeSafetyEnvironmentAssessment = memo<Props>(
  ({
    form,
    onSubmit,
    loading,
    submitError,
    ctaLabel,
    ctaLoadingLabel,
    secondaryCtaLabel,
    tForm,
    namePlaceholder,
    datePlaceholder,
    variantType,
  }) => {
    const { t: tLocalForm } = useTranslation(i18nNamespaces.FORM);
    const { getMeasurementOptions, tMeasurement } = useMeasurementOptions('length');

    const hasSignature = (['dropship', 'ptnPickup'] as VariantType[]).includes(variantType);
    const hasRelationship = (['dropship', 'ptnPickup'] as VariantType[]).includes(variantType);
    const hasDeliveryTech = variantType === 'delivery';
    const hasAdditionalCommentNote = variantType === 'ptnPickup';

    const yesNoOptions = useMemo(() => getFormYesNoOptions(tForm), [tForm]);
    const relationshipRadioOptions = useMemo(() => getFormRelationshipOptions(tForm), [tForm]);

    const {
      register,
      handleSubmit,
      formState: { errors },
      getValues,
      setValue,
      watch,
    } = useForm({
      defaultValues: {
        customerId: form?.customerId ?? '',
        name: form?.name ?? '',
        date: format(new Date(), 'yyyy-MM-dd'),
        phone: form?.paOrderData?.phone,
        itemOrdered: form?.paOrderData?.itemOrdered,
        orderNo: form?.paOrderData?.orderNo,
        orderDate: isMatch(form?.paOrderData?.orderDate, 'yyyyMMdd')
          ? format(parse(form?.paOrderData?.orderDate, 'yyyyMMdd', new Date()), 'yyyy-MM-dd')
          : '',
        instructionsAndFormsEvaluation: {
          onThisDateText: format(new Date(), 'yyyy-MM-dd'),
        },
      } as unknown as { [key: string]: string },
    });
    const { signature: signatureValue } = getValues();

    const onSubmitWithDataMapping = useCallback(
      (data) => {
        const { relationship, homeEnvironment, instructionsAndFormsEvaluation, ...rest } = data;
        const mappedData = {
          ...rest,
          ...(relationship === 'self' ? {} : { relationship }),
          homeEnvironment: (() => {
            const {
              entranceDoorWidth,
              entranceDoorWidthUnit,
              wheelchairAccessibleRooms = '',
              ...restData
            } = homeEnvironment;
            return {
              ...restData,
              entranceDoorWidth: `${entranceDoorWidth || ''} ${entranceDoorWidthUnit}`,
              wheelchairAccessibleRooms: wheelchairAccessibleRooms?.split(',') ?? '',
            };
          })(),
          instructionsAndFormsEvaluation: (() => {
            const { patientInstructedToCallOrReturnToOfficeForFollowUp, onThisDateText, ...restData } =
              instructionsAndFormsEvaluation;
            return {
              ...restData,
              patientInstructedToCallOrReturnToOfficeForFollowUp:
                patientInstructedToCallOrReturnToOfficeForFollowUp === 'onThisDate'
                  ? [patientInstructedToCallOrReturnToOfficeForFollowUp, onThisDateText]
                  : patientInstructedToCallOrReturnToOfficeForFollowUp,
            };
          })(),
        };
        return onSubmit(mappedData);
      },
      [onSubmit],
    );

    const renderMeasurementInput = (
      name: string,
      unitName: string,
      exampleNum?: string | number,
      label?: string,
      required = true,
    ) => {
      const numberValue = watch(name);
      const unitValue = watch(unitName);
      return (
        <MeasurementInputGroup
          name={name}
          unitName={unitName}
          numberValue={numberValue}
          unitValue={unitValue}
          setValue={setValue}
          register={register}
          getMeasurementOptions={getMeasurementOptions}
          label={label ?? tForm(name)}
          placeholder={exampleNum !== undefined ? `${tLocalForm('eg')} ${exampleNum}` : ''}
          unitPlaceholder={`${tLocalForm('eg')} ${tMeasurement('centimeter_plural')}`}
          errors={errors}
          required={required}
          autoSelectedUnit="cm"
        />
      );
    };

    const renderRadioInSection = (
      ns: string,
      question: string | [string, RadioOption[]] | [string, RadioOption[], Partial<RadioProps>],
      required = true,
      className = '',
    ) => {
      const [qKey, qOptions = yesNoOptions, radioProps = {}] = Array.isArray(question) ? question : [question];
      const fieldKey = `${ns}.${qKey}`;
      const { multipleChoice } = radioProps;
      const value = watch(fieldKey) ?? '';

      return (
        <RadioField
          {...register(fieldKey, { required })}
          key={fieldKey}
          label={tForm(qKey)}
          options={qOptions}
          disabled={loading}
          status={(errors ?? {})[fieldKey] ? 'error' : 'active'}
          className={`FormHomeSafetyEnvironmentAssessment-radio-in-section ${className}`}
          inline
          {...(multipleChoice
            ? {
                onClick: getMultipleChoiceClickHandler(fieldKey, value, setValue),
                value,
              }
            : {})}
          {...radioProps}
        />
      );
    };

    const renderFallRiskAssessment = () => {
      const ns = 'fallRiskAssessment';
      const questions = [
        'age65AndOlder',
        'hasFallenInPastYear',
        'anyPotentialHomeHazards',
        'inappropriateFootwear',
        'confusionDisorientationImpulsivity',
        'visualImpairment',
      ];
      const bulletPoints = tForm('fallRiskAssessmentBulletPoints', { returnObjects: true });
      const bulletPointsText = `<ul>${
        Array.isArray(bulletPoints) ? (bulletPoints as unknown as string[])?.map((pt) => `<li>${pt}</li>`).join('') : ''
      }</ul>`;

      return (
        <FormSection
          title={tForm(ns)}
          inset={['left']}
          description={
            <>
              <p>{tForm('fallRiskAssessmentStatement')}</p>
              {parseHTML(bulletPointsText)}
            </>
          }
        >
          {questions.map((q) => renderRadioInSection(ns, q))}
        </FormSection>
      );
    };

    const renderInstructionsAndFormsEvaluationSection = () => {
      const ns = 'instructionsAndFormsEvaluation';

      const questionsSetA = [
        [
          'instructionsOfUseAndFormsGivenTo',
          [
            { label: tForm('patient'), value: 'patient' },
            { label: tForm('caregiver'), value: 'caregiver' },
          ],
        ] as [string, RadioOption[]],
        [
          'patientEvaluated',
          [
            { label: tForm('yes'), value: 'yes' },
            { label: tForm('notAvailable'), value: 'notAvailable' },
          ],
        ] as [string, RadioOption[]],
        [
          'isPatientInPainWhenUsingTheEquipment',
          [
            ...yesNoOptions,
            { label: tForm('na'), value: 'na' },
            { label: tForm('suggestPatientToCallDoctor'), value: 'suggestPatientToCallDoctor' },
          ],
        ] as [string, RadioOption[]],
      ];

      const patientInstructedToCallOrReturnToOfficeForFollowUpValue = watch(
        `${ns}.patientInstructedToCallOrReturnToOfficeForFollowUp`,
      );
      const isOnThisDate = patientInstructedToCallOrReturnToOfficeForFollowUpValue === 'onThisDate';
      const onThisDateTextValue = watch(`${ns}.onThisDateText`);

      const questionsSetB = [
        [
          'patientInstructedToCallOrReturnToOfficeForFollowUp',
          [
            { label: tForm('asNeeded'), value: 'asNeeded' },
            { label: tForm('inTwoWeeks'), value: 'inTwoWeeks' },
            {
              label: `${tForm('onThisDate')}:`,
              value: 'onThisDate',
              nodeAfter: (
                <DatePicker
                  {...register(`${ns}.onThisDateText`, { required: isOnThisDate })}
                  forceHideLabel
                  placeholder="Select date..."
                  minDate={new Date()}
                  date={new Date(onThisDateTextValue)}
                  // @ts-expect-error: @TODO: Fix type
                  onChange={(date: Date) => setValue(`${ns}.onThisDateText`, format(date, 'yyyy-MM-dd'))}
                  status={isOnThisDate ? 'active' : 'disabled'}
                  disabled={!isOnThisDate}
                  className="FormHomeSafetyEnvironmentAssessment-input-date-picker"
                />
              ),
              className: 'FormHomeSafetyEnvironmentAssessment-radio-with-input',
            },
          ],
          { inline: true },
        ] as [string, RadioOption[], Partial<RadioProps>],
      ];

      return (
        <FormSection title={tForm(ns)} inset={['left']}>
          {questionsSetA.map((q) => renderRadioInSection(ns, q))}
          <InputTextField
            {...register(`${ns}.functionalLimitations`)}
            label={tForm('functionalLimitationsIfAny')}
            type="text"
          />
          {questionsSetB.map((q) => renderRadioInSection(ns, q))}
        </FormSection>
      );
    };

    const renderHomeEnvironmentSection = () => {
      const ns = 'homeEnvironment';
      const mandatoryQuestions = [
        [
          'livingArrangement',
          [
            { label: tForm('livesAlone'), value: 'livesAlone' },
            { label: tForm('livesWithCaregiversFamily'), value: 'livesWithCaregiversFamily' },
          ],
        ] as [string, RadioOption[]],
        'caregiverFamilyWillingAndAbleToHelpWithEquipment',
        'instructionsGivenOnSafetyOperationAndMaintenance',
        'storageAreaForEquipment',
      ];

      const optionalQuestions = [
        [
          'homeType',
          [
            { label: tForm('singleStoryHouse'), value: 'singleStoryHouse' },
            { label: tForm('multiStoryHouse'), value: 'multiStoryHouse' },
            { label: tForm('aptCondo'), value: 'aptCondo' },
            { label: tForm('mobileHome'), value: 'mobileHome' },
          ],
        ] as [string, RadioOption[]],
        'handicapAccessible',
        [
          'wheelchairAccessibleRooms',
          [
            { label: tForm('bathroom'), value: 'bathroom' },
            { label: tForm('bedroom'), value: 'bedroom' },
            { label: tForm('kitchen'), value: 'kitchen' },
            { label: tForm('livingRoom'), value: 'livingRoom' },
          ],
          { multipleChoice: true, type: 'checkbox' },
        ] as [string, RadioOption[], Partial<RadioProps>],
      ];

      return (
        <FormSection title={tForm(ns)} inset={['left']}>
          {mandatoryQuestions.map((q) => renderRadioInSection(ns, q))}
          <hr />
          <p className="FormHomeSafetyEnvironmentAssessment-section-optional-title">
            <i>{`(${tForm('forWalkersRollatorsWheelchairsTransportersOnly')})`}</i>
          </p>
          <br />
          {/* ------ @Optional ------  */}
          {optionalQuestions.map((q) => renderRadioInSection(ns, q, false, 'mod-responsive-column'))}
          {renderMeasurementInput(
            `${ns}.entranceDoorWidth`,
            `${ns}.entranceDoorWidthUnit`,
            120,
            tForm('entranceDoorWidth'),
            false,
          )}
          <InputTextField
            {...register(`${ns}.additionalComment`)}
            label={tForm('additionalComment')}
            type="textarea"
            maxLength={180}
            elementAfter={
              hasAdditionalCommentNote ? (
                <p className="FormHomeSafetyEnvironmentAssessment-section-field-note">
                  {`(${tForm('additionalCommentNote')})`}
                </p>
              ) : undefined
            }
          />
        </FormSection>
      );
    };

    const renderHomeSafetyAssessmentSection = () => {
      const ns = 'homeSafetyAssessment';
      const questions = [
        'clearPathway',
        'unclutteredManeuveringArea',
        'smokeDetector',
        'waterHeatElectricityAvailability',
      ];
      return (
        <FormSection title={tForm(ns)} inset={['left']}>
          {questions.map((q) => renderRadioInSection(ns, q))}
        </FormSection>
      );
    };

    const renderBasicInfoSection = () => (
      <FormSection>
        <InputTextField
          {...register('name')}
          label={tForm('patientName')}
          placeholder={namePlaceholder}
          type="text"
          status="disabled"
        />
        <InputTextField {...register('customerId')} label={tForm('patientId')} type="text" status="disabled" />
        <PhoneInputWrapper hasError={false} hasErrorMessage={false}>
          {({ className, inputClassName, elementAfter }) => (
            <InputTextField
              {...register('phone')}
              label={tForm('phone')}
              type="text"
              className={className}
              inputClassName={inputClassName}
              elementAfter={elementAfter}
              status="disabled"
            />
          )}
        </PhoneInputWrapper>
        <InputTextField {...register('itemOrdered')} label={tForm('items')} type="text" status="disabled" />
        <InputTextField {...register('orderNo')} label={tForm('order')} type="text" status="disabled" />
      </FormSection>
    );

    const renderSignOffSection = () => (
      <FormSection>
        <InputTextField
          {...register('orderDate')}
          label={tForm('orderDate')}
          placeholder={datePlaceholder}
          type="date"
          status="disabled"
        />
        {hasDeliveryTech && (
          <InputTextField
            {...register('deliveryTech', { required: hasDeliveryTech })}
            label={tForm('deliveryTech')}
            type="text"
            maxLength={50}
          />
        )}
        {hasSignature && (
          <SignaturePadField
            {...register('signature', { required: hasSignature })}
            label={tForm('signature')}
            status={errors?.signature ? 'error' : 'active'}
            value={signatureValue}
            disabled={loading}
            onChange={(value) => setValue('signature', value)}
          />
        )}
        {hasRelationship && (
          <RadioField
            {...register('relationship', { required: hasRelationship })}
            label={tForm('relationship')}
            options={relationshipRadioOptions}
            disabled={loading}
            status={errors?.relationship ? 'error' : 'active'}
          />
        )}
        <InputTextField
          {...register('date')}
          label={tForm('date')}
          placeholder={datePlaceholder}
          type="date"
          status="disabled"
        />
      </FormSection>
    );

    return (
      <FormDetailsLayout
        className="FormHomeSafetyEnvironmentAssessment"
        id={form?.id}
        title={tForm('title')}
        ctaLabel={ctaLabel}
        ctaLoadingLabel={ctaLoadingLabel}
        secondaryCtaLabel={secondaryCtaLabel}
        onSubmit={handleSubmit(onSubmitWithDataMapping)}
        loading={loading}
        submitError={submitError}
      >
        {renderBasicInfoSection()}
        {renderHomeSafetyAssessmentSection()}
        {renderHomeEnvironmentSection()}
        {renderInstructionsAndFormsEvaluationSection()}
        {renderFallRiskAssessment()}
        {renderSignOffSection()}
      </FormDetailsLayout>
    );
  },
);

FormHomeSafetyEnvironmentAssessment.displayName = 'FormHomeSafetyEnvironmentAssessment';

export default FormHomeSafetyEnvironmentAssessment;
