import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute, ScrollToTop } from './components';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import ForgotPasswordVerify from '../pages/ForgotPasswordVerify/ForgotPasswordVerify';
import Login from '../pages/Login/Login';
import LoginVerify from '../pages/LoginVerify/LoginVerify';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import ResetPasswordComplete from '../pages/ResetPasswordComplete/ResetPasswordComplete';
import SignUp from '../pages/SignUp/SignUp';
import SignUpComplete from '../pages/SignUpComplete/SignUpComplete';
import SignUpVerify from '../pages/SignUpVerify/SignUpVerify';
import Home from '../pages/Home/Home';
import FormsAndDocuments from '../pages/FormsAndDocuments/FormsAndDocuments';
import FormDetails from '../pages/FormDetails/FormDetails';
import MyInformation from '../pages/MyInformation/MyInformation';
import Appointments from '../pages/Appointments/Appointments';
import AppointmentDetails from '../pages/AppointmentDetails/AppointmentDetails';
import Orders from '../pages/Orders/Orders';
import OrderDetails from '../pages/OrderDetails/OrderDetails';

const MainRouter = (): JSX.Element => (
  <Router>
    <ScrollToTop />
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/login/verify" component={LoginVerify} />
      <Route exact path="/sign-up" component={SignUp} />
      <Route exact path="/sign-up/verify" component={SignUpVerify} />
      <Route exact path="/sign-up/complete" component={SignUpComplete} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/forgot-password/verify" component={ForgotPasswordVerify} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/reset-password/complete" component={ResetPasswordComplete} />
      <PrivateRoute exact path="/home" component={Home} />
      <PrivateRoute exact path="/forms-and-documents" component={FormsAndDocuments} />
      <PrivateRoute exact path="/form/:id" component={FormDetails} />
      <PrivateRoute exact path="/my-information" component={MyInformation} />
      <PrivateRoute exact path="/appointments" component={Appointments} />
      <PrivateRoute exact path="/appointments/:id" component={AppointmentDetails} />
      <PrivateRoute exact path="/orders" component={Orders} />
      <PrivateRoute exact path="/orders/:id" component={OrderDetails} />
      <PrivateRoute
        component={(routeProps) => <Redirect to={{ pathname: '/home', state: { from: routeProps.location } }} />}
      />
    </Switch>
  </Router>
);

export default MainRouter;
