import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import parseBonafideDate from 'utils/parseBonafideDate';
import i18nNamespaces from '../../../i18n/i18nNamespaces';
import { CardConfig } from '../../../models/CardConfig';
import { Order } from '../../../models/Order';
import parseCustomerDob from '../../../utils/parseCustomerDob';

interface ReturnType {
  cardsConfig: CardConfig[];
  description: string;
  title: string;
}

const useContent = (orderDetails: Order): ReturnType => {
  const { t } = useTranslation(i18nNamespaces.ORDERS);

  const title = orderDetails?.itemInfo?.description || '';
  const description = orderDetails?.itemInfo?.equipType || '';

  const cardsConfig: CardConfig[] = [
    {
      title: t('item'),
      rows: [
        {
          key: t('orderDate'),
          value: parseBonafideDate(orderDetails.orderdate)
            ? dayjs(parseBonafideDate(orderDetails.orderdate)).format('MM/DD/YYYY')
            : '',
        },
        {
          key: t('description'),
          value: orderDetails?.itemInfo?.description || '',
        },
        {
          key: t('equipmentType'),
          value: orderDetails?.itemInfo?.equipType || '',
        },
        {
          key: t('subType'),
          value: orderDetails?.itemInfo?.subType || '',
        },
        {
          key: t('make'),
          value: orderDetails?.itemInfo?.make || '',
        },
        {
          key: t('partNo'),
          value: `${orderDetails?.itemInfo?.partNo || ''}`,
        },
        {
          key: t('sku'),
          value: `${orderDetails?.itemInfo?.sku || ''}`,
        },
        {
          key: t('hcpcs'),
          value: `${orderDetails?.itemInfo?.hcpcs || ''}`,
        },
      ],
    },
    {
      title: t('orderInfo'),
      rows: [
        {
          key: t('orderNo'),
          value: `#${orderDetails.orderno}`,
        },
        {
          key: t('authFromDate'),
          value: parseCustomerDob(orderDetails.paDetails[0]?.fromdate) || '',
        },
        {
          key: t('authToDate'),
          value: parseCustomerDob(orderDetails.paDetails[0]?.todate) || '',
        },
        {
          key: t('insurance'),
          value: orderDetails.paDetails[0]?.insurance || '',
        },
      ],
    },
  ];

  return { title, description, cardsConfig };
};

export default useContent;
