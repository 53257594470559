import { useCallback, useEffect, useState } from 'react';
import { getFormDropdownOptions } from 'api';
import { FormDetails } from 'models/FormDetails';

export interface UseFormDropdownOptionsReturnType<T> {
  isRefreshingOptions: boolean;
  dropdownOptions: Partial<T>;
}

const useFormDropdownOptions = <T>(form: FormDetails): UseFormDropdownOptionsReturnType<T> => {
  const [isRefreshingOptions, setIsRefreshingOptions] = useState(true);
  const [dropdownOptions, setDropdownOptions] = useState<Partial<T>>({} as Partial<T>);
  const version = form?.version ?? 'v1';

  const refreshOptions = useCallback(async () => {
    if (!form?.formComponent) return;
    setIsRefreshingOptions(true);
    try {
      const result = await getFormDropdownOptions<T>(form?.formComponent, version);
      if (result.status === 'error') {
        // @TODO: handle error
        setIsRefreshingOptions(false);
        return;
      }
      setDropdownOptions(result.data as unknown as T);
    } catch (error) {
      // @TODO: handle error
    } finally {
      setIsRefreshingOptions(false);
    }
  }, [form, version]);

  // Fetch and set users on mount
  useEffect(() => {
    refreshOptions();
  }, [refreshOptions]);

  return { isRefreshingOptions, dropdownOptions };
};

export default useFormDropdownOptions;
