import React, { memo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import OTPVerifyLayout from '../../layouts/OTPVerifyLayout/OTPVerifyLayout';

export const LoginVerify = memo<RouteComponentProps>((routeProps) => {
  return <OTPVerifyLayout {...routeProps} from="/login" to="/" />;
});

LoginVerify.displayName = 'LoginVerify';

export default LoginVerify;
