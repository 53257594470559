import { RootState } from 'MyTypes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Dispatch } from 'redux';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { deleteAppointment, getAppointmentDetails } from '../../api';
import HeaderCard from '../../components/HeaderCard/HeaderCard';
import InformationCards from '../../components/InformationCard/InformationCards';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import { Appointment } from '../../models/Appointment';
import { selectAppointment } from '../../store/AppointmentsDuck/duck/selector';
import './AppointmentDetails.scss';
import useContent from './hooks/useContent';
import { GoogleMaps } from '../../components/GoogleMaps/GoogleMaps';
import CTAButton from '../../components/CTAButton/CTAButton';
import AppleMapsIcon from '../../assets/icons/apple-maps.png';
import GoogleMapsIcon from '../../assets/icons/google-maps.png';
import convertHtmlToPlainText from '../../utils/convertHtmlToPlainText';
import * as UserDetailsActions from '../../store/UserDetailsDuck/duck/action';
import { CurrentUserDetails } from '../../models/CurrentUserDetails';
import { selectCurrentUserDetails } from '../../store/UserDetailsDuck/duck/selector';
import { ICSEvent } from '../../models/ICSEvent';
import AlertModal from '../../components/AlertModal/AlertModal';
import i18nNamespaces from '../../i18n/i18nNamespaces';
import replaceStringWithValue from '../../utils/replaceStringWithValue';
import isAppleProduct from '../../utils/isAppleProduct';
import { OwnProps } from '../../models/OwnProps';

interface Props {
  id: string;
  appointment?: Appointment;
  currentUserDetails: CurrentUserDetails | null;
  fetchCurrentUserDetails: (
    successCallback?: () => void,
    errorCallback?: () => void,
  ) => Promise<UserDetailsActions.ActionType>;
}

const AppointmentDetails = ({
  id,
  appointment: initialAppointmentState,
  currentUserDetails,
  fetchCurrentUserDetails,
}: Props): JSX.Element => {
  /** ------------------------------- Content ------------------------------- */
  const { t } = useTranslation(i18nNamespaces.APPOINTMENTS);

  const history = useHistory();

  const [appointment, setAppointment] = useState(initialAppointmentState);

  /* Loading states */
  const [deleting, setDeleting] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  /* Fetch Current User Details */
  useEffect(() => {
    if (!currentUserDetails) fetchCurrentUserDetails();
  }, [currentUserDetails, fetchCurrentUserDetails]);

  /* Fetch Appointment */
  useEffect(() => {
    if (appointment) return;
    getAppointmentDetails(id).then((res) => {
      if (res.status === 'ok') {
        setAppointment(res.data);
      } else {
        history.push('/appointments');
      }
    });
  }, [appointment, history, id]);

  if (!appointment) {
    return (
      <div className="AppointmentDetails-loading">
        <ClipLoader color="#06bbc9" />
      </div>
    );
  }

  /* Handlers */
  const handleCancelAppointment = useCallback(() => {
    const {
      id: appointmentId,
      start: date,
      store: { id: storeId },
    } = appointment;

    const timeslot = format(new Date(appointment.start), 'hh:mm a');

    setDeleting(true);
    deleteAppointment(appointmentId, timeslot, new Date(date), storeId)
      .then((res) => {
        if (res.status === 'ok') {
          history.push('/appointments');
        }
      })
      .finally(() => {
        setDeleting(false);
        setOpenDeleteModal(false);
      });
  }, [appointment, history]);

  const { title, description, cardsConfig } = useContent(appointment);

  const composeValueElement = (key: string, value: string) => {
    switch (key) {
      case t('phone'):
        return <a href={`tel:${value}`}>{value}</a>;
      case t('remarks'):
        return <div className="html-content" dangerouslySetInnerHTML={{ __html: value }} />;
      default:
        return value;
    }
  };

  const calendarEvent: ICSEvent = useMemo(() => {
    return {
      title: appointment.store.name,
      startTime: appointment.start,
      endTime: appointment.end,
      location: appointment.store.address.replace(/,/g, '').trim(),
      description: convertHtmlToPlainText(appointment.remarks),
    };
  }, [appointment]);

  return (
    <>
      <AlertModal
        isOpen={openDeleteModal}
        description={t('areYouSureYouWantToCancelThisAppointment')}
        confirmLabel={deleting ? t('cancellingAppointment') : t('confirm')}
        confirmDisabled={deleting}
        cancelLabel={t('cancel')}
        onConfirm={handleCancelAppointment}
        onCancel={() => setOpenDeleteModal(false)}
      />
      <MainLayout
        className="AppointmentDetails"
        showBackButton
        backRoutePathname="/appointments"
        contentMaxWidth={1000}
      >
        <HeaderCard hideLogo title={title} description={description}>
          <hr className="AppointmentDetails-headerCardHr" />
          <span
            className="AppointmentDetails-headerCardDescription"
            dangerouslySetInnerHTML={{
              __html: `${replaceStringWithValue(
                t('headerCardDescription'),
                'phone',
                `<a href="tel:${appointment.store.phone}">${appointment.store.phone}</a>`,
              )}`,
            }}
          />
        </HeaderCard>
        <div className="AppointmentDetails-cta-toolbar">
          <CTAButton
            type="ics"
            className="AppointmentDetails-cta-toolbar-add-to-calendar"
            text={t('addToCalendar')}
            calendarEvent={calendarEvent}
            filename="appointment"
          />
          {/* @TODO: Request Cancel Appointment */}
          {/* <CTAButton
            invert
            className="AppointmentDetails-cta-toolbar-cancel"
            text={deleting ? t('cancellingAppointment') : t('cancelAppointment')}
            onClick={() => setOpenDeleteModal(true)}
          /> */}
        </div>
        <InformationCards cards={cardsConfig} valueElementTransformer={composeValueElement} />
        <GoogleMaps
          className="AppointmentDetails-map"
          embeddedUrl={appointment.store.embeddedGoogleMapsUrl}
          googleMapsLabel={t('viewOnGoogleMaps')}
          appleMapsLabel={t('viewOnAppleMaps')}
          googleMapsUrl={appointment.store.googleMapsUrl}
          appleMapsUrl={appointment.store.appleMapsUrl}
        />
        <div className="AppointmentDetails-cta-maps">
          {isAppleProduct() && (
            <CTAButton
              iconSrc={AppleMapsIcon}
              iconPosition="left"
              className="AppointmentDetails-cta-maps-apple"
              text={t('viewOnAppleMaps')}
              onClick={() => window.open(appointment.store.appleMapsUrl, '_self')}
            />
          )}
          <CTAButton
            iconSrc={GoogleMapsIcon}
            iconPosition="left"
            className="AppointmentDetails-cta-maps-google"
            text={t('viewOnGoogleMaps')}
            onClick={() => window.open(appointment.store.googleMapsUrl, '_self')}
          />
        </div>
      </MainLayout>
    </>
  );
};

AppointmentDetails.displayName = 'AppointmentDetails';

const mapStateToProps = (
  state: RootState,
  props: OwnProps,
): Pick<Props, 'appointment' | 'currentUserDetails' | 'id'> => {
  const { id } = props.match.params;

  return {
    id,
    currentUserDetails: selectCurrentUserDetails(state),
    appointment: selectAppointment(state, id),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): Pick<Props, 'fetchCurrentUserDetails'> => ({
  fetchCurrentUserDetails: async (successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(await UserDetailsActions.fetchCurrentUserDetails(successCallback, errorCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentDetails);
