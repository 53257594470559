import React, { memo } from 'react';
import { CardConfig } from '../../models/CardConfig';
import Card from '../Card/Card';
import './InformationCards.scss';

interface Props {
  cards: CardConfig[];
  valueElementTransformer?: (key: string, value: string) => JSX.Element | string;
}

export const InformationCards = memo<Props>(({ cards, valueElementTransformer }) => {
  return (
    <>
      {cards.map((card) => (
        <Card key={card.title} className="InformationCards-section">
          <h2 className="InformationCards-section-title">{card.title}</h2>
          {card.rows.map((row, rowIndex) => (
            <div key={`${row.value}-${rowIndex + 1}`} className="InformationCards-section-row">
              <label htmlFor={row.key} className="InformationCards-section-row-label">
                {row.key}
              </label>
              <span className="InformationCards-section-row-value">
                {valueElementTransformer ? valueElementTransformer(row.key, row.value) : row.value}
              </span>
            </div>
          ))}
        </Card>
      ))}
    </>
  );
});

InformationCards.displayName = 'InformationCards';

export default InformationCards;
