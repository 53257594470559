import React, { memo, ReactElement } from 'react';
import './PhoneInputWrapper.scss';
import usFlagSrc from '../../assets/images/us_flag_64.png';

export interface PhoneInputWrapperChildrenProps {
  className: string;
  inputClassName: string;
  elementAfter: ReactElement;
}

interface Props {
  children: (childrenProps: PhoneInputWrapperChildrenProps) => ReactElement;
  hasError: boolean;
  hasErrorMessage: boolean;
}

export const PhoneInputWrapper = memo<Props>(({ children, hasError, hasErrorMessage }) => {
  return children({
    className: 'PhoneInputWrapper',
    inputClassName: 'PhoneInputWrapper-input',
    elementAfter: (
      <div
        className={`PhoneInputWrapper-prefix ${hasError ? 'mod-error' : ''} ${
          hasErrorMessage ? 'mod-error-message' : ''
        }`}
      >
        <img alt="us-flag" src={usFlagSrc} />
        <span>+1</span>
      </div>
    ),
  });
});

PhoneInputWrapper.displayName = 'PhoneInputWrapper';

export default PhoneInputWrapper;
