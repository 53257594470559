import { createSelector } from 'reselect';
import { RootState } from 'MyTypes';
import { State } from './reducer';

export const selectFormsState = (state: RootState): State => state.forms;

export const selectAllForms = createSelector(selectFormsState, (formsState) => formsState.forms);

export const selectUnsignedForms = createSelector(selectAllForms, (forms) =>
  forms.filter((form) => form.signed === false),
);
