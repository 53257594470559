import React, { memo, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import parseHTML from 'html-react-parser';

import { InputTextField } from 'components/InputTextField/InputTextField';
import SignaturePadField from 'components/SignaturePadField/SignaturePadField';
import RadioField from 'components/RadioField/RadioField';
import getFormRelationshipOptions from '../../../utils/getFormRelationshipOptions';
import useFormRepresentativeField from '../../../hooks/useFormRepresentativeField';
import { FormComponentProps } from '../../../types/FormComponentProps';
import FormDetailsLayout from '../../FormDetailsLayout/FormDetailsLayout';
import './MedicareCappedRentalNotificationLetter.scss';

const itemTypeValues = {
  CAPPED_RENTAL: 'cappedRental',
  INEXPENSIVE_OR_ROUTINELY_PURCHASED: 'inexpensiveOrRoutinelyPurchased',
};

interface Props<T = any> extends FormComponentProps<T> {
  namePlaceholder?: string;
  datePlaceholder?: string;
}

export const MedicareCappedRentalNotificationLetter = memo<Props>(
  ({
    form,
    onSubmit,
    loading,
    submitError,
    ctaLabel,
    ctaLoadingLabel,
    secondaryCtaLabel,
    tForm,
    namePlaceholder,
    datePlaceholder,
  }) => {
    /** ------------------------------- Radio Options ------------------------------- */
    const relationshipRadioOptions = useMemo(() => getFormRelationshipOptions(tForm), [tForm]);

    const {
      register,
      handleSubmit,
      formState: { errors },
      getValues,
      setValue,
      watch,
    } = useForm({
      defaultValues: {
        customerId: form?.customerId ?? '',
        name: form?.name ?? '',
        date: format(new Date(), 'yyyy-MM-dd'),
        itemOrdered: form?.paOrderData?.itemOrdered,
      } as { [key: string]: string },
    });
    const { signature: signatureValue, itemOrdered: itemOrderedValue } = getValues();
    const itemTypeValue = watch('itemType');

    const { handleRepresentativeNameChange, isSelfRepresentative } = useFormRepresentativeField(watch, setValue);

    const renderStatement = () => {
      const emphasize = (text: string) => `<strong><u>${text}</u></strong>`;
      const statement = tForm('statement', {
        returnObjects: true,
        itemOrdered: emphasize(itemOrderedValue),
      }) as string[];
      return <p>{parseHTML(Array.isArray(statement) ? statement.join('') : '')}</p>;
    };

    const renderItemTypeBulletPoints = (bulletPointsTransKey: string) => {
      const bulletPoints = tForm(bulletPointsTransKey, { returnObjects: true });
      const bulletPointsText = `<ul>${
        Array.isArray(bulletPoints) ? (bulletPoints as unknown as string[])?.map((pt) => `<li>${pt}</li>`).join('') : ''
      }</ul>`;
      return parseHTML(bulletPointsText);
    };

    const renderRoutinelyPurchaseItemDescription = () => {
      const bulletPointsNode = renderItemTypeBulletPoints('routinelyPurchasedItemsBulletPoints');
      const isSelected = itemTypeValue === itemTypeValues.INEXPENSIVE_OR_ROUTINELY_PURCHASED;
      const itemOptions = [
        { value: 'purchase', label: tForm('purchaseOption') },
        { value: 'rental', label: tForm('rentalOption') },
      ];
      return (
        <>
          {bulletPointsNode}
          <div className="MedicareCappedRentalNotificationLetter-item-options">
            <RadioField
              {...register('itemOption', { required: isSelected })}
              options={itemOptions}
              disabled={loading || !isSelected}
              status={errors?.itemOption ? 'error' : 'active'}
            />
          </div>
        </>
      );
    };

    const getItemTypeClassNameByValue = (value: string) =>
      itemTypeValue !== '' && itemTypeValue !== value
        ? 'MedicareCappedRentalNotificationLetter-radio-non-selected'
        : 'MedicareCappedRentalNotificationLetter-radio-selected';

    const itemTypeRadioOptions = [
      {
        value: itemTypeValues.CAPPED_RENTAL,
        label: tForm('forCappedRentalItems'),
        nodeAfter: renderItemTypeBulletPoints('cappedRentalItemsBulletPoints'),
        className: getItemTypeClassNameByValue(itemTypeValues.CAPPED_RENTAL),
      },
      {
        value: itemTypeValues.INEXPENSIVE_OR_ROUTINELY_PURCHASED,
        label: tForm('forRoutinelyPurchasedItems'),
        nodeAfter: renderRoutinelyPurchaseItemDescription(),
        className: getItemTypeClassNameByValue(itemTypeValues.INEXPENSIVE_OR_ROUTINELY_PURCHASED),
      },
    ];

    return (
      <FormDetailsLayout
        className="MedicareCappedRentalNotificationLetter"
        id={form?.id}
        title={tForm('title')}
        ctaLabel={ctaLabel}
        ctaLoadingLabel={ctaLoadingLabel}
        secondaryCtaLabel={secondaryCtaLabel}
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
        submitError={submitError}
      >
        <InputTextField {...register('customerId')} label={tForm('id')} type="text" status="disabled" />
        <InputTextField
          {...register('name')}
          label={tForm('patientName')}
          placeholder={namePlaceholder}
          type="text"
          status="disabled"
        />
        {renderStatement()}
        <RadioField
          {...register('itemType', { required: true })}
          options={itemTypeRadioOptions}
          disabled={loading}
          status={errors?.itemType ? 'error' : 'active'}
        />
        <br />
        <SignaturePadField
          {...register('signature', { required: true })}
          label={tForm('signature')}
          status={errors?.signature ? 'error' : 'active'}
          value={signatureValue}
          disabled={loading}
          onChange={(value) => setValue('signature', value)}
        />
        <InputTextField
          {...register('date')}
          label={tForm('date')}
          placeholder={datePlaceholder}
          type="date"
          status="disabled"
        />
        <InputTextField
          {...register('representativeName', { required: true })}
          label={tForm('signName')}
          placeholder={namePlaceholder}
          type="text"
          disabled={isSelfRepresentative || loading}
          maxLength={50}
          onChange={handleRepresentativeNameChange}
        />
        <RadioField
          {...register('relationship', { required: true })}
          label={tForm('relationship')}
          options={relationshipRadioOptions}
          disabled={loading}
          status={errors?.relationship ? 'error' : 'active'}
        />
      </FormDetailsLayout>
    );
  },
);

MedicareCappedRentalNotificationLetter.displayName = 'MedicareCappedRentalNotificationLetter';

export default MedicareCappedRentalNotificationLetter;
