import dayjs from 'dayjs';
import React, { forwardRef, memo, useCallback, useState } from 'react';
import ReactCalendar, { CalendarTileProperties } from 'react-calendar';
import './DatePicker.scss';
import { InputTextField, InputTextFieldProps } from '../InputTextField/InputTextField';

interface Props extends Partial<Omit<InputTextFieldProps, 'onChange'>> {
  name: string;
  minDate?: Date;
  maxDate?: Date;
  date: Date | null;
  enabledDates?: Date[];
  onChange: (date: Date) => void;
  /** Default 'right' */
  calendarAlign?: 'left' | 'right';
}

export const DatePicker = forwardRef(
  (
    { name, minDate, maxDate, date, enabledDates, onChange, calendarAlign = 'right', ...restProps }: Props,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: any,
  ) => {
    const [openCalendar, setOpenCalendar] = useState(false);

    const disableTile = useCallback(
      (props: CalendarTileProperties) => {
        const { date: tileDate, view } = props;
        if (enabledDates && view === 'month') {
          return !enabledDates.some(
            (enabledDate) =>
              tileDate.getFullYear() === enabledDate.getFullYear() &&
              tileDate.getMonth() === enabledDate.getMonth() &&
              tileDate.getDate() === enabledDate.getDate(),
          );
        }
        return false;
      },
      [enabledDates],
    );

    const handleOnChange = useCallback(
      (newDate: Date) => {
        onChange(newDate);
        setOpenCalendar(false);
      },
      [onChange],
    );

    return (
      <div className={`DatePicker ${calendarAlign === 'left' ? 'mod-align-start' : ''}`}>
        <InputTextField
          ref={ref}
          type="text"
          value={date ? dayjs(date).format('MM/DD/YYYY') : ''}
          name={name}
          readOnly
          onClick={() => setOpenCalendar(!openCalendar)}
          {...restProps}
        />
        {openCalendar && (
          <div className="DatePicker-calendar">
            <ReactCalendar
              minDate={minDate}
              maxDate={maxDate}
              value={date ? dayjs(date).toDate() : null}
              onChange={handleOnChange}
              className="DatePicker"
              tileDisabled={disableTile}
            />
          </div>
        )}
      </div>
    );
  },
);

DatePicker.displayName = 'DatePicker';

export default memo(DatePicker);
