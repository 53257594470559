import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import i18nNamespaces from '../../../i18n/i18nNamespaces';
import { Appointment } from '../../../models/Appointment';
import { CardConfig } from '../../../models/CardConfig';

interface ReturnType {
  title: string;
  description: string;
  cardsConfig: CardConfig[];
}
const useContent = (appointment: Appointment): ReturnType => {
  const { t } = useTranslation(i18nNamespaces.APPOINTMENTS);
  const { start, store, remarks } = appointment;

  const title = format(new Date(start), 'MM/dd/yyyy hh:mm a');
  const description = store.name;

  const cardsConfig = [
    {
      title: t('appointment'),
      rows: [
        {
          key: t('date'),
          value: format(new Date(start), 'MM/dd/yyyy'),
        },
        {
          key: t('time'),
          value: format(new Date(start), 'hh:mm a'),
        },
        {
          key: t('remarks'),
          value: remarks,
        },
      ],
    },
    {
      title: t('location'),
      rows: [
        {
          key: t('store'),
          value: store.name,
        },
        {
          key: t('address'),
          value: store.address,
        },
        {
          key: t('phone'),
          value: store.phone,
        },
        {
          key: t('fax'),
          value: store.fax,
        },
      ],
    },
  ];

  return { title, description, cardsConfig };
};

export default useContent;
