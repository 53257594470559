import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import parseHTML from 'html-react-parser';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import getAPIErrorI18nKey from 'utils/getAPIErrorI18nKey';
import CTAButton from '../../components/CTAButton/CTAButton';
import OTPInput from '../../components/OTPInput/OTPInput';
import VisitorJumbotron from '../../components/VisitorJumbotron/VisitorJumbotron';
import useTimer from '../../hooks/useTimer';
import i18nNamespaces from '../../i18n/i18nNamespaces';
import VisitorLayout from '../VisitorLayout/VisitorLayout';
import './OTPVerifyLayout.scss';
import { selectOTPPhone, selectOTPUsername } from '../../store/UserDetailsDuck/duck/selector';
import { postResendOTP, postVerify, postVerifyWithToken } from '../../api';
import * as UserDetailsActions from '../../store/UserDetailsDuck/duck/action';
import { VerifyWithTokenResponse } from '../../models/VerifyWithTokenResponse';

const RESEND_INTERVAL_SECONDS = 60;
const getOTPFieldName = (index: number) => `otp_${index}`;

interface Props extends RouteComponentProps {
  /** The source route */
  from: string;
  /** The destination route after OTP verified */
  to: string;
  /** Verify with token returned */
  withToken?: boolean;
  setToken?: (token: string) => Promise<UserDetailsActions.ActionType>;
  sendOnMountDisabled?: boolean;
}

export const OTPVerifyLayout = memo<Props>(({ from, to, match, withToken, setToken, sendOnMountDisabled }) => {
  const history = useHistory();

  const username = useSelector(selectOTPUsername);
  const phone = useSelector(selectOTPPhone);

  const { t } = useTranslation(i18nNamespaces.OTP_VERIFY);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const localStorageKey = `${match.path}:OTPVerifyLayout-resendTimer`;
  const [isResending, setIsResending] = useState(false);
  const [resendTimer, setResendTimer] = useTimer({
    mode: 'countDown',
    boundaryValue: 0,
    localStorageKey,
  });

  // Resend verification code
  const onResend = useCallback(async () => {
    if (resendTimer > 0) return;

    setSubmitError(null);
    setIsResending(true);
    try {
      const res = await postResendOTP({ username, ...(phone ? { phone } : {}) });

      switch (res.status) {
        case 'ok': {
          setResendTimer(RESEND_INTERVAL_SECONDS);
          break;
        }
        case 'error':
          setSubmitError(res.error ? t(`error_${res.error}`) : null);
          break;
        default:
          break;
      }
    } catch (error) {
      // @TODO: Log error message to server
      setSubmitError(t(getAPIErrorI18nKey(error, i18nNamespaces.OTP_VERIFY)));
    } finally {
      setIsResending(false);
    }
  }, [phone, resendTimer, setResendTimer, t, username]);

  // Send code on mount
  const hasSendOnMount = useRef(false);
  useEffect(() => {
    if (!hasSendOnMount.current && !sendOnMountDisabled) {
      onResend();
      hasSendOnMount.current = true;
    }
  }, [onResend, sendOnMountDisabled]);

  // Form hook
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setFocus,
    setValue,
  } = useForm();

  // Verify code and go to next route
  const onSubmit = async () => {
    setLoading(true);
    const codeSegments = Object.values(getValues());
    const code = codeSegments.join('');

    try {
      const res = await (() => {
        if (withToken) {
          return postVerifyWithToken({ username, phone, otp: code });
        }
        return postVerify({ username, otp: code });
      })();

      switch (res.status) {
        case 'ok': {
          if (withToken && setToken) setToken((res as VerifyWithTokenResponse).token);
          setTimeout(() => {
            localStorage.removeItem(localStorageKey);
            history.push(to);
          }, 0);
          break;
        }
        case 'error':
          setSubmitError(res.error ? t(`error_${res.error}`) : null);
          break;
        default:
          break;
      }
    } catch (error) {
      // @TODO: Log error message to server
      setSubmitError(t(getAPIErrorI18nKey(error, i18nNamespaces.OTP_VERIFY)));
    } finally {
      setLoading(false);
    }
  };

  return (
    <VisitorLayout className="OTPVerifyLayout" hasError={!!submitError}>
      {({
        formClassName,
        formContainerClassName,
        formErrorClassName,
        formButtonsContainerClassName,
        formHintButtonClassName,
      }) => (
        <>
          <VisitorJumbotron title={t('title')} description={t('description')} />
          <form className={`${formClassName} OTPVerifyLayout-form`} onSubmit={handleSubmit(onSubmit)}>
            <div className={formContainerClassName}>
              <OTPInput
                register={register}
                getFieldName={getOTPFieldName}
                setValue={setValue}
                setFocus={setFocus}
                errors={errors}
              />
            </div>
            <span className={`${formErrorClassName} OTPVerifyLayout-form-error`}>{`${submitError}`}</span>
            <p className={`OTPVerifyLayout-timer ${resendTimer <= 0 ? 'mod-zero' : ''}`}>
              {parseHTML(t('resendAfter', { count: resendTimer }))}
            </p>
            <div className={formButtonsContainerClassName}>
              <CTAButton type="submit" text={loading ? t('ctaLoading') : t('cta')} disabled={loading} />
              <CTAButton
                type="button"
                text={isResending ? t('secondaryCtaLoading') : t('secondaryCta')}
                invert
                onClick={onResend}
                disabled={loading || isResending || resendTimer > 0}
              />
            </div>
          </form>
          <Link to={from} className={formHintButtonClassName}>
            {t('bottomCta')}
          </Link>
        </>
      )}
    </VisitorLayout>
  );
});

OTPVerifyLayout.displayName = 'OTPVerifyLayout';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setToken: async (token: string) => dispatch(await UserDetailsActions.setResetPasswordToken(token)),
});

export default connect(null, mapDispatchToProps)(OTPVerifyLayout);
