import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import parseHTML from 'html-react-parser';
import { format } from 'date-fns';

import { InputTextField } from 'components/InputTextField/InputTextField';
import SignaturePadField from 'components/SignaturePadField/SignaturePadField';
import { FormComponentProps } from '../../../types/FormComponentProps';
import FormDetailsLayout from '../../FormDetailsLayout/FormDetailsLayout';
import './MedicareDMEPOSSupplierStandardForm.scss';

interface Props<T = any> extends FormComponentProps<T> {
  namePlaceholder?: string;
  datePlaceholder?: string;
}

export const MedicareDMEPOSSupplierStandardForm = memo<Props>(
  ({
    form,
    onSubmit,
    loading,
    submitError,
    ctaLabel,
    ctaLoadingLabel,
    secondaryCtaLabel,
    namePlaceholder,
    datePlaceholder,
    tForm,
  }) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      getValues,
      setValue,
    } = useForm({
      defaultValues: {
        name: form?.name ?? '',
        date: format(new Date(), 'yyyy-MM-dd'),
      } as { [key: string]: string },
    });
    const { beneficiarySignature: beneficiarySignatureValue } = getValues();

    const renderEmphasizedStatement = (statement: string, className = '') => (
      <div className={`MedicareDMEPOSSupplierStandardForm-emphasized-statement ${className}`}>{statement}</div>
    );
    const renderDescription = () => {
      const mainStatementBulletPoints = tForm('mainStatementBulletPoints', { returnObjects: true });
      const bulletPointsText = `<ol>${
        Array.isArray(mainStatementBulletPoints)
          ? (mainStatementBulletPoints as unknown as string[])?.map((pt) => `<li>${pt}</li>`).join('')
          : ''
      }</ol>`;
      return <p className="MedicareDMEPOSSupplierStandardForm-description">{parseHTML(bulletPointsText)}</p>;
    };

    return (
      <FormDetailsLayout
        className="MedicareDMEPOSSupplierStandardForm"
        id={form?.id}
        title={tForm('title')}
        ctaLabel={ctaLabel}
        ctaLoadingLabel={ctaLoadingLabel}
        secondaryCtaLabel={secondaryCtaLabel}
        onSubmit={handleSubmit(onSubmit)}
        loading={loading}
        submitError={submitError}
        afterHeaderNode={renderEmphasizedStatement(tForm('statementBefore'))}
      >
        {renderDescription()}
        {renderEmphasizedStatement(tForm('statementAfter'), 'mod-after')}
        <InputTextField
          {...register('name')}
          label={tForm('clientName')}
          placeholder={namePlaceholder}
          type="text"
          status="disabled"
        />
        <SignaturePadField
          {...register('beneficiarySignature', { required: true })}
          label={tForm('beneficiarySignature')}
          status={errors?.beneficiarySignature ? 'error' : 'active'}
          value={beneficiarySignatureValue}
          disabled={loading}
          onChange={(value) => setValue('beneficiarySignature', value)}
        />
        <InputTextField
          {...register('date')}
          label={tForm('date')}
          placeholder={datePlaceholder}
          type="date"
          status="disabled"
        />
      </FormDetailsLayout>
    );
  },
);

MedicareDMEPOSSupplierStandardForm.displayName = 'MedicareDMEPOSSupplierStandardForm';

export default MedicareDMEPOSSupplierStandardForm;
