import i18next from 'i18next';
import { action } from 'typesafe-actions';
import { NotificationDetails } from '../../../models/Notifications';

export interface ActionType {
  type: 'forms/FETCH_NOTIFICATIONS' | 'forms/FETCH_NOTIFICATIONS_SUCCESS' | 'forms/FETCH_NOTIFICATIONS_FAIL';

  payload: {
    i18n?: typeof i18next;
    notifications?: NotificationDetails[];
    error?: string;
    successCallback?: () => void;
    errorCallback?: () => void;
  };
}

export const FETCH_NOTIFICATIONS = 'forms/FETCH_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS_SUCCESS = 'forms/FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAIL = 'forms/FETCH_NOTIFICATIONS_FAIL';

/* ------------- Fetch Notifications  ------------- */
export const fetchNotifications = (
  limit?: number,
  successCallback?: () => void,
  errorCallback?: () => void,
): ActionType => action(FETCH_NOTIFICATIONS, { limit, successCallback, errorCallback });

export const fetchNotificationsSuccess = (notifications: NotificationDetails[]): ActionType =>
  action(FETCH_NOTIFICATIONS_SUCCESS, { notifications });

export const fetchNotificationsFail = (error?: string): ActionType => action(FETCH_NOTIFICATIONS_FAIL, { error });
