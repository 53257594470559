const replaceStringWithValue = (text: string, target: string[] | string, newText: string[] | string): string => {
  if (!Array.isArray(target) && !Array.isArray(newText)) {
    return text.replace(new RegExp(`{ ${target} }`, 'g'), newText);
  }
  if (Array.isArray(target) && Array.isArray(newText) && target.length === newText.length) {
    let content = text;
    target.forEach((item, index) => {
      content = content.replace(new RegExp(`{ ${item} }`, 'g'), newText[index]);
    });
    return content;
  }
  return text;
};

export default replaceStringWithValue;
