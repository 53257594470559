import { RootState } from 'MyTypes';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import parseBonafideDate from 'utils/parseBonafideDate';
import HeaderCard from '../../components/HeaderCard/HeaderCard';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import { CurrentUserDetails } from '../../models/CurrentUserDetails';
import { selectAllOrders } from '../../store/OrdersDuck/duck/selector';
import * as UserDetailsActions from '../../store/UserDetailsDuck/duck/action';
import { selectCurrentUserDetails } from '../../store/UserDetailsDuck/duck/selector';
import * as OrdersActions from '../../store/OrdersDuck/duck/action';
import { Order } from '../../models/Order';
import ItemList, { ListItem } from '../../components/ItemList/ItemList';
import i18nNamespaces from '../../i18n/i18nNamespaces';

interface Props {
  orders: Order[];
  currentUserDetails: CurrentUserDetails | null;
  fetchCurrentUserDetails: (
    successCallback?: () => void,
    errorCallback?: () => void,
  ) => Promise<UserDetailsActions.ActionType>;
  fetchOrders: (successCallback?: () => void, errorCallback?: () => void) => Promise<OrdersActions.ActionType>;
}
const Orders: FC<Props> = ({ currentUserDetails, fetchCurrentUserDetails, fetchOrders, orders }) => {
  const [isRefreshing, setIsRefreshing] = useState(true);
  const history = useHistory();
  /** ------------------------------- Content ------------------------------- */
  const { t } = useTranslation(i18nNamespaces.ORDERS);

  useEffect(() => {
    fetchOrders(
      () => setIsRefreshing(false),
      () => setIsRefreshing(false),
    );
    if (!currentUserDetails) fetchCurrentUserDetails();
  }, [currentUserDetails, fetchCurrentUserDetails, fetchOrders]);

  const orderItems: ListItem[] = useMemo(() => {
    return orders
      .map((order) => ({
        id: order.id,
        title: order.itemInfo?.description || '',
        extraLabel: order.itemInfo?.equipType || '',
        disabled: false,
        remarks: order.orderno !== '0' ? `#${order.orderno}` : ' ',
        orderDate: parseBonafideDate(order.orderdate)?.getTime() || Infinity,
      }))
      .sort((a, b) => a.orderDate - b.orderDate);
  }, [orders]);

  return (
    <MainLayout showBackButton backRoutePathname="/" contentMaxWidth={1000}>
      <HeaderCard title={t('title')} description={`#${currentUserDetails?.customerId}`} />
      <ItemList
        items={orderItems}
        trueLabel=""
        falseLabel=""
        onItemClick={({ id }) => history.push(`/orders/${id}`)}
        isRefreshing={isRefreshing}
        emptyLabel={t('emptyList')}
      />
    </MainLayout>
  );
};

const mapStateToProps = (state: RootState): Pick<Props, 'currentUserDetails' | 'orders'> => ({
  currentUserDetails: selectCurrentUserDetails(state),
  orders: selectAllOrders(state),
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<Props, 'fetchCurrentUserDetails' | 'fetchOrders'> => ({
  fetchCurrentUserDetails: async (successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(await UserDetailsActions.fetchCurrentUserDetails(successCallback, errorCallback)),
  fetchOrders: async (successCallback?: () => void, errorCallback?: () => void) =>
    dispatch(await OrdersActions.fetchOrders(successCallback, errorCallback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
