import qs from 'qs';

import { PDFFormDropdownOptionsErrorResponse, PDFFormDropdownOptionsResponse } from 'models/PDFFormDropdownOptions';
import { ResetPasswordDetails } from '../models/ResetPasswordDetails';
import { ResetPasswordResponse } from '../models/ResetPasswordResponse';
import { LoginDetails } from '../models/LoginDetails';
import { LoginResponse } from '../models/LoginResponse';
import { ResendOTPDetails } from '../models/ResendOTPDetails';
import { ResendOTPResponse } from '../models/ResendOTPResponse';
import { VerifyDetails } from '../models/VerifyDetails';
import { VerifyResponse } from '../models/VerifyResponse';
import { VerifyWithTokenDetails } from '../models/VerifyWithTokenDetails';
import { VerifyWithTokenResponse } from '../models/VerifyWithTokenResponse';
import axios from './axios';
import { RegisterUserDetails } from '../models/RegisterUserDetails';
import { RegisterUserResponse } from '../models/RegisterUserResponse';
import { GetAllFormsResponse } from '../models/GetAllFormsResponse';
import { SubmitFormDetails } from '../models/SubmitFormDetails';
import { SubmitFormErrorResponse, SubmitFormResponse } from '../models/SubmitFormResponse';
import { GetFormContentErrorResponse, GetFormContentResponse } from '../models/GetFormContentResponse';
import Config from '../Config';
import { CurrentUserDetailsResponse } from '../models/CurrentUserDetails';
import { GetFormCatalogContentsResponse, GetFormCatalogErrorResponse } from '../models/GetFormCatalogContentsResponse';
import { AppointmentResponse, AppointmentsResponse } from '../models/Appointment';
import { OrderDetailsResponse, OrdersResponse } from '../models/Order';
import { NotificationsResponse } from '../models/Notifications';

export const postRegisterUser = (registerUserDetails: RegisterUserDetails): Promise<RegisterUserResponse> =>
  axios.post('/api/auth/register', registerUserDetails).then((res) => res.data);

export const postLoginDetails = (loginDetails: LoginDetails): Promise<LoginResponse> =>
  axios.post('/api/auth/login', loginDetails).then((res) => res.data);

export const getCurrentUserDetails = (): Promise<CurrentUserDetailsResponse> =>
  axios.get('/api/profile/me').then((res) => res.data);

export const postResendOTP = (resendOtpDetails: ResendOTPDetails): Promise<ResendOTPResponse> =>
  axios.post('/api/auth/otp/resend', resendOtpDetails).then((res) => res.data);

export const postVerify = (verifyDetails: VerifyDetails): Promise<VerifyResponse> =>
  axios.post('/api/auth/verify', verifyDetails).then((res) => res.data);

export const postVerifyWithToken = (verifyDetails: VerifyWithTokenDetails): Promise<VerifyWithTokenResponse> =>
  axios.post('/api/auth/verify/token', verifyDetails).then((res) => res.data);

export const postResetPassword = (
  resetToken: string,
  changePwDetails: ResetPasswordDetails,
): Promise<ResetPasswordResponse> =>
  axios
    .post('/api/auth/reset-password', changePwDetails, { headers: { 'x-reset-token': resetToken } })
    .then((res) => res.data);

export const postRefreshToken = (refreshToken: string): Promise<LoginResponse> =>
  axios
    .post(
      '/api/auth/token/refresh',
      {},
      {
        headers: {
          'x-refresh-token': refreshToken,
        },
      },
    )
    .then((res) => res.data);

export const getAllForms = (): Promise<GetAllFormsResponse> => axios.get('/api/forms').then((res) => res.data);

export const getFormDropdownOptions = <T = any>(
  formComponent: string,
  version: string,
): Promise<PDFFormDropdownOptionsResponse<T> | PDFFormDropdownOptionsErrorResponse> =>
  axios.get(`/api/forms/dropdown-options/${formComponent}/${version}`).then((res) => res.data);

export const postSubmitForm = (
  id: string,
  details: SubmitFormDetails,
): Promise<SubmitFormResponse | SubmitFormErrorResponse> =>
  axios.post(`/api/forms/submit/${id}`, details, { timeout: 300000 }).then((res) => res.data);

export const getFormContent = (
  formComponent: string,
  version: string,
): Promise<GetFormContentResponse | GetFormContentErrorResponse> =>
  axios.get(`/api/forms/${formComponent}/${version}`, { baseURL: Config.contentUrl }).then((res) => res.data);

export const getFormsCatalogContent = (): Promise<GetFormCatalogContentsResponse | GetFormCatalogErrorResponse> =>
  axios.get(`/api/forms/catalog`, { baseURL: Config.contentUrl }).then((res) => res.data);

export const getAppointments = (): Promise<AppointmentsResponse> =>
  axios.get('/api/appointments/all-appointments').then((res) => res.data);

export const getAppointmentDetails = (id: string): Promise<AppointmentResponse> =>
  axios.get(`/api/appointments?${qs.stringify({ id })}`).then((res) => res.data);

export const deleteAppointment = (
  id: string,
  timeslot: string,
  date: Date,
  storeId: string,
): Promise<{ status: 'ok' | 'error' }> =>
  axios.delete(`/api/appointments/delete-appointment?${qs.stringify({ id, timeslot, date, storeId })}`);

export const getOrders = (): Promise<OrdersResponse> => axios.get('/api/orders/all-orders').then((res) => res.data);

export const getOrderDetails = (id: string): Promise<OrderDetailsResponse> =>
  axios.get(`/api/orders?${qs.stringify({ id })}`).then((res) => res.data);

export const getNotifications = (): Promise<NotificationsResponse> =>
  axios.get('/api/notifications').then((res) => res.data);
