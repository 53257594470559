import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FixedSizeList as List, FixedSizeListProps } from 'react-window';
import i18nNamespaces from '../../../../i18n/i18nNamespaces';
import './NotificationsPreview.scss';

const ITEM_HEIGHT = 40;

export interface NotificationPreviewDataItem {
  /** Key for identifying this item */
  key: string | number;
  /** file path of icon */
  icon: string;
  title: string;
  pathname: string;
}

interface Props {
  data: NotificationPreviewDataItem[];
  maxDisplayedRow?: number;
}

export const NotificationsPreview = memo<Props>(({ data, maxDisplayedRow = 3 }) => {
  const { t } = useTranslation(i18nNamespaces.APP);

  const renderItem = ({ key, icon, title, pathname }: NotificationPreviewDataItem) => (
    <Link to={pathname} className="NotificationsPreview-list-item" key={key} style={{ height: ITEM_HEIGHT }}>
      <span
        className="NotificationsPreview-list-item-icon"
        style={{
          mask: `url(${icon}) no-repeat center`,
          WebkitMask: `url(${icon}) no-repeat center`,
          maskSize: 18,
          WebkitMaskSize: 18,
        }}
      />
      <span className="NotificationsPreview-list-item-title">{title}</span>
    </Link>
  );

  const Row: FixedSizeListProps['children'] = ({ index, style }) => <div style={style}>{renderItem(data[index])}</div>;

  const renderList = () =>
    data.length <= maxDisplayedRow ? (
      <div className="NotificationsPreview-list">{data.map(renderItem)}</div>
    ) : (
      <List width="auto" height={ITEM_HEIGHT * maxDisplayedRow} itemSize={ITEM_HEIGHT} itemCount={data.length}>
        {Row}
      </List>
    );

  return (
    <div className="NotificationsPreview">
      <h3 className="NotificationsPreview-title">{t('notification_plural')}</h3>
      {data.length === 0 ? <div className="NotificationsPreview-empty">--</div> : renderList()}
    </div>
  );
});

NotificationsPreview.displayName = 'NotificationsPreview';

export default NotificationsPreview;
