import React, { useState } from 'react';
import parseHTML from 'html-react-parser';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect, useSelector } from 'react-redux';

import getAPIErrorI18nKey from 'utils/getAPIErrorI18nKey';
import VisitorJumbotron from '../../components/VisitorJumbotron/VisitorJumbotron';
import i18nNamespaces from '../../i18n/i18nNamespaces';
import VisitorLayout from '../../layouts/VisitorLayout/VisitorLayout';
import CTAButton from '../../components/CTAButton/CTAButton';
import InputTextField from '../../components/InputTextField/InputTextField';
import * as UserDetailsActions from '../../store/UserDetailsDuck/duck/action';
import { selectOTPUsername, selectResetPasswordToken } from '../../store/UserDetailsDuck/duck/selector';
import { postResetPassword } from '../../api';

interface FormData {
  password: string;
  confirmPassword: string;
}

interface Props {
  clearLoginDetails: () => Promise<UserDetailsActions.ActionType>;
}

export const ResetPassword: React.FC<Props> = ({ clearLoginDetails }) => {
  const history = useHistory();

  const username = useSelector(selectOTPUsername);
  const resetToken = useSelector(selectResetPasswordToken);

  const { t } = useTranslation(i18nNamespaces.RESET_PW);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormData>();

  const validateConfirmPassword = (confirmPassword: string) => {
    const { password } = getValues();
    if (confirmPassword !== password) return t('passwordMismatch');
    return true;
  };

  const onSubmit = async (data: FormData) => {
    setSubmitError(null);
    setLoading(true);
    history.push('/reset-password/complete');
    try {
      const res = await postResetPassword(resetToken, { ...data, username });

      switch (res.status) {
        case 'ok': {
          await clearLoginDetails();
          setTimeout(() => history.push('/reset-password/complete'), 0);
          break;
        }
        case 'error':
          setSubmitError(res.error ? t(`error_${res.error}`) : null);
          break;
        default:
          break;
      }
    } catch (err) {
      // @TODO: Log error message to server
      setSubmitError(t(getAPIErrorI18nKey(err, i18nNamespaces.RESET_PW)));
    } finally {
      setLoading(false);
    }
  };

  return (
    <VisitorLayout hasError={!!submitError}>
      {({
        formClassName,
        formContainerClassName,
        formErrorClassName,
        formRemarkClassName,
        formButtonsContainerClassName,
        formHintButtonClassName,
      }) => (
        <>
          <VisitorJumbotron title={t('title')} description={parseHTML(t('description'))} smallerTitle />
          <form className={formClassName} onSubmit={handleSubmit(onSubmit)}>
            <div className={formContainerClassName}>
              <InputTextField name="email" label={t('email')} type="email" value={username} disabled />
              <InputTextField
                {...register('password', { required: true })}
                type="password"
                label={t('password')}
                placeholder="•••••••••••••••"
                minLength={10}
                status={errors?.password ? 'error' : 'active'}
              />
              <InputTextField
                {...register('confirmPassword', { required: true, validate: validateConfirmPassword })}
                type="password"
                label={t('confirmPassword')}
                placeholder="•••••••••••••••"
                minLength={10}
                status={errors?.confirmPassword ? 'error' : 'active'}
                bottomLabel={errors?.confirmPassword?.message}
              />
            </div>
            <span className={formErrorClassName}>{`${submitError}`}</span>
            <p className={formRemarkClassName}>{parseHTML(t('remark'))}</p>
            <div className={formButtonsContainerClassName}>
              <CTAButton type="submit" text={loading ? t('ctaLoading') : t('cta')} disabled={loading} />
            </div>
          </form>
          <Link to="/forgot-password" className={formHintButtonClassName}>
            {t('bottomCta')}
          </Link>
        </>
      )}
    </VisitorLayout>
  );
};

ResetPassword.displayName = 'ResetPassword';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearLoginDetails: async () => dispatch(await UserDetailsActions.clearLoginDetails()),
});

export default connect(null, mapDispatchToProps)(ResetPassword);
