import React, {
  forwardRef,
  Ref,
  ReactElement,
  FocusEventHandler,
  ChangeEventHandler,
  ReactNode,
  MouseEventHandler,
} from 'react';
import './RadioField.scss';

export interface RadioOption {
  label: ReactNode;
  value: string;
  nodeAfter?: ReactNode;
  className?: string;
  required?: boolean;
}

export interface RadioProps {
  options: RadioOption[];
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  bottomClassName?: string;
  label?: string;
  status?: 'active' | 'error' | 'inactive' | 'disabled';
  name: string;
  bottomLabel?: string;
  forceHideLabel?: boolean;
  elementAfter?: ReactElement;
  forceHideBottomLabel?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onClick?: MouseEventHandler<HTMLInputElement>;
  disabled?: boolean;
  inline?: boolean;
  /**
   * If this is enabled, the value string will be
   */
  multipleChoice?: boolean;
  /**
   * A single option value or values separated by commas
   */
  value?: string;
  type?: 'radio' | 'checkbox';
}

export const RadioField = forwardRef(
  (
    {
      options,
      className,
      labelClassName,
      inputClassName,
      bottomClassName,
      label,
      status,
      bottomLabel,
      forceHideLabel,
      elementAfter,
      forceHideBottomLabel,
      name,
      value = '',
      onBlur,
      onChange,
      onClick,
      disabled,
      inline,
      multipleChoice,
      type = 'radio',
    }: RadioProps,
    ref?: Ref<HTMLInputElement>,
  ) => {
    return (
      <div data-testid="RadioField" className={`RadioField ${className}`}>
        <div className={`RadioField-wrapper ${inline ? 'mod-inline' : ''}`}>
          {!forceHideLabel && (
            <div className={`RadioField-label  ${labelClassName} ${inline ? 'mod-inline' : ''}`}>{label}</div>
          )}
          <div className={`RadioField-options ${inline ? 'mod-inline' : ''}`}>
            {options.map(({ label: optLabel, value: optValue, nodeAfter, className: optClassName = '', required }) => {
              const optId = `${name}.${optValue}`;
              const mcValues = multipleChoice && typeof value === 'string' ? value?.split(',') : null;
              return (
                <div key={optValue} className={optClassName}>
                  <div className={`RadioField-options-item-container ${inline ? 'mod-inline' : ''}`}>
                    <input
                      type={type}
                      ref={ref}
                      name={multipleChoice ? undefined : name}
                      checked={mcValues?.includes(optValue)}
                      id={optId}
                      value={optValue}
                      onBlur={onBlur}
                      onChange={onChange}
                      onClick={onClick}
                      disabled={disabled || status === 'disabled'}
                      className={`${
                        status === 'disabled'
                          ? 'RadioField-options-item-disabled'
                          : status === 'error'
                          ? 'RadioField-options-item-error'
                          : 'RadioField-options-item'
                      } ${type === 'checkbox' ? 'mod-checkbox' : ''} ${inputClassName}`}
                      required={multipleChoice && mcValues ? mcValues?.length === 0 : required}
                    />
                    <label htmlFor={optId}>{optLabel}</label>
                  </div>
                  {!!nodeAfter && nodeAfter}
                </div>
              );
            })}
          </div>
        </div>
        {!forceHideBottomLabel && (
          <div
            className={`${
              status === 'error' ? 'RadioField-label-bottom-error' : 'RadioField-label-bottom'
            } ${bottomClassName}`}
          >
            {bottomLabel}
          </div>
        )}
        {elementAfter}
      </div>
    );
  },
);

RadioField.displayName = 'RadioField';

export default RadioField;
