import { createSelector } from 'reselect';
import { RootState } from 'MyTypes';
import { State } from './reducer';
import { NotificationDetails } from '../../../models/Notifications';

export const selectNotificationsState = (state: RootState): State => state.notifications;

export const selectAllNotifications = createSelector(
  selectNotificationsState,
  (notificationsState) => notificationsState.notifications,
);

export const selectNotification = createSelector(
  selectAllNotifications,
  (_: unknown, id: string) => id,
  (notifications, id) => notifications && notifications.find((item: NotificationDetails) => item.id === id),
);
