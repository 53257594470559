import { combineReducers } from 'redux';
import { UserDetailsReducer } from './UserDetailsDuck';
import { FormsReducer } from './FormsDuck';
import { AppointmentsReducer } from './AppointmentsDuck';
import { OrdersReducer } from './OrdersDuck';
import { NotificationsReducer } from './NotificationsDuck';

export const rootReducer = combineReducers({
  userDetails: UserDetailsReducer,
  forms: FormsReducer,
  appointments: AppointmentsReducer,
  orders: OrdersReducer,
  notifications: NotificationsReducer,
});

export default rootReducer;
