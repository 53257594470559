import { createSelector } from 'reselect';
import { RootState } from 'MyTypes';
import { State } from './reducer';
import { Order } from '../../../models/Order';

export const selectOrdersState = (state: RootState): State => state.orders;

export const selectAllOrders = createSelector(selectOrdersState, (ordersState) => ordersState.orders);

export const selectOrder = createSelector(
  selectAllOrders,
  (_: unknown, id: string) => id,
  (orders, id) => orders && orders.find((item: Order) => item.id === id),
);
