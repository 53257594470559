const parseCustomerAge = (str: string): number | null => {
  if (!/^(\d){8}$/.test(str)) return null;
  const y = Number(str.substr(0, 4));
  const m = Number(str.substr(4, 2));
  const d = Number(str.substr(6, 2));

  const dob = new Date(y, m, d);
  const today = new Date();

  const age = today.getFullYear() - dob.getFullYear();

  return age;
};

export default parseCustomerAge;
