import React, { memo, ReactNode } from 'react';
import Card from '../Card/Card';
import './HeaderCard.scss';

interface Props {
  title: ReactNode;
  description: ReactNode;
  children?: ReactNode;
  largeDescription?: boolean;
  hideLogo?: true;
}

export const HeaderCard = memo<Props>(({ title, description, children, largeDescription = false, hideLogo }) => {
  return (
    <Card className={`HeaderCard ${hideLogo ? 'mod-hide-logo' : ''}`}>
      <span className="HeaderCard-icon" />
      <h1 className="HeaderCard-title">{title}</h1>
      <p className={`HeaderCard-description ${largeDescription ? 'mod-large' : ''}`}>{description}</p>
      {children}
    </Card>
  );
});

HeaderCard.displayName = 'HeaderCard';

export default HeaderCard;
