const parseCustomerDob = (str: string): string | null => {
  if (!/^(\d){8}$/.test(str)) return null;
  const y = String(str.substr(0, 4));
  const m = String(str.substr(4, 2));
  const d = String(str.substr(6, 2));

  return `${y}-${m}-${d}`;
};

export default parseCustomerDob;
