import { Device } from 'hooks/useWindowDimensions';
import React, { createContext } from 'react';

interface ToolsContextProps {
  setIsMainNavigationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMainNavigationOpen: boolean;
  device: Device;
}

const ToolsContext = createContext<ToolsContextProps>({} as ToolsContextProps);

export default ToolsContext;
