import { useRef, useEffect, MutableRefObject } from 'react';

/**
 * Hook a ref of a function dependency.
 * Useful for using in some useEffect which does not want the function to trigger the update
 *
 * @param func
 */
function useUpdatedRef<T>(value: T): MutableRefObject<T> {
  // Hook ref for func
  const valueRef = useRef<T>(value);

  // Update funcRef on func update
  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  return valueRef;
}

export default useUpdatedRef;
