import { FormDetails } from '../../../models/FormDetails';
import * as FormsActions from './action';

export interface State {
  forms: FormDetails[];
}

const initialState: State = {
  forms: [],
};

export default function reducer(state: State = initialState, action: FormsActions.ActionType): State {
  switch (action.type) {
    case FormsActions.SET_FORMS:
      return { ...state, forms: action.payload.forms ?? [] };
    case FormsActions.FETCH_FORMS:
    case FormsActions.FETCH_FORMS_SUCCESS:
    case FormsActions.FETCH_FORMS_FAIL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
