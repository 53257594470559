import { useCallback, useRef, MutableRefObject, useEffect } from 'react';
import isFunc from 'simply-utils/dist/validators/isFunc';
import tryUntil from 'simply-utils/dist/utils/tryUntil';
import useUpdatedRef from './useUpdatedRef';
import useWindowSize from './useWindowSize';

export type Measurer<T extends HTMLElement = HTMLElement> = (node: T) => unknown;
export type ReturnType<T extends HTMLElement = HTMLElement> = [MutableRefObject<null | T>, Measurer<T>];

export function useMeasuredElementRef<T extends HTMLElement = HTMLElement>(
  setSize: (rect: DOMRect) => unknown,
  /**
   * The dependencies to remeausre element
   */
  dependencies?: unknown,
): ReturnType<T> {
  const elRef = useRef<null | T>(null);

  // Measure ref
  const measure = useCallback(
    () =>
      tryUntil(() => {
        const el = elRef.current as HTMLElement;
        if (el && isFunc(setSize) && isFunc(el.getBoundingClientRect)) {
          setSize(el.getBoundingClientRect());
          return true;
        }
        return false;
      }, 10),
    [setSize],
  );
  // Create ref of measure
  const measureRef = useUpdatedRef(measure);

  // With ref setter
  const measurerWithRef = useCallback<Measurer<T>>(
    (node) => {
      if (node) elRef.current = node;

      requestAnimationFrame(() => measure());
      // Measure once more to prevent some layout not rendered yet
      setTimeout(() => requestAnimationFrame(() => measure()), 300);
    },
    [measure],
  );

  // Re-measure on window size change
  const size = useWindowSize();
  useEffect(() => {
    measureRef.current();
  }, [measureRef, size, dependencies]);

  return [elRef, measurerWithRef];
}

export default useMeasuredElementRef;
