import React, { memo, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import Card from '../../../../components/Card/Card';
import './IconCardButton.scss';

const titleAlignFlexMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

export interface IconCardButtonProps {
  title: string;
  to?: string;
  href?: string;
  badgeNumber?: number;
  icon?: string;
  iconSize?: number;
  backgroundImage?: string;
  disabled?: boolean;
  /** Default to 'center' */
  titleAlign?: keyof typeof titleAlignFlexMap;
  children?: ReactNode;
}

export const IconCardButton = memo<IconCardButtonProps>(
  ({ to, href, icon, iconSize, backgroundImage, title, titleAlign, badgeNumber, disabled, children }) => {
    const badgeNumDisplayed = typeof badgeNumber === 'number' && badgeNumber > 9 ? `9+` : badgeNumber;

    const renderContents = () => {
      return (
        <Card
          className={`${backgroundImage ? 'IconCardButton-backgroundImage' : ''}`}
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          {!!icon && (
            <span
              className="IconCardButton-icon"
              style={{
                mask: `url(${icon}) no-repeat center`,
                WebkitMask: `url(${icon}) no-repeat center`,
                maskSize: 'contain',
                WebkitMaskSize: 'contain',
                width: iconSize,
                height: iconSize,
              }}
            />
          )}
          <span className="IconCardButton-title" style={{ alignSelf: titleAlign && titleAlignFlexMap[titleAlign] }}>
            {title}
          </span>
          {!!badgeNumDisplayed && <span className="IconCardButton-badge">{badgeNumDisplayed}</span>}
          {children && <div className="IconCardButton-children">{children}</div>}
        </Card>
      );
    };

    if (to) {
      return (
        <Link
          to={to}
          className={`Card IconCardButton ${disabled ? 'mod-disabled' : ''} ${children ? 'mod-children' : ''}`}
        >
          {renderContents()}
        </Link>
      );
    }

    if (href) {
      return (
        <a
          href={href}
          className={`Card IconCardButton ${disabled ? 'mod-disabled' : ''} ${children ? 'mod-children' : ''}`}
        >
          {renderContents()}
        </a>
      );
    }

    return <></>;
  },
);

IconCardButton.displayName = 'IconCardButton';

export default IconCardButton;
