/* eslint-disable react/jsx-one-expression-per-line */
import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import parseHTML from 'html-react-parser';

import InputTextField from 'components/InputTextField/InputTextField';
import SignaturePadField from 'components/SignaturePadField/SignaturePadField';
import { InputDropdownField } from 'components/InputDropdownField/InputDropdownField';
import useFormDropdownOptions from '../../../hooks/useFormDropdownOptions';
import { FormComponentProps } from '../../../types/FormComponentProps';
import FormDetailsLayout from '../../FormDetailsLayout/FormDetailsLayout';
import './DMEPOSPaymentResponsibilityAgreementForm.scss';

interface DropdownOptions {
  declareVerb?: string[];
  declareTimeUnit?: string[];
}

interface Props<T = any> extends FormComponentProps<T> {
  namePlaceholder?: string;
  datePlaceholder?: string;
}

export const DMEPOSPaymentResponsibilityAgreementForm = memo<Props>(
  ({
    form,
    onSubmit,
    loading,
    submitError,
    ctaLabel,
    ctaLoadingLabel,
    secondaryCtaLabel,
    tForm,
    namePlaceholder,
    datePlaceholder,
  }) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      getValues,
      setValue,
    } = useForm({
      defaultValues: {
        customerId: form?.customerId ?? '',
        name: form?.name ?? '',
        date: format(new Date(), 'yyyy-MM-dd'),
        insuranceId: form?.paOrderData?.insuranceId,
        itemOrdered: form?.paOrderData?.itemOrdered,
        itemList: form?.paOrderData?.itemList,
        payerInsuranceName: form?.paOrderData?.payerInsuranceName,
        approverInsuranceName: form?.paOrderData?.approverInsuranceName,
        paymentAmount: form?.paOrderData?.paymentAmount,
      } as { [key: string]: string },
    });
    const {
      signature: signatureValue,
      paymentAmount: paymentAmountValue,
      payerInsuranceName: payerInsuranceNameValue,
      approverInsuranceName: approverInsuranceNameValue,
    } = getValues();

    /** ------------------------- Fetch dropdown options ------------------------- */
    const { isRefreshingOptions, dropdownOptions } = useFormDropdownOptions<DropdownOptions>(form);

    const renderDeclareStatement = () => {
      return (
        <>
          <p>
            <span>{tForm('I')}&nbsp;</span>
            <InputDropdownField
              {...register('declareVerb', { required: true })}
              // @TODO: Translate options
              items={(dropdownOptions?.declareVerb ?? []).map((c) => ({ label: c, value: c }))}
              placeholder="e.g. have not"
              status={isRefreshingOptions ? 'disabled' : undefined}
              className="DMEPOSPaymentResponsibilityAgreementForm-inline-field"
            />
            <span>&nbsp;</span>
            <span>
              {tForm('receivedSame')}&nbsp;{tForm('withinTheLast')}:
            </span>
          </p>
          <p>
            <InputTextField
              {...register('declareLength', { required: true })}
              placeholder="e.g. 1"
              type="number"
              className="DMEPOSPaymentResponsibilityAgreementForm-inline-field declare-length"
            />
            <InputDropdownField
              {...register('declareTimeUnit', { required: true })}
              // @TODO: Translate options
              items={(dropdownOptions?.declareTimeUnit ?? []).map((c) => ({ label: c, value: c }))}
              placeholder="e.g. year(s) 年"
              status={isRefreshingOptions ? 'disabled' : undefined}
              className="DMEPOSPaymentResponsibilityAgreementForm-inline-field"
            />
            <span>{tForm('inclusive')}</span>
          </p>
        </>
      );
    };

    const renderPaymentStatement = () => {
      const emphasize = (text: string) => `<strong><u>${text}</u></strong>`;
      const statement = tForm('statement', {
        returnObjects: true,
        approverInsuranceName: emphasize(approverInsuranceNameValue),
        payerInsuranceName: emphasize(payerInsuranceNameValue),
        paymentAmount: emphasize(`$${paymentAmountValue}`),
      }) as string[];
      return <p>{parseHTML(Array.isArray(statement) ? statement.join('') : '')}</p>;
    };

    return (
      <FormDetailsLayout
        className="DMEPOSPaymentResponsibilityAgreementForm"
        id={form?.id}
        title={tForm('title')}
        ctaLabel={ctaLabel}
        ctaLoadingLabel={ctaLoadingLabel}
        secondaryCtaLabel={secondaryCtaLabel}
        onSubmit={handleSubmit(onSubmit)}
        loading={loading || isRefreshingOptions}
        submitError={submitError}
      >
        <InputTextField {...register('customerId')} label={tForm('customerId')} type="text" status="disabled" />
        <InputTextField
          {...register('name')}
          label={tForm('patientName')}
          placeholder={namePlaceholder}
          type="text"
          status="disabled"
        />
        <InputTextField {...register('insuranceId')} label={tForm('insuranceId')} type="text" status="disabled" />
        <InputTextField {...register('itemOrdered')} label={tForm('applyingFor')} type="text" status="disabled" />
        {renderDeclareStatement()}
        <br />
        <InputTextField {...register('itemList')} label={tForm('categoryItems')} type="text" status="disabled" />
        <br />
        {renderPaymentStatement()}
        <br />
        <InputTextField
          {...register('beneficiaryName', { required: true })}
          label={tForm('beneficiaryName')}
          placeholder={namePlaceholder}
          type="text"
          maxLength={50}
        />
        <SignaturePadField
          {...register('signature', { required: true })}
          label={tForm('signature')}
          status={errors?.signature ? 'error' : 'active'}
          value={signatureValue}
          disabled={loading}
          onChange={(value) => setValue('signature', value)}
        />
        <InputTextField
          {...register('date')}
          label={tForm('date')}
          placeholder={datePlaceholder}
          type="date"
          status="disabled"
        />
      </FormDetailsLayout>
    );
  },
);

DMEPOSPaymentResponsibilityAgreementForm.displayName = 'DMEPOSPaymentResponsibilityAgreementForm';

export default DMEPOSPaymentResponsibilityAgreementForm;
