import React, { memo, useCallback, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import './GoogleMaps.scss';
import ModalButtonList, { ButtonList } from '../ModalButtonList/ModalButtonList';
import isAppleProduct from '../../utils/isAppleProduct';

interface Props {
  className?: string;
  embeddedUrl: string;
  googleMapsUrl: string;
  appleMapsUrl: string;
  googleMapsLabel: string;
  appleMapsLabel: string;
}

export const GoogleMaps = memo<Props>(
  ({ className, embeddedUrl, googleMapsUrl, appleMapsUrl, googleMapsLabel, appleMapsLabel }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const modalBtnList: ButtonList[] = [
      { title: googleMapsLabel, onClick: () => window.open(googleMapsUrl, '_self') },
      { title: appleMapsLabel, onClick: () => window.open(appleMapsUrl, '_self') },
    ];

    const handleOnMapClick = useCallback(() => {
      if (isAppleProduct()) {
        setOpenModal(true);
      } else {
        window.open(googleMapsUrl);
      }
    }, [googleMapsUrl]);

    return (
      <>
        <ModalButtonList
          inActiveStyling
          isOpen={openModal}
          list={modalBtnList}
          onRequestClose={() => setOpenModal(false)}
        />
        <div onClick={handleOnMapClick} className={`GoogleMaps ${className || ''} ${isLoaded ? 'mod-loaded' : ''}`}>
          {!isLoaded && (
            <div className="GoogleMaps-loading">
              <Skeleton count={1} />
            </div>
          )}
          <iframe onLoad={() => setIsLoaded(true)} className="GoogleMaps-iframe" title="GoogleMaps" src={embeddedUrl} />
        </div>
      </>
    );
  },
);

GoogleMaps.displayName = 'GoogleMaps';

export default GoogleMaps;
