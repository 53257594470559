import React, { useEffect, useMemo, useRef, useState } from 'react';

const ONE_SECOND_MS = 1000;
const ONE_MINUTE_MS = 1000 * 60;
const ONE_HOUR_MS = 1000 * 60 * 60;

export type TimerUnit = 'seconds' | 'minutes' | 'hours';
export type TimerMode = 'countDown' | 'countUp';

export interface TimerOptions {
  mode?: TimerMode;
  unit?: TimerUnit;
  defaultValue?: number;
  interval?: number;
  /**
   * If `mode` is 'countUp', this would be the inclusive max value of the timer.
   * If `mode` is 'countDown', this would be the inclusive min value of the timer.
   */
  boundaryValue?: number;
  disabled?: boolean;
  /** If defined, the timer value will be synchronized with in local storage */
  localStorageKey?: string;
}

export type ReturnType = [number, React.Dispatch<React.SetStateAction<number>>];

const useTimer = ({
  mode = 'countUp',
  unit = 'seconds',
  defaultValue = 0,
  interval = 1,
  boundaryValue,
  disabled,
  localStorageKey,
}: TimerOptions): ReturnType => {
  const [timer, setTimer] = useState(
    localStorageKey ? +(localStorage.getItem(localStorageKey) ?? defaultValue) : defaultValue,
  );
  const isMounted = useRef(false);

  // Prevent 'set state after un-mount' error
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Calculate interval of milliseconds based on `unit` and `interval`
  const calculatedInterval = useMemo(() => {
    switch (unit) {
      case 'hours':
        return ONE_HOUR_MS * interval;
      case 'minutes':
        return ONE_MINUTE_MS * interval;
      case 'seconds':
      default:
        return ONE_SECOND_MS * interval;
    }
  }, [interval, unit]);

  // Update time per unit
  useEffect(() => {
    if (disabled) return () => null;

    const intervalId = setInterval(() => {
      if (isMounted.current)
        setTimer((prevVal) => {
          const calculatedValue = (() => {
            const nextVal = mode === 'countDown' ? prevVal - 1 : prevVal + 1;
            if (typeof boundaryValue === 'number') return nextVal < boundaryValue ? boundaryValue : nextVal;
            return nextVal;
          })();
          // Synchronize with local storage
          if (localStorageKey) localStorage.setItem(localStorageKey, `${calculatedValue}`);
          // Return next state
          return calculatedValue;
        });
    }, calculatedInterval);

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [boundaryValue, calculatedInterval, disabled, localStorageKey, mode]);

  return [timer, setTimer];
};

export default useTimer;
