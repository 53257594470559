import React, { memo, ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './ItemList.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Card from '../Card/Card';

export interface ListItem {
  id: string;
  title: ReactNode;
  status?: boolean;
  remarks: string | number | null;
  extraLabel?: string;
  disabled?: boolean;
  statusLabel?: string;
  statusClassName?: string;
}

interface Props {
  items: ListItem[];
  trueLabel: string;
  falseLabel: string;
  onItemClick: (item: ListItem) => unknown;
  isRefreshing?: boolean;
  emptyLabel?: string;
  trueLabelClassName?: string;
  falseLabelClassName?: string;
  badgeClassName?: string;
}

export const ItemList = memo<Props>(
  ({
    items,
    trueLabel,
    falseLabel,
    onItemClick,
    isRefreshing = false,
    emptyLabel,
    trueLabelClassName,
    falseLabelClassName,
    badgeClassName,
  }) => {
    const renderItem = (item: ListItem) => {
      const { id, title, status, remarks, disabled, extraLabel, statusLabel, statusClassName } = item;
      return (
        <Card
          key={id}
          className={`ItemList-list-item ${disabled ? 'mod-disabled' : ''}`}
          onClick={disabled ? undefined : () => onItemClick(item)}
          disabled={disabled}
        >
          <div className="ItemList-list-content-container">
            <div className="ItemList-list-item-title">{title}</div>
            <div className="ItemList-list-item-metadata">
              {status !== undefined && (
                <span
                  className={`ItemList-list-item-metadata-status ${
                    status ? `mod-true ${trueLabelClassName || ''}` : `mod-false ${falseLabelClassName || ''}`
                  } ${statusClassName || ''}`}
                >
                  {statusLabel || (status ? trueLabel : falseLabel)}
                </span>
              )}
              {extraLabel && <span className="ItemList-list-item-metadata-status">{extraLabel}</span>}
              {remarks && <span className="ItemList-list-item-metadata-createdAt">{remarks}</span>}
            </div>
            {status !== undefined && !status && <div className={`ItemList-list-item-badge ${badgeClassName || ''}`} />}
          </div>
        </Card>
      );
    };

    const renderLoadingItems = (loadingItems: number): JSX.Element[] | JSX.Element =>
      [...Array(loadingItems)].map((_, index) => (
        <div key={`${index + 1}`} className="ItemList-list-loading-skeleton-container">
          <Skeleton count={1} />
        </div>
      ));

    const renderListItems = () => {
      if (emptyLabel && items.length === 0) return <div className="ItemList-list-empty-container">{emptyLabel}</div>;
      return items.map(renderItem);
    };

    return (
      <div className="ItemList">
        <div className="ItemList-list">{isRefreshing ? renderLoadingItems(3) : renderListItems()}</div>
      </div>
    );
  },
);

ItemList.displayName = 'ItemList';

export default ItemList;
